import { Component, OnDestroy, OnInit } from '@angular/core';
import { Company } from '../../dtos/company';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { CompanyService } from '../../services/felixApi/company.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { AreaPermission } from '../../dtos/areaPermission';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'js-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  public isCollapsed = true;
  signedIn = false;
  currComp: Company;
  areaSelected = '';
  disabledMenu = false;
  phoneSize: boolean;
  userTypeEnum = UserTypeEnum;
  companyConfiguration: CompanyConfiguration[];
  innerWidth: number;
  canSeeMaintenance: boolean;
  canSeeMigrationTools: boolean;
  invoiceAdmin: boolean;
  invoiceRead: boolean;
  incomeInvoiceRead: boolean;
  canAccessOrders: boolean;
  canAccessSendPrint: boolean;

  constructor(
    private globalService: GlobalService,
    public authService: AuthService,
    private compService: CompanyService,
    private notiService: NotificationService
  ) {
    this.subscribeToLoginChanges();
    this.subscribeToCompanyChanges();
    this.subscribeToAreaChanges();
    this.subscribeToMenuDisable();
    this.subscribeToInnerWidth();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  menuPress(area) {
    this.globalService.setAreaSelected(area);
  }

  subscribeToAreaChanges() {
    this.subscriptions.push(
      this.globalService.areaChanged.subscribe(area => {
        this.areaSelected = area;
      })
    );
  }

  subscribeToLoginChanges() {
    this.subscriptions.push(
      this.authService.isLoggedInChanged.subscribe(
        (isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this.signedIn = false;
            this.disabledMenu = true;
          } else {
            this.signedIn = true;
          }
        })
    );
  }

  subscribeToCompanyChanges() {
    this.subscriptions.push(
      this.globalService.companySelected.subscribe(selectedComp => {
        this.currComp = selectedComp;
        if (this.currComp.userTypeId === this.userTypeEnum.Admin
          || this.currComp.userTypeId === this.userTypeEnum.SuperUser) {
          this.canSeeMaintenance = true;
          this.invoiceAdmin = true;
          this.invoiceRead = true;
          this.incomeInvoiceRead = true;
          this.canAccessOrders = true;
          this.canAccessSendPrint = true;
          this.globalService.canSeeAdminReports = true;
          this.globalService.invoicePermission = 'Admin';
          this.globalService.invoiceSubmitPermission = 'Admin';
          this.globalService.incomeInvoicePermission = 'Admin';
          this.globalService.ordersPermWrite = true;
          this.globalService.setInvoicePermissionsChanged();
          if (this.currComp.userTypeId === this.userTypeEnum.SuperUser) {
            this.canSeeMigrationTools = true;
          }
        }
        this.getCompanyConfigurations();
      })
    );
  }

  subscribeToMenuDisable() {
    this.subscriptions.push(
      this.globalService.menuDisable.subscribe(
        disable => {
          if (disable) {
            this.disabledMenu = true;
          } else {
            this.disabledMenu = false;
          }
        }
      )
    );
  }

  subscribeToInnerWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.innerWidth = window.innerWidth;
        }
      )
    );
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.compService.getCompanyConfigurations().subscribe({
        next: (companyConfiguration) => {
          this.companyConfiguration = companyConfiguration;
          this.globalService.companyConfiguration = this.companyConfiguration;

          if (this.currComp.userTypeId !== this.userTypeEnum.Admin && this.currComp.userTypeId !== this.userTypeEnum.SuperUser) {
            // Invoices permissions
            const invoicePermission = this.authService.areaPermissions.find(i => i.applicationArea === 'Invoices');
            this.invoiceAdmin = this.hasPermissionLevelOrHigher(invoicePermission, 'Admin');
            this.invoiceRead = this.hasPermissionLevelOrHigher(invoicePermission, 'Read');
            this.globalService.invoicePermission = invoicePermission?.permissionType;
            // Invoice Sumbit permissions
            if (this.hasPermissionLevelOrHigher(invoicePermission, 'Write')) {
              const invoiceSubmitPermission = this.authService.areaPermissions.find(i => i.applicationArea === 'InvoiceSubmit');
              this.globalService.invoiceSubmitPermission = invoiceSubmitPermission?.permissionType;
            }
            // Income Invoices permissions
            const incomeInvoicePermission = this.authService.areaPermissions.find(i => i.applicationArea === 'IncomeInvoices');
            this.incomeInvoiceRead = this.hasPermissionLevelOrHigher(incomeInvoicePermission, 'Read');
            this.globalService.incomeInvoicePermission = incomeInvoicePermission?.permissionType;
            // Maintenance permissions
            const poPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'PurchaseOrders');
            this.canSeeMaintenance = this.hasPermissionLevelOrHigher(poPerm, 'Admin');
            // Orders permissions
            if (this.hasPermissionLevelOrHigher(poPerm, 'Read')) {
              const selectionsFilePerm = this.authService.areaPermissions.find(i => i.applicationArea === 'Selections');
              const priceFilePerm = this.authService.areaPermissions.find(i => i.applicationArea === 'PriceFile');
              const recipesPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'Recipes');

              if ((this.hasPermissionLevelOrHigher(selectionsFilePerm, 'Admin') || this.hasPermissionLevelOrHigher(priceFilePerm, 'Read'))
                && (this.hasPermissionLevelOrHigher(recipesPerm, 'Read'))) {
                this.canAccessOrders = true;
                if (this.hasPermissionLevelOrHigher(poPerm, 'Write')) {
                  this.canAccessSendPrint = true;
                  this.globalService.ordersPermWrite = true;
                }
              }
            }
            // Reports permissions
            const reportsPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'Reports');
            if (this.hasPermissionLevelOrHigher(reportsPerm, 'Admin')) {
              this.globalService.canSeeAdminReports = true;
            } else {
              this.globalService.canSeeAdminReports = false;
            }
          }
          this.globalService.setInvoicePermissionsChanged();
        },
        error: (err) => {
          this.notiService.notify(err);
        }})
    );
  }

  hasPermissionLevelOrHigher(permission: AreaPermission, permissionType: string): boolean {
    var hasPermission: boolean = false;
    if (permission) {
      if (permission.permissionType === 'Admin') {
        hasPermission = true;
      } else if (permission.permissionType === 'Write' && permissionType !== 'Admin') {
        hasPermission = true;
      } else if (permission.permissionType === 'Read' && permissionType === 'Read') {
        hasPermission = true;
      }
    }
    return hasPermission;
  }
  dropdownItems = [
    {
      text: 'Core',
      onClick: () => this.launchCore(),
      icon: 'assets//tlogos/main.png'
    },
    {
      text: 'Tracking',
      onClick: () => this.launchTracking(),
      icon: 'assets//tlogos/tracking.png'
    },
    {
      text: 'Estimating',
      onClick: () => this.launchEstConfig(),
      icon: 'assets//tlogos/estimating.png'
    },
    {
      text: 'Config',
      onClick: () => this.launchConfig(),
      icon: 'assets//tlogos/config.png'
    },
    {
      visible: this.userTypeEnum.Admin,
      text: 'Admin',
      onClick: () => this.launchAdminPortal(),
      icon: 'assets//tlogos/admin.png'
    }
  ];

  launchAdminPortal() {
    window.open(environment.adminAppUrl);
  }

  launchConfig() {
    window.open(environment.configAppUrl);
  }

  launchEstConfig() {
    window.open(environment.estConfigAppUrl);
  }

  launchCore() {
    window.open(environment.addendaAppUrl);
  }

  launchTracking() {
    window.open(environment.trackingAppUrl);
  }

}

