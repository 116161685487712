<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="vendorGrid" class="m-auto" columnResizingMode="widget" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" columnMinWidth="10"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')" (onInitNewRow)="onInitNewRow($event)"
    (onEditorPreparing)="onEditorPreparing($event)" (onExporting)="gridService.onExporting($event, 'vendors')">

    <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="after" [width]="250"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="orderVendorsGrid" [savingTimeout]="500">
    </dxo-state-storing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <div *dxTemplate="let data of 'refreshTemplate'">
      <div>
        <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
      </div>
    </div>

    <dxi-column dataField="vendorCode" [width]="100" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="vendorName" minWidth="150">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="vendorName2" [visible]="false"></dxi-column>

    <dxi-column dataField="vendorABN" [visible]="false"></dxi-column>

    <dxi-column dataField="vendorAddress" [visible]='false'></dxi-column>

    <dxi-column dataField="vendorContact" [width]="120"></dxi-column>

    <dxi-column dataField="phoneNumber" [width]="90"></dxi-column>

    <dxi-column dataField="email" [visible]='false'></dxi-column>

    <dxi-column dataField="callUpEmail" caption="Call Up Email (if Different)" [visible]='false'></dxi-column>

    <dxi-column dataField="ordersEmail" caption="Orders Email (if Different)" [visible]='false'></dxi-column>

    <dxi-column dataField="accountsCode" minWidth="0"></dxi-column>

    <dxi-column dataField="accountId" [visible]='false'></dxi-column>
    
    <dxi-column dataField="incomeProtectionExpiry" dataType="date" [width]="100" format="d/M/yy" alignment="center"
      [editorOptions]="{ showClearButton: true }" [setCellValue]="setIncomeProtectionExpiry"></dxi-column>

    <dxi-column dataField="publicLiabilityExpiry" dataType="date" [width]="100" format="d/M/yy" alignment="center"
      [editorOptions]="{ showClearButton: true }" [setCellValue]="setPublicLiabilityExpiry"></dxi-column>

    <dxi-column dataField="workCoverExpiry" dataType="date" [width]="100" format="d/M/yy" alignment="center"
      [editorOptions]="{ showClearButton: true }" [setCellValue]="setWorkCoverExpiry"></dxi-column>

    <dxi-column dataField="renegotiationDate" dataType="date" [width]="110" format="d/M/yy" alignment="center"
      [visible]='false'>
    </dxi-column>

    <dxi-column dataField="comment" [visible]='false'></dxi-column>

    <dxi-column dataField="callUpSubjectAppendix" caption="Call-Up Email Subject Appendix" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="isCallUpShowSupervisor" caption="Show Supervisor on Call-Up/PO?" dataType="boolean"
      [width]="100" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="canAcceptInvoices" dataType="boolean" [width]="100" cellTemplate="activeTemplate">
    </dxi-column>

    <dxi-column dataField="canAnyVendorInvoice" dataType="boolean" [width]="100">
    </dxi-column>

    <dxi-column dataField="isInvoiceManuallyAuthorised" caption="Invoices Manually Authorised" dataType="boolean"
      [width]="100">
    </dxi-column>

    <dxi-column dataField="vendorPayableIds" caption="Vendors to pay" [width]="150" [allowSorting]="false"
      editCellTemplate="tagBoxEditor" [cellTemplate]="cellTemplate"
      [calculateFilterExpression]="calculateFilterExpression">
      <dxo-lookup [dataSource]="vendors" valueExpr="id" displayExpr="vendorCode">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="glAccountTypeId" caption="Vendor Type" dataType="number" [width]="130"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="glAccountTypes" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="vendorGroupId" caption="Vendor Group" dataType="number" [width]="130"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="vendorGroups" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isGstFree" caption="GST Free" dataType="boolean" [width]="80"></dxi-column>

    <dxi-column dataField="accountId" caption="Connected to Accounts" dataType="string" minWidth="90"
      cellTemplate="accountsCodeCellTemplate" alignment="center" editCellTemplate="editVendorConnection">
      <dxo-lookup [dataSource]="accountingSystemVendors" valueExpr="contactID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="invoiceDefaultCostCentreId" caption="Default Cost Centre for Invoices" dataType="number"
      [width]="130" [editorOptions]="{ showClearButton: true, searchEnabled: true }">
      <dxo-lookup [dataSource]="costCentres" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="vendorInvoiceApproverUserIds" caption="Invoice Approvers" [width]="130"
      [allowSorting]="false" editCellTemplate="userTagBoxEditor" [cellTemplate]="cellTemplate"
      [calculateFilterExpression]="calculateFilterExpression">
      <dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isActive" caption="Active?" dataType="boolean" [width]="95" cellTemplate="activeTemplate">
    </dxi-column>

    <dxi-column type="buttons" [fixed]="true">
    </dxi-column>

    <!-- templates -->
    <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
      <dx-tag-box [dataSource]="vendorsDataSource" [value]="cellInfo.value" valueExpr="id" displayExpr="vendorName"
        [showSelectionControls]="true" [maxDisplayedTags]="55" [showMultiTagOnly]="false" applyValueMode="useButtons"
        [searchEnabled]="true" [dropDownOptions]="{ minWidth: 350 }" (onValueChanged)="cellInfo.setValue($event.value)"
        (onSelectionChanged)="cellInfo.component.updateDimensions()">
      </dx-tag-box>
    </div>

    <div *dxTemplate="let cellInfo of 'userTagBoxEditor'">
      <dx-tag-box [dataSource]="usersDataSource" [value]="cellInfo.value" valueExpr="id" displayExpr="fullName"
        [showSelectionControls]="true" [maxDisplayedTags]="55" [showMultiTagOnly]="false" applyValueMode="useButtons"
        [searchEnabled]="true" [dropDownOptions]="{ minWidth: 350 }" (onValueChanged)="cellInfo.setValue($event.value)"
        (onSelectionChanged)="cellInfo.component.updateDimensions()">
      </dx-tag-box>
    </div>

    <div *dxTemplate="let cellInfo of 'accountsCodeCellTemplate'"
      [class]="(cellInfo.value | accountsCode) === 'NO' ? 'red' : 'green'">
      <div>{{cellInfo.value | accountsCode}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'editVendorConnection'">
      <dx-select-box [(ngModel)]="cellInfo.value" [dataSource]="accountingSystemVendors" valueExpr="contactID"
        [showClearButton]="true" displayExpr="name" placeholder="Select.." [searchEnabled]="true"
        [dropDownOptions]="{ minWidth: 350 }" (onValueChanged)="onLookupValueChanged($event, cellInfo)">
      </dx-select-box>
    </div>

    <div *dxTemplate="let cell of 'activeTemplate'">
      <i *ngIf="cell.value" class="dx-icon-todo"></i>
      <i *ngIf="!cell.value" class="dx-icon-close red"></i>
    </div>

    <!-- Toolbar -->
    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-select-box id="selectBatch" [dataSource]="accountingSystemTenantNames"
          displayExpr="accountingSystemTenantName" valueExpr="accountingSystemTenantName"
          [placeholder]="vendorPlaceHolder" [width]="400" [(value)]='accountingSystemTenantName'
          (onValueChanged)='selectOptionChanged()'>
        </dx-select-box>
      </div>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingAccounts" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>

<!-- auto - connect -->
<dx-popup class="popup" [width]="400" [height]="130" [showTitle]="true" title="Auto Connect Vendors by Name"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="autoConnectPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="autoConnectButton" text="Go" type="success" (onClick)="autoConnectGo()"> </dx-button>
  </div>
</dx-popup>
