<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="incomeInvoicesGrid" class="m-auto" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')" (onEditorPreparing)="onEditorPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'income-invoices')">

    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-editing mode="batch" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"
      refreshMode="repaint">
    </dxo-editing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-selection selectAllMode='allPages' showCheckBoxesMode='always' mode="multiple">
    </dxo-selection>

    <dxi-column dataField="invoiceNumber" [allowEditing]="false" width="120" sortOrder="asc"></dxi-column>

    <dxi-column dataField="date" dataType="date" [width]="100" format="d/M/yy" alignment="center"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="dueDate" dataType="date" [width]="100" format="d/M/yy" alignment="center"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="jobNumber" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="contactName" caption="Customer" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="total" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="status" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="amountPaid" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="fullyPaidOnDate" caption="Last Paid" dataType="date" [width]="100" format="d/M/yy"
      alignment="center" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="lineDescription" [allowEditing]="false"></dxi-column>

    <dxi-column *ngIf="accountingSystemType === 1" caption="Tracking Category 1" dataField="trackingCategory1"
      [allowEditing]="false"></dxi-column>

    <dxi-column *ngIf="accountingSystemType === 1" caption="Tracking Category 2" dataField="trackingCategory2"
      [allowEditing]="false"></dxi-column>

    <dxi-column *ngIf="accountingSystemType === 2" dataField="memo" [allowEditing]="false"></dxi-column>

    <dxi-column *ngIf="accountingSystemType === 3" dataField="jobName" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="invoiceID" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="truthEngineIncomeInvoiceId" [allowEditing]="false"
      [calculateCellValue]='calculateExistsInTruthEngine' caption="Exists In Truth Engine" [allowEditing]="false"
      dataType="boolean">
    </dxi-column>

    <dxi-column dataField="importType" [allowEditing]="true">
      <dxo-lookup [dataSource]="incomeInvoiceTypes" valueExpr="value" displayExpr="text">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="importToJobNumber" [allowEditing]="true"></dxi-column>

    <dxi-column caption="Import To Claim" dataField="importToClaimId" [allowEditing]="true">
      <dxo-lookup [dataSource]="allClaims" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Import To Variation" dataField="importToVariationId" [allowEditing]="true">
      <dxo-lookup [dataSource]="allJobVariations" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="createVariationNumber" [allowEditing]="true">
    </dxi-column>

    <dxi-column dataField="importMessage" [allowEditing]="false"> </dxi-column>

    <!-- summary totals -->
    <dxo-summary>
      <dxi-total-item column="invoiceNumber" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="total" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="amountPaid" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

    <!-- templates -->
    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        Company: <dx-select-box id="selectAccountingCompany" [dataSource]="accountingCompanies" displayExpr="tenantName"
          valueExpr="tenantId" placeholder="Select Accounting Company" [width]="250"
          [(value)]='accountingSystemTenantId' (onValueChanged)='onCompanySelectionChanged()'>
        </dx-select-box>
        Contact: <dx-select-box id="selectContact" [dataSource]="contacts" displayExpr="contractName" valueExpr="id"
          [showClearButton]="true" placeholder="Select contact" [width]="250" [(value)]='selectedContactId'
          [searchEnabled]="true" (onValueChanged)='onContactSelectionChanged($event)'>
        </dx-select-box>
        Last Modified: <dx-date-box id="lastModifiedDateBox" [width]="150" [(value)]='lastModifiedDate'
          (onValueChanged)='onLastModifiedChanged()'></dx-date-box>
        <dx-button *ngIf="accountingSystemType === 2" id="getCount" icon="info" (onClick)="getCount()"></dx-button>
        <dx-text-box *ngIf="accountingSystemType === 2" id="countTextBox" [readOnly]="true" [width]="100"
          [(value)]='invoiceCount'></dx-text-box>
        Max. Invoices to Retrieve: <dx-number-box id="maxInvoices" [width]="100"
          [(value)]='maxInvoices'></dx-number-box>
        Split By Lines: <dx-check-box id="splitByLines" [width]="100"
          [(value)]='splitByLines'></dx-check-box>
      </div>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingAccounts" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
