<nav *ngIf="signedIn && currComp" class="navbar navbar-expand-md navbar-light navbarclass">

  <dx-drop-down-button #dropdownButton [items]="dropdownItems" [useSelectMode]="false" [dropDownOptions]="{ width: 140}"
  icon="assets/menuBar.png" class="menuDropDown">

  <div *dxTemplate="let item of 'item'">
    <img [src]="item.icon" class="dropDownLogo">
    <span class="dropdownOptionText">{{ item.text }}</span>
  </div>
</dx-drop-down-button>

  <div>
    <img class="navbar-brand logo" src="../../../assets/logoTransparent.png" [routerLink]="['/welcome']" />
    <div class="title">
      Costing
    </div>
  </div>

  <span *ngIf="currComp && !(innerWidth < 768)" class="cust-divider-vertical"></span>

  <button class="navbar-toggler d-lg-none" type="button" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarsExampleDefault" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarsExampleDefault" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0" style="margin-top: 0!important;">

      <a *ngIf="canAccessOrders" class="nav-item active nav-link tab" [routerLink]="['/orders']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'orders'"
        (click)="isCollapsed = true; menuPress('orders')">
        Construction Orders
      </a>

      <a *ngIf="canAccessSendPrint" class="nav-item active nav-link tab" [routerLink]="['/order-send']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'order-send'"
        (click)="isCollapsed = true; menuPress('order-send')">
        Send/Print
      </a>

      <a *ngIf="invoiceRead" class="nav-item active nav-link tab" [routerLink]="['/invoice']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'invoice'"
        (click)="isCollapsed = true; menuPress('invoice')">
        Invoices
      </a>

      <a *ngIf="incomeInvoiceRead" class="nav-item active nav-link tab" [routerLink]="['/claims']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'claims'"
        (click)="isCollapsed = true; menuPress('claims')">
        Claims
      </a>

      <a *ngIf="invoiceAdmin" class="nav-item active nav-link tab" [routerLink]="['/vendors']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'vendors'"
        (click)="isCollapsed = true; menuPress('vendors')">
        Vendors
      </a>

      <a *ngIf="invoiceRead" class="nav-item active nav-link tab" [routerLink]="['/reports']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'reports'"
        (click)="isCollapsed = true; menuPress('reports')">
        Reports
      </a>

      <a *ngIf="canSeeMaintenance" class="nav-item active nav-link tab" [routerLink]="['/maintenance']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'maintenance'"
        (click)="isCollapsed = true; menuPress('maintenance')">
        Set-Up
      </a>

      <a *ngIf="canSeeMigrationTools" class="nav-item active nav-link tab" [routerLink]="['/migration-tools']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'migration-tools'"
        (click)="isCollapsed = true; menuPress('migration-tools')">
        Migration Tools
      </a>

    </ul>
  </div>

  <div *ngIf="innerWidth > 450">
    <a class="currComp" [routerLink]="['/welcome']">{{currComp.companyShortName}}</a>
  </div>
</nav>
