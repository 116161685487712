<div class="page">

  <dx-data-grid *ngIf="!loadingData" #depositGrid id="depositGrid" columnResizingMode="widget" [height]="gridHeight - 20" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true" columnMinWidth="10" 
    [(selectedRowKeys)]="selectedRecords"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate2')"
    (onExporting)="gridService.onExporting($event, 'claims')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="progress-claim-invoices" [savingTimeout]="500">
    </dxo-state-storing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-selection selectAllMode='allPages' showCheckBoxesMode='always' mode="multiple">
    </dxo-selection>


    <dxi-column dataField="jobId" caption="Job" dataType="number" width="110">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="clientName" caption="Client" dataType="string" [calculateCellValue]='calculateClientName'>
    </dxi-column>

    <dxi-column dataField="siteAddress" dataType="string" [calculateCellValue]='calculateSiteAddress'>
    </dxi-column>

    <dxi-column dataField="orderNumber" caption="Claim#" dataType="number" width="70" alignment="center">
    </dxi-column>

    <dxi-column dataField="claimDescription" caption="Claim" dataType="string">
    </dxi-column>

    <dxi-column dataField="invoiceDate" caption="Date" dataType="date" width="130" alignment="center">
    </dxi-column>

    <dxi-column dataField="totalExGST" dataType="number" width="130" [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="totalGST" dataType="number" width="130" [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="totalIncGST" dataType="number" width="130" [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="connectionStatus" caption="Status" dataType="string" width="200">
    </dxi-column>

    <dxi-column dataField="clientEmail" dataType="string" width="200" [calculateCellValue]='calculateClientEmail'
      [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" width="100"
      [allowEditing]="false" [visible]="false">
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <dx-check-box text="Submit Future Dated Invoices" [(value)]='submitFutureInvoices'>
        </dx-check-box>
      </div>
    </div>

    <dxo-summary>
      <dxi-total-item column="jobId" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="totalExGST" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalGST" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalIncGST" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

  </dx-data-grid>

  <span *ngIf="!loading">{{minuteCountdown}} minute(s) before auto refresh</span>

</div>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
