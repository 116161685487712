import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Variation } from '../../dtos/variation';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class VariationService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getVariations(jobNum: string): Observable<Variation[]> {
    let url = this.globalService.getApiUrl();
      url += '/jobs/' + jobNum + '/variations';

    return this._http.get<Variation[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getAllVariations(): Observable<Variation[]> {
    let url = this.globalService.getApiUrl();
      url += '/job-variations/all';

    return this._http.get<Variation[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
