import { HelpDictionary, HelpService } from './../services/help.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'js-help-modal',
  template: `
        <div *ngIf="title" style="height: 50px; padding-top: 8px;" class="modal-header">
            <h3 class="modal-title" style="font-size: 22px;">{{title}}</h3>
            <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="font-size: 15px;">
            <div style="white-space: break-spaces;" [innerHTML]="sanitisedTemplate"></div>
        </div>
    `
})
export class HelpModalComponent implements OnInit {
  @Input() dataField: string;
  @Input() wipReport: boolean;

  sanitisedTemplate: SafeHtml;
  title: string;
  text: string;
  helpDictionary: HelpDictionary[];

  constructor(private sanitiser: DomSanitizer, private helpService: HelpService, private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.helpDictionary = this.helpService.getHelpDictionary();
    let helpRecord = this.helpDictionary.find(i => i.dataField === this.dataField);

    if (this.wipReport && this.dataField === 'jobCostExcGST') {
      helpRecord = this.helpDictionary.find(i => i.dataField === 'jobCostExcGST-WIP');
    }
    
    if (helpRecord) {
      this.title = helpRecord.title;
      this.text = helpRecord.helpText;
    } else {
      this.title = this.dataField;
      this.text = 'No help defined for this field';
    }
    this.sanitisedTemplate = this.sanitiser.bypassSecurityTrustHtml(this.text);
  }

  cancel() {
    this.activeModal.close();
  }

}
