import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MigrationToolsHelperService {

  accountingSystemSections = ['Income Invoice Query', 'Invoice Query', 'Purchase Order Query', 'Order Import'];
  fileUploadSections = ['Order History Load', 'Order Line History Load', 'Invoice History Load', 'Claim History Load',
    'Variation History Load', 'Accrual History Load', 'DataBuild Price File', 'Addenda Writer', 'Tasks', 'Jobs',
    'Field Data', 'Key Dates', 'Users', 'Contacts'];
  
  selectedSection = 'Income Invoice Query';

  constructor() { }

}