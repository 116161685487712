<dx-tab-panel #panel height="calc(100vh - 50px)" [dataSource]="sections" [selectedIndex]="selectedIndex" [loop]="false"
  [swipeEnabled]="false" (onTitleClick)="tabSelected($event)" width="99.9%">

  <div *dxTemplate="let title of 'title'">
    <span>{{title}}</span>
  </div>

  <div *dxTemplate="let section of 'item'">
    <div style="height: calc(100vh - 100px);">
      <js-variance-code *ngIf="section === 'Extras Codes'"></js-variance-code>
      <js-variance-reason *ngIf="section === 'Extras Reasons'"></js-variance-reason>
      <js-order-control *ngIf="section === 'Control'"></js-order-control>
      <js-divisions *ngIf="section === 'Job Divisions'"></js-divisions>
      <js-company-activities *ngIf="section === 'Activities'"></js-company-activities>
      <js-gl-account-type *ngIf="section === 'Vendor Types'"></js-gl-account-type>
      <js-division-account *ngIf="section === 'Division Accounts'" [divisionAccountCounter]=divisionAccountCounter></js-division-account>
      <js-division-product *ngIf="section === 'Division Products'" [divisionProductCounter]=divisionProductCounter></js-division-product>
      <js-vendor-group *ngIf="section === 'Vendor Groups'"></js-vendor-group>
      <js-job-accounting-customer *ngIf="section === 'Job-Accounting Customers'" [jobAccountingCustomerCounter]=jobAccountingCustomerCounter></js-job-accounting-customer>
    </div>
  </div>
</dx-tab-panel>
