import { CompanyService } from './../../../services/felixApi/company.service';
import { SplitOrder } from './../../../dtos/purchase-order';
import { UtilsService } from './../../../services/utils.service';
import { InvoiceService } from './../../../services/felixApi/invoice.service';
import { NotificationService } from './../../../services/notification.service';
import { PoService } from './../../../services/felixApi/po.service';
import { EstimatingService } from './../../../services/felixApi/estimating.service';
import { JobService } from './../../../services/felixApi/job.service';
import { MaintenanceService } from './../../../services/felixApi/maintenance.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Invoice, InvoiceAddForm, InvoiceStatuses, InvoiceStatusTypeEnum } from '../../../dtos/invoice';
import { Job } from '../../../dtos/job';
import { Vendor } from '../../../dtos/vendor';
import { PriceFileItem } from '../../../dtos/price-file-item';
import { RoleTypeEnum } from '../../../dtos/role-type.enum';
import { CreateZeroPoComponent } from '../../../orders/create-zero-po/create-zero-po.component';

@Component({
  selector: 'js-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss']
})
export class AddInvoiceComponent implements OnInit, OnDestroy {
  @Input() selectedInvoiceBatchId: number;
  @Input() currentBatchInvoices: Invoice[];

  subscriptions: Subscription[] = [];
  loading = true;
  invoiceForm: InvoiceAddForm;
  updateButtonOptions = {
    text: 'Save',
    type: 'default',
    stylingMode: 'contained',
    useSubmitBehavior: true
  };
  extrasButtonOptions = {
    text: 'Add Extra',
    type: 'default',
    stylingMode: 'outlined',
    onClick: () => {
      this.extrasPopup();
    },
    useSubmitBehavior: false
  };
  zeroOrderButtonOptions = {
    text: 'Add Zero Value Order',
    type: 'default',
    stylingMode: 'outlined',
    onClick: () => {
      this.zeroOrderPopup();
    },
    useSubmitBehavior: false
  };
  orderButtonOptions = { icon: 'edit', onClick: this.orderLookup.bind(this) };
  overBudgetButtonOptions: object;
  vendors: Vendor[];
  jobs: Job[];
  costCentres: PriceFileItem[];
  orderNumberOptions: object;
  totalIncGSTOptions: object;
  showOrderLookupPopup: boolean;
  selectedOrderId: number;
  totalGSTOptions: object;
  fileUploadOptions: object;
  files: any[] = [];
  orderLookupSwitch = true;
  selectedVendorId: number;
  addExtraVisible: boolean;
  defaultDescription: string;
  splitOrders: SplitOrder[];
  isOverBudget = false;
  overBudgetOtherPOsText: string;
  invoiceDateOptions: object;
  vendorOptions: object;
  invoiceNumberOptions: object;

  constructor(
    private _activeModal: NgbActiveModal,
    private companyService: CompanyService,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private poService: PoService,
    private estimatingService: EstimatingService,
    private notiService: NotificationService,
    private invoiceService: InvoiceService,
    private utilService: UtilsService,
    private modalService: NgbModal
  ) {
    this.extrasPopup = this.extrasPopup.bind(this);
    this.zeroOrderPopup = this.zeroOrderPopup.bind(this);
    this.calcIfOverBudget = this.calcIfOverBudget.bind(this);
    this.totalIncGSTChanged = this.totalIncGSTChanged.bind(this);
    this.invoiceNumberChanged = this.invoiceNumberChanged.bind(this);
    this.vendorChanged = this.vendorChanged.bind(this);
    this.calculateSiteManagerTitle = this.calculateSiteManagerTitle.bind(this);

    this.orderNumberOptions = {
      onValueChanged: (e) => {
        this.orderNumberChanged(e.value);
      }
    };

    this.invoiceNumberOptions = {
      onValueChanged: (e) => {
        this.invoiceNumberChanged(e.value);
      }
    };

    this.totalIncGSTOptions = {
      // value: this.invoiceForm?.totalIncGST,
      onValueChanged: (e) => {
        this.totalIncGSTChanged(e.value);
      }
    };

    this.totalGSTOptions = {
      // value: this.invoiceForm ? this.invoiceForm.totalGST : null,
      onValueChanged: (e) => {
        this.totalGSTChanged(e.value);
      }
    };

    this.invoiceDateOptions = {
      type: 'date',
      useMaskBehavior: true
    };

    this.fileUploadOptions = {
      onValueChanged: (e) => {
        this.files = e.value;
      }
    };
  }

  ngOnInit() {
    this.vendors = this.maintenanceService.allVendors.filter(i => i.canAcceptInvoices && i.isActive);

    this.vendorOptions = {
      items: this.vendors,
      required: true,
      displayExpr: 'vendorName',
      valueExpr: 'id',
      searchEnabled: true,
      onValueChanged: (e) => {
        this.vendorChanged(e.value);
      }
    };

    this.jobs = [new Job(null, 'Not Found')];
    this.jobs = this.jobs.concat(this.jobService.jobs);
    this.costCentres = [new PriceFileItem(null, 0, '', 'Not Found')];
    this.costCentres = this.costCentres.concat(this.estimatingService.costCentres);
    this.invoiceForm = new InvoiceAddForm;
    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cancel() {
    this._activeModal.dismiss();
  }

  orderNumberChanged(newOrderNumber: string) {
    this.invoiceForm.orderNumber = newOrderNumber.trim();
    const orderNumberArray = newOrderNumber.trim().split('.');
    // let validOrder = false;
    this.invoiceForm.purchaseOrderId = null;
    this.invoiceForm.poNumber = null;
    this.invoiceForm.jobId = null;
    this.invoiceForm.jobAddressString = 'NOT FOUND';
    this.invoiceForm.costCentreDesc = 'PO NOT FOUND';
    this.invoiceForm.siteManager = 'Unassigned';

    if (orderNumberArray.length >= 2) {
      const jobNumber = orderNumberArray[0];
      this.invoiceForm.jobNumber = jobNumber;
      const job = this.jobs.find(i => i.jobNumber === jobNumber);
      if (job) {
        this.invoiceForm.jobId = job.id;
        this.jobService.setCurrentJob(job.jobNumber);
        this.invoiceForm.jobAddressString = job.jobAddressString;

        const siteManager =
          this.jobService.jobRoles.find(i => i.jobId === job.id && i.roleId === RoleTypeEnum.SiteManager)?.user.fullName;
        if (siteManager && siteManager !== '') {
          this.invoiceForm.siteManager = siteManager;
        }

        const orderNumber = orderNumberArray[1] + (orderNumberArray.length >= 3 ? '.' + orderNumberArray[2] : '');

        // look this up to see if we can find the matching PO
        const po = this.poService.allPurchaseOrdersForCompany.find(i => i.jobId === job.id && i.poNumber === orderNumber);
        if (po) {
          this.invoiceForm.purchaseOrderId = po.id;
          this.invoiceForm.poNumber = orderNumber;
          this.invoiceForm.costCentreId = po.costCentreId;

          const costCentre = this.costCentres.find(i => i.id === po.costCentreId);
          this.invoiceForm.costCentreDesc = costCentre?.description;

          if (this.invoiceForm.vendorId !== po.vendorId && this.invoiceForm.vendorId) {
            const vendor = this.maintenanceService.allVendors.find(i => i.id === po.vendorId);
            if (!vendor?.canAnyVendorInvoice) {
              if (!this.maintenanceService.vendorPayables
                .find(i => i.orderVendorId === po.vendorId && i.payVendorId === this.invoiceForm.vendorId)) {
                this.notiService.showError('Cannot accept invoices from this vendor for this order');
              }
            }
          }
        }

        this.calcIfOverBudget();
      }
    }
  }

  orderLookup() {
    this.selectedOrderId = this.invoiceForm.purchaseOrderId;
    this.showOrderLookupPopup = true;
    this.selectedVendorId = this.invoiceForm.vendorId;
    this.orderLookupSwitch = !this.orderLookupSwitch;
  }

  getOrderFromLookup(orderId: number) {
    this.showOrderLookupPopup = false;
    if (orderId) {
      const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === orderId);
      if (po) {
        const job = this.jobs.find(i => i.id === po.jobId);
        this.invoiceForm.orderNumber = job?.jobNumber + '.' + po.poNumber;
        this.orderNumberChanged(this.invoiceForm.orderNumber);
      }
    }
    this.splitOrders = null;
  }

  getOrdersFromLookup(orders: SplitOrder[]) {
    this.showOrderLookupPopup = false;
    this.invoiceForm.orderNumber = 'Invoice split';
    this.splitOrders = orders;
  }

  vendorChanged(value: any) {
    this.invoiceForm.vendorId = value;
    const vendor = this.vendors.find(i => i.id === value);
    if (vendor?.isGstFree) {
      this.invoiceForm.totalGST = 0;
      this.notiService.showInfo('GST Free Vendor');
    } else {
      this.invoiceForm.totalGST = Math.round((this.invoiceForm.totalIncGST / (1 + this.invoiceService.globalGSTRate)) * 100) / 100;
    }
    this.invoiceForm.totalExGST = Math.round((this.invoiceForm.totalIncGST - this.invoiceForm.totalGST) * 100) / 100;

    if (!vendor?.vendorGroupId) {
      this.notiService.showWarning('Vendor Group not set. Cannot calculate the due date.');
    }

    // check if we are going over budget and check for other invoices
    this.calcIfOverBudget();
  }

  invoiceNumberChanged(value: string) {
    // check for duplicate invoice number
    if (this.invoiceForm.vendorId && this.invoiceForm.jobId && value) {
      this.subscriptions.push(
        this.invoiceService.getVendorInvoice(this.invoiceForm.vendorId, this.invoiceForm.jobId, value)
          .subscribe({
            next: (invoices) => {
              const invoicesToCheck = invoices.filter(i => i.invoiceStatusId <= InvoiceStatusTypeEnum.Paid);
              if (invoicesToCheck && invoicesToCheck.length) {
                const invoiceStatus = InvoiceStatuses.find(i => i.id === invoicesToCheck[0].invoiceStatusId)?.description;
                this.notiService.showWarning('Duplicate invoice exists on P.O. ' + invoices[0].poNumber + ' with status ' + invoiceStatus);
              }
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  totalIncGSTChanged(value: any) {
    const valueString = this.utilService.calcQtyFromString(this.utilService.sanitizeQty(value));
    if (!isNaN(+valueString)) {
      if (this.invoiceForm.totalIncGST !== +valueString) {
        this.invoiceForm.totalIncGST = +valueString;
        const vendor = this.vendors.find(i => i.id === this.invoiceForm.vendorId);
        if (vendor?.isGstFree) {
          this.invoiceForm.totalGST = 0;
          this.notiService.showInfo('GST Free Vendor');
        } else {
          this.invoiceForm.totalGST = Math.round((+valueString / (1 + this.invoiceService.globalGSTRate)) * 100) / 100;
        }
        this.invoiceForm.totalExGST = Math.round((+valueString - this.invoiceForm.totalGST) * 100) / 100;

        // check if we are going over budget and check for other invoices
        this.calcIfOverBudget();
      }
    }
  }

  totalGSTChanged(value: number) {
    if (!value) {
      this.invoiceForm.totalGST = 0;
      this.invoiceForm.totalExGST = this.invoiceForm.totalIncGST;
    } else {
      this.invoiceForm.totalExGST = Math.round((this.invoiceForm.totalIncGST - value) * 100) / 100;
    }

    // check if we are going over budget and check for other invoices
    this.calcIfOverBudget();
  }


  async update() {
    if (this.maintenanceService.orderControl.minPostingDate
      && this.invoiceService.dateDiffInDays(new Date(this.maintenanceService.orderControl.minPostingDate.valueOf()), this.invoiceForm.invoiceDate) > 0) {
      this.notiService.showWarning('Date older than minimum allowed');
    } else {
      this.loading = true;
      this.invoiceForm.invoiceBatchId = this.selectedInvoiceBatchId;
      this.invoiceForm.invoiceStatusId = 1;
      this.invoiceForm.hasBlob = false;
      this.invoiceForm.isWorkDone = false;
      this.invoiceForm.totalGST = this.invoiceForm.totalGST ? this.invoiceForm.totalGST : 0;
      this.invoiceForm.dueDate = await this.invoiceService.calcDueDate(this.invoiceForm.vendorId, this.invoiceForm.invoiceDate, new Date(), this.invoiceForm.purchaseOrderId, this.invoiceForm.jobId);

      if (this.invoiceForm.orderNumber === 'Invoice split') {
        if (this.splitOrders && this.splitOrders.length) {
          this.invoiceForm.splitOrders = this.splitOrders;
          this.subscriptions.push(
            this.invoiceService.addInvoice(this.invoiceForm).subscribe({
              next: (res) => {
                if (this.files && this.files.length) {
                  this.uploadClick(res.id);
                } else {
                  this._activeModal.close();
                }
              }, error: (err) => {
                this.notiService.notify(err);
                this.files = [];
                this.loading = false;
              }
            })
          );
        } else {
          this.notiService.showError('No orders selected');
        }
      } else {
        this.invoiceForm.splitOrders = [];
        this.subscriptions.push(
          this.invoiceService.addInvoice(this.invoiceForm).subscribe({
            next: (res) => {
              if (this.files && this.files.length) {
                this.uploadClick(res.id);
              } else {
                this._activeModal.close();
              }
            }, error: (err) => {
              this.notiService.notify(err);
              this.files = [];
              this.loading = false;
            }
          })
        );
      }
    }
  }

  uploadClick(id: number) {
    // load the file
    const formData: FormData = new FormData();
    formData.append('image', this.files[0], this.files[0].name);

    this.subscriptions.push(
      this.invoiceService.uploadInvoice(id, formData).subscribe({
        next: () => {
          this._activeModal.close();
        }, error: (err) => {
          this.notiService.notify(err);
          this.files = [];
          this.splitOrders = [];
          this.loading = false;
        }
      })
    );
  }

  extrasPopup() {
    if (!this.invoiceForm.purchaseOrderId) {
      this.notiService.showInfo('select a valid order to add an extra to it.');
    } else {
      const vendor = this.vendors.find(i => i.id === this.invoiceForm.vendorId);
      this.defaultDescription = (vendor ? 'Vendor: ' + vendor.vendorName : '');
      this.defaultDescription += this.invoiceForm.invoiceNumber && this.invoiceForm.invoiceNumber !== '' ?
        ' - Invoice: ' + this.invoiceForm.invoiceNumber : '';
      this.addExtraVisible = true;
    }
  }

  extrasClose() {
    this.addExtraVisible = false;
  }

  zeroOrderPopup() {
    // clear current job
    this.jobService.setCurrentJob('');
    this.jobService.currentJob = null;

    const modalRef = this.modalService.open(CreateZeroPoComponent, { windowClass: 'modal-edit2' });

    modalRef.result.then(purchaseOrderId => {
      if (purchaseOrderId) {
        const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === purchaseOrderId);
        if (po) {
          const job = this.jobs.find(i => i.id === po.jobId);
          this.invoiceForm.orderNumber = job?.jobNumber + '.' + po.poNumber;
          this.orderNumberChanged(this.invoiceForm.orderNumber);
        }
      }
      this.splitOrders = null;
    });
  }

  calcIfOverBudget() {
    if (this.invoiceForm.purchaseOrderId && this.invoiceForm.vendorId && this.invoiceForm.totalExGST) {
      const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === this.invoiceForm.purchaseOrderId);
      let remainingAmount = 0;
      if (po) {
        remainingAmount = po.orderTotal - (po.approvedInvoicesTotal ? po.approvedInvoicesTotal : 0) - this.invoiceForm.totalExGST;

        // check other invoices not processed in this batch only
        if (this.currentBatchInvoices) {
          this.currentBatchInvoices.filter(i => i.purchaseOrderId === this.invoiceForm.purchaseOrderId).forEach(invoice => {
            remainingAmount = remainingAmount - invoice.totalExGST;
          });
        }
      }

      remainingAmount += (this.maintenanceService.orderControl.invoiceThreshold ?? 0);
      this.isOverBudget = (remainingAmount < 0);

      if (this.isOverBudget) {
        // are there other orders we could use spits for
        const pos = this.poService.allPurchaseOrdersForCompany
          .filter(i => i.jobId === po.jobId && i.costCentreId === po.costCentreId && i.id !== po.id
            && (i.orderTotal - (i.approvedInvoicesTotal ? i.approvedInvoicesTotal : 0) > 0));

        let count = 0;
        pos.forEach(pOrder => {
          if (pOrder.vendorId === this.invoiceForm.vendorId) {
            count++;
          } else {
            // check for other orders we could use
            const vendorCanInvoice = this.maintenanceService.allVendors.find(i => i.id === pOrder.vendorId);

            if (vendorCanInvoice?.canAnyVendorInvoice) {
              count++;
            } else {
              const vendorPayable = this.maintenanceService.vendorPayables
                .find(i => i.orderVendorId === pOrder.vendorId && i.payVendorId === this.invoiceForm.vendorId);

              if (vendorPayable) {
                count++;
              }
            }
          }
        });

        if (count > 0) {
          this.overBudgetOtherPOsText = 'Over Budget! - Other possible orders exist';
        } else {
          this.overBudgetOtherPOsText = 'Over Budget!';
        }
        this.overBudgetButtonOptions = { text: this.overBudgetOtherPOsText, stylingMode: 'text', type: 'danger' };
      }
    } else {
      this.isOverBudget = false;
    }
  }

  calculateSiteManagerTitle() {
    return this.companyService.companyRoles.find(i => i.roleId === RoleTypeEnum.SiteManager)?.companyRoleDescription;
  }
}
