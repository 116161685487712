import { ConstructionStageTypeEnum } from './../../dtos/construction-stage-type';
import { UserService } from './user.service';
import { AttachmentTypeEnum } from './../../dtos/attachment-type.enum';
import { PriceFileItem } from './../../dtos/price-file-item';
import { HouseType } from './../../dtos/house-type';
import { EstimatingService } from './estimating.service';
import { SelectedItem } from '../../dtos/selected-item';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { MaintenanceService } from './maintenance.service';
import { JobService } from './job.service';
import { OrderHeader } from '../../dtos/order-header';
import { District } from '../../dtos/district';
import { OrderLine, OrderLineMove } from '../../dtos/order-line';
import { OrderLineLength } from '../../dtos/order-line-length';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { Phase } from '../../dtos/phase';
import { OrderResponse } from '../../dtos/order-response';
import { OrderLineExtra } from '../../dtos/order-line-extra';
import { PurchaseOrderDocument } from '../../dtos/purchase-order-document';
import { PDFReports } from '../../dtos/pdf-report';
import { JobCostCentre } from '../../dtos/job-cost-centre';
import { VarianceCode } from '../../dtos/variance-code';
import { HttpService } from '../http.service';
import { Vendor } from '../../dtos/vendor';
import { Task } from '../../dtos/task';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShowPdfComponent } from '../../shared/show-pdf/show-pdf.component';
import { Invoice } from '../../dtos/invoice';
import { saveAs } from 'file-saver';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class PoService {
  orderLines: OrderLine[];
  purchaseOrdersForJob: PurchaseOrder[];
  currentPurchaseOrder: PurchaseOrder;
  currentPhase: Phase;
  orderLineExtras: OrderLineExtra[];
  purchaseOrderDocuments: PurchaseOrderDocument[];
  purchaseOrderDocumentsJobId: number;
  jobCostCentres: JobCostCentre[];
  allPurchaseOrdersForCompany: PurchaseOrder[];
  allPurchaseOrdersForCompanyCompanyId: string;
  invoicesForThisJob: Invoice[];

  constructor(
    private _http: HttpClient,
    private maintenanceService: MaintenanceService,
    private companyService: CompanyService,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private httpService: HttpService,
    private globalService: GlobalService,
    private userService: UserService,
    private modalService: NgbModal) { }


  getOrderHeader(jobId: number): Observable<OrderHeader> {
    return this._http.get<OrderHeader>(this.globalService.getApiUrl() +
      '/job/' + jobId + '/headers', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addOrderHeader(dataRecord: any): Observable<OrderHeader> {
    const url = this.globalService.getApiUrl() + '/order-headers';
    return this._http.post<OrderHeader>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateOrderHeader(id: number, itm: any): Observable<OrderHeader> {
    const url = this.globalService.getApiUrl() + '/order-headers/' + id;
    return this._http.patch<OrderHeader>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  getOrdersDataForInvoices(useCache: boolean): Observable<PurchaseOrder[]> {
    return forkJoin(
      [
        this.getAllPurchaseOrders(useCache),
        this.maintenanceService.getPhases(useCache),
        this.maintenanceService.getAllVendors(useCache),
        this.estimatingService.getPriceFileItemGroups(useCache)
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getOrdersData(): Observable<District[]> {
    return forkJoin(
      [
        this.maintenanceService.getDistricts(true),
        this.maintenanceService.getPhases(true),
        this.maintenanceService.getUnitOfMeasures(true),
        this.maintenanceService.getAllVendors(true),
        this.maintenanceService.getQtySizeControls(true),
        this.getOrdersData2(),
        this.companyService.getCompanyRoles(true)
      ]
    )
      .pipe(map(
        ([districts]) => {
          return districts;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getOrdersData2(): Observable<HouseType[]> {
    return forkJoin(
      [
        this.jobService.getHouseTypes(),
        this.maintenanceService.getExtraCodes(true),
        this.maintenanceService.getExtraReasons(true, false),
        this.maintenanceService.getOrderControlData(),
        this.getPriceFileGroupsWithData(),
        this.userService.getCurrCompUsers(true)
      ]
    )
      .pipe(map(
        ([houseTypes]) => {
          return houseTypes;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getExtrasData(): Observable<VarianceCode[]> {
    return forkJoin(
      [
        this.maintenanceService.getExtraCodes(true),
        this.maintenanceService.getExtraReasons(true, false),
        this.jobService.getJobRoles(true),
        this.getAllPurchaseOrders(true),
        this.estimatingService.getPriceFileItemGroups(true)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getOrdersDataForAccruals(): Observable<Vendor[]> {
    return forkJoin(
      [
        this.maintenanceService.getAllVendors(true),
        this.maintenanceService.getDistricts(true),
        this.maintenanceService.getPhases(true),
        this.jobService.getJobsByAddressWithExtras(false),
        this.estimatingService.getPriceFileItemGroups(true)
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getDataForExtrasReport(): Observable<Vendor[]> {
    return forkJoin(
      [
        this.maintenanceService.getAllVendors(true),
        this.maintenanceService.getDistricts(true),
        this.jobService.getJobsByAddressWithExtras(false),
        this.estimatingService.getPriceFileItemGroups(true),
        this.getExtrasData(),
        this.maintenanceService.getOrderControlData()
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getPODocuments(jobId: number): Observable<PurchaseOrderDocument[]> {
    if (this.purchaseOrderDocuments && this.purchaseOrderDocuments.length && this.purchaseOrderDocumentsJobId === jobId) {
      return of(this.purchaseOrderDocuments);
    } else {
      // return this._http.get<OrderLine[]>(this.globalService.getApiUrl() +
      //   '/job/' + jobId + '/order-lines', this.httpService.getHttpOptions()).pipe(
      return this._http.get<PurchaseOrderDocument[]>(this.globalService.getApiUrl() + '/jobs/' + jobId + '/purchase-order-documents',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.purchaseOrderDocuments = res;
            this.purchaseOrderDocumentsJobId = jobId;
          }),
          catchError(this.handleError));
    }
  }

  addPODocuments(poId: number, items: any) {
    const url = this.globalService.getApiUrl() + '/purchase-order-documents/' + poId;
    return this._http.post(url, JSON.stringify(items), this.httpService.getHttpOptions());
  }

  getPriceFileGroupsWithData(): Observable<PriceFileItem[]> {
    return forkJoin(
      [
        this.estimatingService.getPriceFileItemGroups(false),
        this.maintenanceService.getCostCentreCallUpDocs(false),
        this.maintenanceService.getCallUpDocsTypes(true)
      ]
    )
      .pipe(map(
        ([priceFileItemGroups]) => {
          this.estimatingService.costCentres.forEach(priceFileItem => {
            priceFileItem.callUpDocsTypes = [];
            this.maintenanceService.costCentreCallUpDocs?.filter(i => i.costCentreId === priceFileItem.id).forEach(docsType => {
              priceFileItem.callUpDocsTypes.push(docsType.callUpDocsTypeId);
            });
          });
          return priceFileItemGroups;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getOrderHeaderData(jobId: number, useCache: boolean): Observable<OrderHeader> {
    return forkJoin(
      [
        this.getOrderHeader(jobId),
        this.getOrderLines(jobId, null),
        this.getPurchaseOrdersForJob(jobId),
        this.estimatingService.getPriceFileItemGroups(useCache),
        this.getOrderLineExtras(jobId),
        this.getJobCostCentres(jobId),
        this.getInvoicesForJob(jobId)
      ]
    )
      .pipe(map(
        ([orderHeader]) => {
          return orderHeader;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getOrdersAndInvoicesForJob(jobId: number): Observable<PurchaseOrder[]> {
    return forkJoin(
      [
        this.getPurchaseOrdersForJob(jobId),
        this.getInvoicesForJob(jobId)
      ]
    )
      .pipe(map(
        ([res]) => {
          return res;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getInvoicesForJob(jobId: number): Observable<Invoice[]> {
    const url = this.globalService.getApiUrl() + '/invoices-for-job/' + jobId;

    return this._http.get<Invoice[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.invoicesForThisJob = res;
      }),
      catchError(this.handleError));
  }

  getOrderLines(jobId: number, purchaseOrderId: number): Observable<OrderLine[]> {
    let url = this.globalService.getApiUrl() + '/job/' + jobId + '/order-lines';
    if (purchaseOrderId) {
      url += '?purchaseOrderId=' + purchaseOrderId;
    }
    return this._http.get<OrderLine[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.orderLines = res;
      }),
      catchError(this.handleError));
  }

  getOrderLinesForCopy(jobId: number): Observable<OrderLine[]> {
    let url = this.globalService.getApiUrl() + '/job/' + jobId + '/order-lines';
    return this._http.get<OrderLine[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getPurchaseOrdersForJob(jobId: number): Observable<PurchaseOrder[]> {
    return this._http.get<PurchaseOrder[]>(this.globalService.getApiUrl() +
      '/purchase-orders?jobId=' + jobId, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.purchaseOrdersForJob = res;
        }),
        catchError(this.handleError));
  }

  getAllPurchaseOrders(useCache: boolean): Observable<PurchaseOrder[]> {
    if (useCache && this.allPurchaseOrdersForCompany && this.allPurchaseOrdersForCompany.length
      && this.allPurchaseOrdersForCompanyCompanyId === this.globalService.getCurrentCompanyId()) {
      return of(this.allPurchaseOrdersForCompany);
    } else {
      return this._http.get<PurchaseOrder[]>(this.globalService.getApiUrl() +
        '/purchase-orders/active-jobs', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.allPurchaseOrdersForCompany = res;
            this.allPurchaseOrdersForCompanyCompanyId = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  updatePurchaseOrder(id: string, itm: any): Observable<PurchaseOrder> {
    const url = this.globalService.getApiUrl() + '/purchase-orders/' + id;
    return this._http.patch<PurchaseOrder>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  stopAllAccruals(jobId: number): Observable<PurchaseOrder> {
    const url = this.globalService.getApiUrl() + '/job/' + jobId + '/purchase-orders/stop-all-accruals';
    return this._http.patch<PurchaseOrder>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  bulkAddExtra(updateData) {
    const url = this.globalService.getApiUrl() + '/purchase-orders/bulk-add-extra';
    return this._http.patch(url, JSON.stringify(updateData), this.httpService.getHttpOptions());
  }

  getPurchaseOrder(jobId: number, orderNumber: string): Observable<PurchaseOrder> {
    let po = this.allPurchaseOrdersForCompany?.find(i => i.jobId === jobId && i.poNumber === orderNumber);
    if (po) {
      return of(po);
    } else {
      po = this.allPurchaseOrdersForCompany?.find(i => i.jobId === jobId && i.poNumber === orderNumber);
      if (po) {
        return of(po);
      } else {
        return this._http.get<PurchaseOrder>(this.globalService.getApiUrl() +
          '/purchase-orders/order?jobId=' + jobId + '&orderNumber=' + orderNumber, this.httpService.getHttpOptions()).pipe(
            tap(res => {
              this.allPurchaseOrdersForCompany.push(res);
            }),
            catchError(this.handleError));
      }
    }
  }

  getPurchaseOrderData(jobId: number): Observable<PurchaseOrder[]> {
    return forkJoin(
      [
        this.getPurchaseOrdersForJob(jobId),
        this.jobService.getJobDocuments(true, jobId),
        this.getPODocuments(jobId)
      ]
    )
      .pipe(map(
        ([purchaseOrders]) => {
          return purchaseOrders;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  addOrderLine(dataRecord: any): Observable<OrderLine> {
    const url = this.globalService.getApiUrl() + '/order-lines';
    return this._http.post<OrderLine>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  addOrderLineWithExtra(dataRecord: any): Observable<OrderLine> {
    const url = this.globalService.getApiUrl() + '/order-lines/extra';
    return this._http.post<OrderLine>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateOrderLine(id: string, itm: any): Observable<OrderLine> {
    const url = this.globalService.getApiUrl() + '/order-lines/' + id;
    return this._http.patch<OrderLine>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteOrderLine(id: string) {
    const url = this.globalService.getApiUrl() + '/order-lines/' + id;
    return this._http.delete<OrderLine>(url, this.httpService.getHttpOptions());
  }


  updateOrderLineExtra(id: string, itm: any): Observable<OrderLineExtra> {
    const url = this.globalService.getApiUrl() + '/order-line-extras/' + id;
    return this._http.patch<OrderLineExtra>(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
      tap(res => {
        const orderLineExtra = this.orderLineExtras.find(i => i.id === +id);
        if (orderLineExtra) {
          if (itm.varianceCodeId !== undefined) {
            orderLineExtra.varianceCodeId = itm.varianceCodeId;
          }
          if (itm.varianceReasonDescription !== undefined) {
            orderLineExtra.varianceReasonDescription = itm.varianceReasonDescription;
          }
          if (itm.varianceTotal !== undefined) {
            orderLineExtra.varianceTotal = itm.varianceTotal;
          }
        }
      }),
      catchError(this.handleError));
  }

  deleteOrderLineExtra(id: string) {
    const url = this.globalService.getApiUrl() + '/order-line-extras/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }


  addOrderLinesFromSelectedRecipes(orderHeaderId: number, selectedItems: SelectedItem[],
    multiAddVarianceCodeId: number, multiAddVarianceReason: string, purchaseOrderId: number) {
    let url = this.globalService.getApiUrl() + '/order-lines/' + orderHeaderId + '/multiple-recipes';

    if (multiAddVarianceCodeId) {
      url += '?varianceCodeId=' + multiAddVarianceCodeId;
    }
    if (multiAddVarianceReason && multiAddVarianceReason !== '') {
      url += '&varianceReason=' + multiAddVarianceReason;
    }
    if (purchaseOrderId) {
      url += '&purchaseOrderId=' + purchaseOrderId;
    }

    return this._http.post(url, JSON.stringify({ 'selectedItems': selectedItems }), this.httpService.getHttpOptions());
  }

  moveOrderLinesToNewPO(jobId: number, linesToMove: OrderLineMove[], newVendorId: number) {
    let url = this.globalService.getApiUrl() + '/order-lines/move?jobId=' + jobId;
    if (newVendorId) {
      url += '&newVendorId=' + newVendorId;
    }
    return this._http.post(url, JSON.stringify({ 'linesToMove': linesToMove }), this.httpService.getHttpOptions());
  }


  getOrderLineLengths(orderLineId: number): Observable<OrderLineLength[]> {
    return this._http.get<OrderLineLength[]>(this.globalService.getApiUrl() +
      '/order-line/' + orderLineId + '/lengths', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addOrderLineLength(dataRecord: any): Observable<OrderLineLength> {
    const url = this.globalService.getApiUrl() + '/order-line-lengths';
    return this._http.post<OrderLineLength>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateOrderLineLength(id: string, itm: any): Observable<OrderLineLength> {
    const url = this.globalService.getApiUrl() + '/order-line-lengths/' + id;
    return this._http.patch<OrderLineLength>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteOrderLineLength(id: string): Observable<OrderLineLength> {
    const url = this.globalService.getApiUrl() + '/order-line-lengths/' + id;
    return this._http.delete<OrderLineLength>(url, this.httpService.getHttpOptions());
  }


  loadOrderLinesFromExcel(xlFile, orderHeaderId: number, useLoadedDescription: boolean,
    stopIfExisting: boolean, useLoadedRate: boolean) {
    const options = this.httpService.getHttpFileOptions();
    let url = this.globalService.getApiUrl() + '/order-lines/' + orderHeaderId
      + '/excel-upload?useLoadedDescription=' + useLoadedDescription
      + '&stopIfExisting=' + stopIfExisting
      + '&useLoadedRate=' + useLoadedRate;

    return this._http.post(url, xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  loadOrderLinesFromRecipe(jobId: number, recipeId: number, stopIfExisting: boolean, quantity: number) {
    const options = this.httpService.getHttpOptions();
    const url = this.globalService.getApiUrl() + '/order-lines/' + jobId
      + '/load-recipe?recipeId=' + recipeId + '&stopIfExisting=' + stopIfExisting
      + '&quantity=' + quantity;

    return this._http.patch(url, {}, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }


  copyJob(fromJobId: number, toOrderHeaderId: number, zeroQuantities: boolean,
    selectedIds: number[], ignoreExistingCostCentres: boolean): Observable<OrderLine[]> {
    const url = this.globalService.getApiUrl() + '/order-lines/' + toOrderHeaderId + '/copy-job?fromJobId=' + fromJobId
      + '&zeroQuantities=' + zeroQuantities
      + '&ignoreExistingCostCentres=' + ignoreExistingCostCentres;
    const dataRecord = { ids: selectedIds };
    return this._http.post<OrderLine[]>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }


  generateOrders(jobId: number, costCentreIds: number[], phaseId: number, constructionStageId: number): Observable<OrderLine[]> {
    let url = this.globalService.getApiUrl() + '/order-lines/' + jobId + '/generate';

    if (phaseId !== null) {
      url += '?phaseId=' + phaseId;
    }

    if (constructionStageId !== null) {
      if (phaseId !== null) {
        url += '&';
      } else {
        url += '?';
      }
      url += 'constructionStageId=' + constructionStageId;
    }

    const dataRecord = { ids: costCentreIds };
    return this._http.patch<OrderLine[]>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  deletePurchaseOrder(purchaseOrderid: number) {
    const url = this.globalService.getApiUrl() + '/purchase-orders/' + purchaseOrderid;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  cancelPurchaseOrder(purchaseOrderid: number, varianceCodeId: number, varianceReason: string, setCallUpToFalse: boolean) {
    const url = this.globalService.getApiUrl() + '/purchase-orders/' + purchaseOrderid + '/cancel?varianceCodeId='
      + varianceCodeId + '&varianceReason=' + varianceReason + '&setCallUpToFalse=' + setCallUpToFalse;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  changeVendorForPurchaseOrder(purchaseOrderid: number, varianceCodeId: number, varianceReason: string,
    setCallUpToFalse: boolean, effectiveDate: string, vendorId: number, isZeroRates: boolean) {
    let url = this.globalService.getApiUrl() + '/purchase-orders/' + purchaseOrderid + '/change-vendor?varianceCodeId='
      + varianceCodeId + '&varianceReason=' + varianceReason + '&setCallUpToFalse=' + setCallUpToFalse
      + '&effectiveDate=' + effectiveDate + '&vendorId=' + vendorId;

    url += '&isZeroRates=' + isZeroRates;

    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  changePhaseForPurchaseOrder(purchaseOrderid: number, newPhaseId: number) {
    let url = this.globalService.getApiUrl() + '/purchase-orders/' + purchaseOrderid + '/change-phase';

    if (newPhaseId) {
      url += '?newPhaseId=' + newPhaseId;
    }
    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  revaluePurchaseOrder(purchaseOrderid: number, varianceCodeId: number, varianceReason: string, effectiveDate: string,
    markOrderNotSent: boolean): Observable<number> {
    const url = this.globalService.getApiUrl() + '/purchase-orders/' + purchaseOrderid + '/revalue?varianceCodeId='
      + varianceCodeId + '&varianceReason=' + varianceReason + '&effectiveDate=' + effectiveDate
      + '&markOrderNotSent=' + markOrderNotSent;
    return this._http.patch<number>(url, '', this.httpService.getHttpOptions());
  }


  sendPurchaseOrders(jobId: number, purchaseOrderDto: any): Observable<OrderResponse> {
    if (purchaseOrderDto.download) {
      return this._http.post<OrderResponse>(this.globalService.getApiUrl() +
        '/purchase-orders/' + jobId + '/send-pdfs', JSON.stringify(purchaseOrderDto), this.httpService.getHttpOptions()).pipe(
          catchError(this.handleError));
    } else {
      // queue them
      return this._http.post<OrderResponse>(this.globalService.getApiUrl() +
        '/purchase-orders/' + jobId + '/queue-orders', JSON.stringify(purchaseOrderDto), this.httpService.getHttpOptions()).pipe(
          catchError(this.handleError));
    }
  }

  markPurchaseOrdersSent(jobId: number, purchaseOrderDto: any) {
    return this._http.post(this.globalService.getApiUrl() +
      '/purchase-orders/' + jobId + '/mark-as-sent', JSON.stringify(purchaseOrderDto), this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }



  getOrderLineExtras(jobId: number): Observable<OrderLineExtra[]> {
    return this._http.get<OrderLineExtra[]>(this.globalService.getApiUrl() +
      '/order-line-extras?jobId=' + jobId, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.orderLineExtras = res;
        }),
        catchError(this.handleError));
  }

  getAllOrderLineExtras(includeInactiveJobs: boolean): Observable<OrderLineExtra[]> {
    return this._http.get<OrderLineExtra[]>(this.globalService.getApiUrl() +
      '/order-line-extras?includeInactiveJobs=' + includeInactiveJobs, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }


  getJobCostCentres(jobId: number): Observable<JobCostCentre[]> {
    return this._http.get<JobCostCentre[]>(this.globalService.getApiUrl() +
      '/job/' + jobId + '/cost-centres', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobCostCentres = res;
        }),
        catchError(this.handleError));
  }

  addJobCostCentre(dataRecord: any): Observable<JobCostCentre> {
    const url = this.globalService.getApiUrl() + '/job-cost-centres';
    return this._http.post<JobCostCentre>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.jobCostCentres.push(res);
      }),
      catchError(this.handleError));
  }

  updateJobCostCentre(id: number, itm: any): Observable<JobCostCentre> {
    const url = this.globalService.getApiUrl() + '/job-cost-centres/' + id;
    return this._http.patch<JobCostCentre>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }



  recostItems(jobId: number, costCentreId: number, recostLockedLines: boolean): Observable<OrderLine[]> {
    const url = this.globalService.getApiUrl() + '/order-lines/' + jobId
      + '/recost?costCentreId=' + costCentreId + '&recostLockedLines=' + recostLockedLines;

    return this._http.patch<OrderLine[]>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  changeVendor(jobId: number, costCentreId: number, oldVendorId: number,
    newVendorId: number, isZeroRates: boolean): Observable<OrderLine[]> {
    let url = this.globalService.getApiUrl() + '/order-lines/' + jobId
      + '/change-vendor?costCentreId=' + costCentreId + '&newVendorId=' + newVendorId;

    if (oldVendorId) {
      url += '&oldVendorId=' + oldVendorId;
    }

    url += '&isZeroRates=' + isZeroRates;

    return this._http.patch<OrderLine[]>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  getOrdersForVendorForChange(vendorId: number): Observable<PurchaseOrder[]> {
    return this._http.get<PurchaseOrder[]>(this.globalService.getApiUrl() +
      '/purchase-orders/' + vendorId + '/orders-to-change-vendor', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getOrdersForRevalue(vendorId: number, revalueOrdersWithInvoices: boolean): Observable<PurchaseOrder[]> {
    return this._http.get<PurchaseOrder[]>(this.globalService.getApiUrl() +
      '/purchase-orders/' + vendorId + '/orders-to-revalue?revalueOrdersWithInvoices=' + revalueOrdersWithInvoices,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  markItemsToBeChecked(jobId: number, costCentreId: number): Observable<OrderLine[]> {
    const url = this.globalService.getApiUrl() + '/order-lines/' + jobId
      + '/mark-to-be-checked?costCentreId=' + costCentreId;

    return this._http.patch<OrderLine[]>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  deleteAllItems(jobId: number, costCentreId: number) {
    const url = this.globalService.getApiUrl() + '/order-lines/' + jobId
      + '/delete-all?costCentreId=' + costCentreId;

    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getTasksForPurchaseOrder(purchaseOrderId: number, jobId: number): Observable<Task[]> {
    return this._http.get<Task[]>(this.globalService.getApiUrl() +
      '/job-tasks/' + jobId + '/for-po/' + purchaseOrderId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }


  searchOrderLines(searchData: object): Observable<OrderLine[]> {
    const url = this.globalService.getApiUrl() + '/order-lines/search';
    return this._http.post<OrderLine[]>(url, JSON.stringify(searchData), this.httpService.getHttpOptions());
  }


  createZeroValuePurchaseOrder(jobId: number, costCentreId: number, vendorId: number, description: string,
    varianceCodeId: number, varianceReason: string, amount: number, constructionStageId: number,
    sendEmail: boolean = false, emailAddress: string = '', ccToSelf: boolean = false): Observable<PurchaseOrder> {
    let url = this.globalService.getApiUrl() + '/job/' + jobId
      + '/purchase-orders/generate-zero-po?vendorId=' + vendorId + '&costCentreId=' + costCentreId;

    if (constructionStageId) {
      url += '&constructionStageId=' + constructionStageId;
    }
    if (sendEmail) {
      url += '&sendEmail=' + sendEmail;
    }
    if (emailAddress) {
      url += '&emailAddress=' + emailAddress;
    }
    if (ccToSelf) {
      url += '&ccToSelf=' + ccToSelf;
    }

    return this._http.post<PurchaseOrder>(url,
      JSON.stringify({ description: description, varianceCodeId: varianceCodeId, varianceReason: varianceReason, amount: amount }),
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          if (this.allPurchaseOrdersForCompany) {
            this.allPurchaseOrdersForCompany.push(res);
          }
        }),
        catchError(this.handleError));
  }



  getOrderPhaseDescription(orderLine: OrderLine) {
    if (orderLine.purchaseOrderId) {
      this.currentPurchaseOrder = this.purchaseOrdersForJob.find(i => i.id === orderLine.purchaseOrderId);
    } else {
      this.currentPurchaseOrder = null;
    }

    if (orderLine.phaseId) {
      this.currentPhase = this.maintenanceService.phases.find(i => i.id === orderLine.phaseId);
    } else {
      this.currentPhase = null;
    }

    if (!this.currentPurchaseOrder && !this.currentPhase) {
      return '-1000;Unordered';
    } else if (!this.currentPurchaseOrder && this.currentPhase) {
      return '-' + this.currentPhase.orderNo + ';Unordered: ' + this.currentPhase.description;
    } else {
      let conStage = '';
      if (this.currentPurchaseOrder.constructionStageId === ConstructionStageTypeEnum.Maintenance) {
        conStage = 'M';
      } else if (this.currentPurchaseOrder.constructionStageId === ConstructionStageTypeEnum.Warranty) {
        conStage = 'W';
      }

      if (!this.currentPhase) {
        return '0;Order: ' + this.currentPurchaseOrder.poNumber + conStage;
      } else {
        return '0;Order: ' + this.currentPurchaseOrder.poNumber + ' - ' + this.currentPhase.description + conStage;

      }
    }
  }

  getPurchaseOrderNumber(orderLine: OrderLine) {
    if (orderLine.purchaseOrderId) {
      return this.purchaseOrdersForJob.find(i => i.id === orderLine.purchaseOrderId)?.poNumber;
    } else {
      return null;
    }
  }

  calcCostCentreTotal(costCentre: PriceFileItem) {
    const orderLinesForCostCentre = this.orderLines?.filter(i => i.costCentreId === costCentre.id);
    costCentre.total = 0;
    costCentre.unorderedLineCount = 0;
    costCentre.orderedLineCount = 0;
    costCentre.orderSentLineCount = 0;

    orderLinesForCostCentre?.forEach(orderLineForCostCentre => {
      if (orderLineForCostCentre.purchaseOrderId) {
        // if ordered we just use the line totals in case we have deleted the price file item
        costCentre.total += (orderLineForCostCentre.lineTotal ? orderLineForCostCentre.lineTotal : 0);
      } else if (orderLineForCostCentre.lineTotal) {
        // let lineTotal = orderLineForCostCentre.supplierQuantity * orderLineForCostCentre.rate;

        // if (orderLineForCostCentre.priceFileCode) {
        //   const unitOfMeasure = this.maintenanceService.unitOfMeasures.find(i => i.description === orderLineForCostCentre.unitOfMeasure);
        //   if (unitOfMeasure && unitOfMeasure.costIsPer) {
        //     lineTotal /= unitOfMeasure.costIsPer;
        //   }
        // }
        costCentre.total += orderLineForCostCentre.lineTotal;
      }
      if (!orderLineForCostCentre.purchaseOrderId) {
        costCentre.unorderedLineCount++;
      } else {
        costCentre.orderedLineCount++;

        // is PO sent?
        const po = this.purchaseOrdersForJob.find(i => i.id === orderLineForCostCentre.purchaseOrderId);
        if (po?.printedDate) {
          costCentre.orderSentLineCount++;
        }
      }
    });
  }


  getSampleExcelUpload(loadTypeId: number): Observable<PDFReports> {
    const url = this.globalService.getApiUrl() + '/price-file-item-vendor-rates/load-sample?loadTypeId=' + loadTypeId;
    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  convertAndSave(file: any, jobNumber: string, poNumber: string, saveFile: boolean) {
    const binary_string = window.atob(file);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'application/pdf' });

    let fileName = poNumber === '' ? 'POs for Job ' + jobNumber : jobNumber + '.' + poNumber;

    if (!fileName.endsWith('.pdf')) {
      fileName = fileName + '.pdf';
    }
    if (saveFile) {
      saveAs(blob, fileName);
    } else {
      this.showBlob(blob, fileName, AttachmentTypeEnum.PDF);
    }
  }

  convertBlobAndOpen(blob: Blob, attachmentTypeId: number, fileName: string, allowReplace: boolean = false, invoiceId: number = null) {
    this.showBlob(this.globalService.getBlobFromBase64(blob, attachmentTypeId), fileName, attachmentTypeId, allowReplace, invoiceId);
  }

  showBlob(blob: Blob, fileName: string, attachmentTypeId: number, allowReplace: boolean = false, invoiceId: number = null) {
    const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
    modalRef1.componentInstance.blob = blob;
    modalRef1.componentInstance.fileName = fileName;
    modalRef1.componentInstance.attachmentTypeId = attachmentTypeId;
    modalRef1.componentInstance.allowReplace = allowReplace;
    modalRef1.componentInstance.invoiceId = invoiceId;

    modalRef1.result.then(() => {
    }, () => {
    });
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
