import { Division } from './../../dtos/division';
import { AccountingSystemService } from './../../services/felixApi/accounting-system.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, OnInit, ViewChild, OnDestroy, Input, OnChanges } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { DivisionService } from '../../services/felixApi/division.service';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { AccountingSystemProductService } from '../../dtos/accounting-system-product-service';
import { AccountingSystemTenant } from '../../dtos/accounting-system-tenant';
import { GlAccountType } from '../../dtos/gl-account-type';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-division-product',
  templateUrl: './division-product.component.html',
  styleUrls: ['./division-product.component.scss']
})
export class DivisionProductComponent implements OnInit, OnChanges, OnDestroy {
  @Input() divisionProductCounter: number;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = true;
  gridHeight: number;
  invoiceAdmin: boolean;
  productsLoaded: boolean;
  accountingSystemProducts: AccountingSystemProductService[];
  accountingSystemTenants: AccountingSystemTenant[];
  loadingProducts: boolean;
  glAccountTypes: GlAccountType[];
  divisionId: number;
  selectedDivisions: Division[];

  constructor(
    public divisionService: DivisionService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    public gridService: GridService,
    private authService: AuthService,
    private accountingSystemService: AccountingSystemService,
    private maintenanceService: MaintenanceService
  ) {
    this.reorder = this.reorder.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.gridHeight = window.innerHeight - 130;
    this.getCompanyConfigurations();
    this.connectToAccountingSystemTenants();
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.maintenanceService.getDivisionAccountsData().subscribe({
        next: () => {
          if (this.authService.isAdminOrSuper()
            || this.authService.areaPermissions.find(i => i.applicationArea === 'Invoices')?.permissionType === 'Admin') {
            this.invoiceAdmin = true;
          }

          this.glAccountTypes = this.maintenanceService.glAccountTypes;
          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  refresh() {
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.divisionService.getDivisionAccounts(false).subscribe({
              next: (res) => {
                const divisionAccounts = res.filter(i => i.divisionId === this.divisionId);
                return resolve(divisionAccounts);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      insert: async (values) => {
        values.divisionId = this.divisionId;
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.divisionService.addDivisionAccount(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.divisionService.updateDivisionAccount(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.divisionService.deleteDivisionAccount(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: err => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  reorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNumber;
    this.grid.instance.beginCustomLoading('Re-ordering');

    this.subscriptions.push(
      this.divisionService.moveDivision(e.itemData.id, newOrderIndex).subscribe({
        next: () => {
          this.grid.instance.endCustomLoading();
          e.component.refresh();
        }, error: () => {
          this.notiService.showInfo('Cannot drop here');
          this.grid.instance.endCustomLoading();
        }
      })
    );
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;

    if (this.productsLoaded) {
      toolbarItems.push({
        location: 'before',
        locateInMenu: 'auto',
        template: templateName
      });
    }

    // if (this.invoiceAdmin && !this.productsLoaded) {
    //   e.toolbarOptions.items.unshift({
    //     location: 'after',
    //     widget: 'dxButton',
    //     options: {
    //       width: 250,
    //       type: 'default',
    //       text: 'Load Accounting Entities',
    //       onClick: this.connectToAccountingSystemTenants.bind(this)
    //     }
    //   });
    // }
  }

  connectToAccountingSystemTenants() {
    // Get the Non Inventory Products from Accounting System
    this.loading = true;

    this.subscriptions.push(
      this.accountingSystemService.getAccountingSystemTenants()
        .subscribe({
          next: () => {
            this.accountingSystemTenants = this.accountingSystemService.accountingSystemTenantsList();
            this.productsLoaded = true;
            this.divisionId = null;
            setTimeout(() => {
              this.loading = false;
            }, 250); // wait so grid loading will work in case we have cached data
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  connectToAccountingSystemCategories(tenantId: string) {
    // Get the accounts from Accounting System to do the connection
    if (this.productsLoaded && tenantId && tenantId.trim() !== '') {
      this.loadingProducts = true;
      this.subscriptions.push(
        this.accountingSystemService.getAccountingSystemProductsAndServices(tenantId, "NonInventory")
          .subscribe({
            next: (accountingSystemProducts) => {
              this.accountingSystemProducts = accountingSystemProducts;
              this.loadingProducts = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingProducts = false;
            }
          })
      );
    }
  }

  onSelectionChanged(event) {
    this.accountingSystemProducts = [];
    this.divisionId = event.value;

    if (this.productsLoaded) {
      const division = this.divisionService.divisions.find(i => i.id === this.divisionId);
      if (division?.accountingSystemTenantId && division.accountingSystemTenantId.trim() !== '') {
        this.connectToAccountingSystemCategories(division.accountingSystemTenantId);
        this.setUpDataSource();
      } else {
        this.notiService.showInfo('Division not linked to an accounting company. See Divisions');
        this.setUpDataSource();
      }
    } else {
      this.notiService.showInfo('Please Load Accounting Connections');
    }
  }
}
