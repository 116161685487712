<div class="page">

  <dx-data-grid *ngIf="!loadingData && !loading" #invoiceGrid id="invoiceGrid" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    (onCellPrepared)="onCellPrepared($event)" (onEditingStart)='onEditingStart($event)'
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'toolbarTemplate2')"
    (onExporting)="gridService.onExporting($event, 'invoice-entry')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoice-entry" [savingTimeout]="500">
    </dxo-state-storing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="true" [useIcons]="true"
      [selectTextOnEditStart]="true" refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [setCellValue]="setVendorCellValue"
      [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="orderNumber" width="155" [setCellValue]="setOrderNumberCellValue"
      editCellTemplate="editOrderCellTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="invoiceNumber" width="120">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="invoiceDate" dataType="date" width="85" format="d/M/yy" alignment="center"
      [setCellValue]="setInvoiceDateValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" width="85" format="d/M/yy" alignment="center">
    </dxi-column>

    <dxi-column dataField="totalIncGST" dataType="number" width="106" [format]="{ type: 'fixedPoint', precision: 2 }"
      [setCellValue]="setTotalIncGSTCellValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="totalGST" dataType="number" width="90" [format]="{ type: 'fixedPoint', precision: 2 }"
      [setCellValue]="setTotalGSTCellValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="totalExGST" dataType="number" [visible]='false' width="110"
      [format]="{ type: 'fixedPoint', precision: 2 }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="jobNumber" caption="Job" dataType="string" [allowEditing]="false" width="100"
      [calculateCellValue]='calculateJobNumber' [visible]='false'>
    </dxi-column>

    <dxi-column dataField="jobId" caption="Job Address" dataType="number" [allowEditing]="false">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobAddressString" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreId" caption="Cost Centre" dataType="string" [allowEditing]="false" width="140">
      <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="siteManager" [caption]="calculateSiteManagerTitle()" dataType="string" [allowEditing]="false"
      width="140" [calculateCellValue]='calculateSiteManager'>
    </dxi-column>

    <dxi-column dataField="purchaseOrderId" dataType="number" width="120" [visible]="false">
    </dxi-column>

    <dxi-column dataField="remainingBudget" caption="Remaining Budget ExGST Will Be" dataType="number" width="110"
      [calculateCellValue]='calculateRemainingBudget' [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="description" caption="Comment">
    </dxi-column>

    <dxi-column dataField="hasAttachments" caption="" dataType="boolean" [width]="45" [allowFiltering]="false"
      [allowSorting]="false" [calculateCellValue]='calculateHasAttachments' [allowEditing]="false"
      cellTemplate="attachmentCellTemplate">
    </dxi-column>

    <dxi-column dataField="createUserId" caption="Entered by" dataType="number" width="120" [allowEditing]="false"
      [visible]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="createDate" caption="Entered" dataType="date" width="95" format="d/M/yy" alignment="center"
      [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified by" dataType="number" width="120" [allowEditing]="false"
      [visible]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="95" format="d/M/yy"
      alignment="center" [allowEditing]="false" [visible]="false">
    </dxi-column>


    <dxi-column type="buttons" [width]="180">
      <dxi-button icon="copy" [visible]="isDuplicateInvoice" [onClick]="showDuplicateInvoices"
        hint='Duplicate invoices for PO'>
      </dxi-button>
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
      <dxi-button icon="share" [onClick]="splitInvoice" hint='Split Invoice'></dxi-button>
      <dxi-button icon="docfile" [visible]="isAttachmentIconVisible" [onClick]="attachDoc" hint='Attach invoice'>
      </dxi-button>
      <dxi-button icon="download" [visible]="isAttachmentIconVisible" [onClick]="openPO" hint='Download PO'>
      </dxi-button>
      <dxi-button icon="unselectall" [onClick]="openHistory" hint='See related invoices'></dxi-button>
    </dxi-column>


    <!-- summary totals -->
    <dxo-summary>
      <dxi-total-item column="vendorId" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="totalExGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalIncGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>


    <!-- Templates -->
    <div *dxTemplate="let cellInfo of 'editOrderCellTemplate'">
      <dx-text-box id="orderEditCell" [(value)]="cellInfo.value" (onValueChanged)="setEditedValue($event, cellInfo)">
      </dx-text-box>
      <dx-button id="orderEditButton" icon="edit" (onClick)="orderLookup(cellInfo)">
      </dx-button>
      <js-order-lookup *ngIf="showOrderLookupPopup" [originalOrderId]="selectedOrderId" [rowData]="cellInfo.data"
        [orderLookupSwitch]="orderLookupSwitch" (purchaseOrderId)="getOrderFromLookup($event, cellInfo)">
      </js-order-lookup>
    </div>

    <div *dxTemplate="let cellInfo of 'attachmentCellTemplate'">
      <div *ngIf="cellInfo.value">
        <i class="material-icons green" (click)="downloadAttachment(cellInfo.value)"
          matTooltip='Download attachment'>attach_file</i>
      </div>
      <div *ngIf="!cellInfo.value" class="red" matTooltip='Attachment missing' (click)="attachDocClick(cellInfo)">?
      </div>
    </div>

    <!-- <div *dxTemplate="let data of 'editBlobCellTemplate'">
      <div id="fileuploader-container">
        <dx-file-uploader #fileUploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false"
          accept="*" [(value)]="files" uploadMode="useForm">
        </dx-file-uploader>
      </div>
    </div> -->

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-select-box id="selectBatch" [dataSource]="invoiceBatches" displayExpr="description" valueExpr="id"
          placeholder="Choose Batch" [width]="286" [(value)]='selectedInvoiceBatchId'
          (onSelectionChanged)='setUpDataSource(true)'>
        </dx-select-box>

        <dx-button id="manageBatchButton" text="Manage Batches" class="ms-1" type="default" stylingMode="outlined"
          (onClick)="manageBatches()">
        </dx-button>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <dx-button id="addButton" icon="add" type="outline" (onClick)="addInvoice()">
        </dx-button>
      </div>
    </div>
  </dx-data-grid>

  <div>* Remaining budget in <span class="orange">orange</span>
    indicates there are other possible orders for the same job and cost centre to split the invoice against.</div>
</div>

<!-- invoice batches popup -->
<dx-popup class="popup" [width]="450" [height]="450" [showTitle]="true" title="Batch Maintenance" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="invoiceBatchesPopupVisible" [shading]="true">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid [dataSource]="invoiceBatchDataSource" keyExpr="id" [height]="350" [showBorders]="true"
      [rowAlternationEnabled]="false" [wordWrapEnabled]="true" [allowColumnResizing]="false">

      <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="full">
      </dxo-editing>

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>

      <dxi-column dataField="description" caption="Batch Name" dataType="string"> </dxi-column>
      <dxi-column dataField="isDefaultForScans" caption="Default for Invoice Scans" dataType="boolean" [width]="110">
      </dxi-column>
    </dx-data-grid>
  </div>
</dx-popup>

<!-- Add attachment -->
<dx-popup class="popup" [width]="400" [height]="attachmentsPopupHeight" [showTitle]="true" title="Attachments"
  [shading]="false" [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="attachmentsPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="attachmentExists">
      <dx-button id="delete-attachment-button" text="Delete Current Attachment" type="danger"
        (onClick)="deleteAttachment()">
      </dx-button>
    </div>
    <div *ngIf="!attachmentExists">
      <div id="fileuploader-container">
        <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
          [(value)]="files" uploadMode="useForm">
        </dx-file-uploader>
      </div>

      <dx-button id="load-button" text="Load" type="success" (onClick)="uploadClick(false)">
      </dx-button>
    </div>
  </div>
</dx-popup>

<js-order-lookup *ngIf="showOrderLookupPopupSplits" [originalOrderId]="selectedOrderId" [rowData]="invoiceData"
  [fromOnHold]="true" [orderLookupSwitch]="orderLookupSwitch" (splitPurchaseOrders)="splitOrdersFromLookup($event)">
</js-order-lookup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loadingFile || loadingData" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>


<dx-popup class="popup" [width]="400" [height]="280" [showTitle]="true" title="Scan File" [shading]="false"
  [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="scanDropVisible">
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="!fileCount">
      <div id="fileuploader-container">
        <dx-file-uploader selectButtonText="Select file(s)" labelText="or drop file(s) here" [multiple]="true"
          accept="*" [(value)]="files" uploadMode="useForm">
        </dx-file-uploader>
      </div>

      <dx-button *ngIf="files.length" id="button" text="Load" type="success" (onClick)="uploadForScan()">
      </dx-button>
    </div>

    <div *ngIf="fileCount">PDFs remaining to upload: {{fileCount}}</div>
  </div>
</dx-popup>


<!-- process -->
<dx-popup class="popup" [width]="130" [height]="130" [showTitle]="true" [shading]="false" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="processPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button text="Process" type="success" (onClick)="processInvoicesGo()">
    </dx-button>
  </div>
</dx-popup>
