import { GridService } from './../../services/grid.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { UtilsService } from './../../services/utils.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Vendor } from '../../dtos/vendor';
import { PoService } from '../../services/felixApi/po.service';
import { Phase } from '../../dtos/phase';
import CustomStore from 'devextreme/data/custom_store';
import { JobService } from '../../services/felixApi/job.service';
import { GlobalService } from '../../services/global.service';
import { PurchaseOrder, SplitOrder } from '../../dtos/purchase-order';
import { PriceFileItem } from '../../dtos/price-file-item';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateZeroPoComponent } from '../../orders/create-zero-po/create-zero-po.component';

@Component({
  selector: 'js-order-lookup',
  templateUrl: './order-lookup.component.html',
  styleUrls: ['./order-lookup.component.scss']
})
export class OrderLookupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() originalOrderId: number;
  @Input() orderLookupSwitch: boolean;
  @Input() vendorId: number;
  @Input() fromAdd: boolean;
  @Input() rowData: object;
  @Input() fromOnHold: boolean;

  @Output() purchaseOrderId: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() splitPurchaseOrders: EventEmitter<SplitOrder[]> =
    new EventEmitter<SplitOrder[]>();

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  vendors: Vendor[];
  phases: Phase[] = [{ id: null, orderNo: 0, phaseCode: 'Default', description: 'Default' }];
  purchaseOrderData: CustomStore;
  jobs: any;
  selectedOrders: PurchaseOrder[];
  costCentres: PriceFileItem[];
  selectedOrderId: number;
  popupVisible = true;
  selectionMode = 'single';
  loading = true;
  purchaseOrders: PurchaseOrder[];
  selectedSplitOrder: SplitOrder;
  selectedSplitOrders: SplitOrder[];
  gridHeight: number;
  gridWidth: number;
  title = 'Order lookup';
  savingData: boolean;
  amountFieldOptions: object;
  totalExGST: any;
  totalIncGST: any;
  vendor: Vendor;

  constructor(
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    private estimatingService: EstimatingService,
    private jobService: JobService,
    private notiService: NotificationService,
    private poService: PoService,
    private invoiceService: InvoiceService,
    private utilService: UtilsService,
    public gridService: GridService,
    private modalService: NgbModal) {
    this.calculateJobNumber = this.calculateJobNumber.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.calculateCostCentreDesc = this.calculateCostCentreDesc.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.openPO = this.openPO.bind(this);
    this.setExInvoiceAmount = this.setExInvoiceAmount.bind(this);
    this.setIncInvoiceAmount = this.setIncInvoiceAmount.bind(this);
    this.calculateCustomSummary = this.calculateCustomSummary.bind(this);

    this.amountFieldOptions = {
      step: 0
    };
  }

  ngOnInit() {
    this.jobs = this.jobService.jobs;
    this.costCentres = this.estimatingService.costCentres;
    this.selectedOrderId = this.originalOrderId;

    this.gridHeight = window.innerHeight > 890 ? 870 : window.innerHeight - 20;
    this.gridWidth = window.innerWidth > 1600 ? 1550 : window.innerWidth - 50;
    this.setupDataSource(true);
  }

  ngOnChanges(): void {
    this.selectedOrderId = this.originalOrderId;
    if (this.rowData) {
      this.vendor = this.maintenanceService.allVendors.find(i => i.id === this.rowData['vendorId']);

      this.title = 'Order lookup - Vendor: ' + this.vendor?.vendorName;
      this.title += '- Invoice#: ' + this.rowData['invoiceNumber'];
      this.title += '- $' + this.rowData['totalExGST'] + ' ex. GST';
      this.title += '- $' + this.rowData['totalIncGST'] + ' inc. GST';

      this.totalExGST = this.rowData['totalExGST'];
      this.totalIncGST = this.rowData['totalIncGST'];
    }

    this.selectedSplitOrders = [];
    this.selectionMode = this.fromOnHold ? 'multiple' : 'single';

    this.popupVisible = true;

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 100); // wait - so we can clear the grid of previous data for splits

    this.setupDataSource(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.popupVisible = false;
    this.purchaseOrderId.emit();
  }

  select() {
    this.popupVisible = false;
    this.purchaseOrderId.emit(this.selectedOrderId);
  }

  setupDataSource(useCache: boolean) {
    this.purchaseOrderData = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.getOrdersDataForInvoices(useCache).subscribe({
              next: (res) => {
                this.purchaseOrders = res;
                this.vendors = this.maintenanceService.allVendors;
                this.phases = this.phases.concat(this.maintenanceService.phases);

                if (this.selectedOrderId) {
                  const currentPO = this.purchaseOrders?.find(i => i.id === this.selectedOrderId);
                  this.jobService.currentJob = this.jobs?.find(i => i.id === currentPO?.jobId);
                }
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  calculateJobNumber(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobNumber;
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data.costCentreId)?.orderNumber;
  }

  calculateCostCentreDesc(data) {
    return this.costCentres.find(i => i.id === data.costCentreId)?.description;
  }

  openPO(e) {
    if (e.row.data.jobId && e.row.data.id) {
      const jobNumber = this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;
      const dataRecord = {
        purchaseOrderIds: [e.row.data.id],
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        printPrices: true
      };

      this.notiService.showInfo('downloading order');

      this.subscriptions.push(
        this.poService.sendPurchaseOrders(e.row.data.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.poService.convertAndSave(orderResponse.pdfOrders, jobNumber, e.row.data.poNumber, false);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  calculateRemainingBudget(data) {
    return data.orderTotal - data.approvedInvoicesTotal;
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    if (this.fromAdd) {
      toolbarItems.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            text: this.selectionMode === 'single' ? 'Split Invoice' : 'Single Invoice',
            onClick: this.splitInvoice.bind(this)
          }
        });
    }
  }

  splitInvoice() {
    if (this.selectionMode === 'single') {
      this.selectionMode = 'multiple';
      this.selectedOrderId = null;
    } else {
      this.selectionMode = 'single';
      this.selectedOrderId = this.originalOrderId;
    }

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 100); // wait
  }

  onSaving(e) {
    e.cancel = true;
    this.selectedSplitOrders = [];

    e.changes.forEach(element => {
      if (element.data.invoiceAmount) {
        const order = this.purchaseOrders.find(i => i.id === element.key);

        if (order) {
          this.selectedSplitOrder = {
            id: element.key,
            invoiceAmount: element.data.invoiceAmount
          };
          this.selectedSplitOrders.push(this.selectedSplitOrder);
        }
      }
    });

    if (this.fromOnHold) {
      // we try to save here in case of error
      // run split
      this.savingData = true;
      this.subscriptions.push(
        this.invoiceService.splitExistingInvoice(this.rowData['id'], this.selectedSplitOrders)
          .subscribe({
            next: () => {
              this.savingData = false;
              this.dataGrid.instance.cancelEditData();
              this.splitPurchaseOrders.emit(this.selectedSplitOrders);
            }, error: (err) => {
              this.notiService.notify(err);
              this.savingData = false;
            }
          })
      );
    } else {
      this.dataGrid.instance.cancelEditData();
      this.splitPurchaseOrders.emit(this.selectedSplitOrders);
    }
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  setExInvoiceAmount(rowData, value) {
    const valueString = this.utilService.calcQtyFromString(this.utilService.sanitizeQty(value));
    if (!isNaN(+valueString)) {
      if (rowData.invoiceAmountEx !== +valueString) {
        rowData.invoiceAmountEx = +valueString;

        if (this.vendor?.isGstFree) {
          rowData.invoiceAmount = +valueString;
        } else {
          const getAmount = Math.round((+valueString / this.invoiceService.globalGSTRate) * 100) / 100;
          rowData.invoiceAmount = +valueString + getAmount;
        }
      }
    }
  }

  setIncInvoiceAmount(rowData, value) {
    const valueString = this.utilService.calcQtyFromString(this.utilService.sanitizeQty(value));
    if (!isNaN(+valueString)) {
      if (rowData.invoiceAmount !== +valueString) {
        rowData.invoiceAmount = +valueString;

        if (this.vendor?.isGstFree) {
          rowData.invoiceAmountEx = +valueString;
        } else {
          const getAmount = Math.round((+valueString / (1 + this.invoiceService.globalGSTRate)) * 100) / 100;
          rowData.invoiceAmountEx = Math.round((+valueString - getAmount) * 100) / 100;
        }
      }
    }
  }

  zeroOrderPopup() {
    this.popupVisible = false;
    this.jobService.setCurrentJob('');
    const modalRef = this.modalService.open(CreateZeroPoComponent, { windowClass: 'modal-edit2' });

    modalRef.result.then((purchaseOrderId) => {
      if (purchaseOrderId) {
        this.selectedOrderId = purchaseOrderId;
        this.select();
      } else {
        this.popupVisible = true;
      }
    }, () => {
      this.popupVisible = true;
    });
  }

  calculateCustomSummary(options) {
    if (options.name === 'totalExRemaining') {
      if (options.summaryProcess === 'start') {
        options.totalValue = this.totalExGST;
      }
      if (options.summaryProcess === 'calculate') {
        options.totalValue = options.totalValue - options.value;
      }
    }

    if (options.name === 'totalIncRemaining') {
      if (options.summaryProcess === 'start') {
        options.totalValue = this.totalIncGST;
      }
      if (options.summaryProcess === 'calculate') {
        options.totalValue = options.totalValue - options.value;
      }
    }
  }
}
