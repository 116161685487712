<div class="modal-header">
  <h3 class="modal-title">ALL Jobs: Add an item to a purchase order</h3>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <b>Updates active jobs and where order does not have accrual stopped.</b>
  <br>
  <br>

  <div class="dx-field">
    <div class="dx-field-label">Vendor:</div>
    <div class="dx-field-value">
      <dx-select-box [(ngModel)]="vendorId" [dataSource]="vendors" valueExpr="id" displayExpr="vendorName"
        [showClearButton]="true" [searchEnabled]="true">
      </dx-select-box>
    </div>
  </div>

  <js-extra-variance-select [varianceCodeId]=varianceCodeId [varianceReason]="varianceReason"
    (varianceCodeIdSelected)="varianceCodeId = $event" (varianceReasonSelected)="varianceReason = $event">
  </js-extra-variance-select>

  <div class="dx-field">
    <div class="dx-field-label">Line Description:</div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="orderLineDescription"> </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Use percentage increase?</div>
    <div class="dx-field-value inlineFlex">
      <dx-check-box [(value)]="usePercentIncrease">
      </dx-check-box>
    </div>
  </div>

  <div *ngIf="usePercentIncrease" class="dx-field">
    <div class="dx-field-label">% increase to PO:</div>
    <div class="dx-field-value">
      <dx-number-box [(value)]="percentageIncrease" [width]="100"> </dx-number-box>
    </div>
  </div>

  <div *ngIf="!usePercentIncrease" class="dx-field">
    <div class="dx-field-label">Line value:</div>
    <div class="dx-field-value">
      <dx-number-box [(value)]="amountIncrease" [width]="100"> </dx-number-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Update orders with invoices</div>
    <div class="dx-field-value inlineFlex">
      <dx-check-box [(value)]="updateOrdersWithInvoices" (onValueChanged)="onRevalueOrdersWithInvoicesChanged()">
      </dx-check-box>
    </div>
  </div>

  <br>

  <dx-data-grid [dataSource]="purchaseOrders" keyExpr="id" [(selectedRowKeys)]="selectedRows" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [allowColumnReordering]="false"
    [allowColumnResizing]="false" [columnAutoWidth]="false" [height]="gridHeight"
    (onToolbarPreparing)="onToolbarPreparing($event)" (onExporting)="gridService.onExporting($event, 'add-item')">

    <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple"></dxo-selection>
    <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-export [enabled]="true"></dxo-export>

    <dxi-column dataField="jobId" caption="Job" dataType="number" [width]="120" sortOrder="asc">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="siteAddress" dataType="string" [calculateCellValue]='calculateSiteAddress'>
    </dxi-column>

    <dxi-column dataField="poNumber" caption="PO Number" sortOrder="asc" [width]="120"> </dxi-column>

    <dxi-column dataField="orderTotal" dataType="number" [width]="90" format="#,##0"> </dxi-column>

    <dxi-column dataField="currentActivityDesc" caption="Activity" [calculateCellValue]='calculateActivityDesc'
      [width]="180">
    </dxi-column>

    <dxi-column dataField="siteManager" [caption]="calculateSiteManagerTitle()" dataType="string" width="180"
      [calculateCellValue]='calculateSiteManager'>
    </dxi-column>

  </dx-data-grid>

  <div *ngIf="isUpdating">Purchase orders to update: {{purchaseOrderCount}}</div>
  <div *ngIf="isUpdating">Please wait...</div>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="isUpdating" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
