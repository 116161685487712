<div class="page">

  <dx-data-grid *ngIf="!loading" #depositGrid id="depositGrid" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)" (onExporting)="gridService.onExporting($event, 'income-invoices')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="all-income-invoices" [savingTimeout]="500">
    </dxo-state-storing>

    <dxo-editing mode="row" [allowUpdating]="isAdmin" [allowAdding]="false" [allowDeleting]="isAdmin" [useIcons]="true"
      refreshMode="repaint">
    </dxo-editing>

    <dxi-column dataField="jobId" caption="Job" dataType="number" width="110" [allowEditing]="false">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="clientName" caption="Client" dataType="string" [calculateCellValue]='calculateClientName'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="siteAddress" dataType="string" [calculateCellValue]='calculateSiteAddress' [visible]="false"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="claimNumber" caption="VO#" dataType="number" width="100" alignment="center"
      [calculateCellValue]='calculateClaimNumber' [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="claimDescription" caption="Description" dataType="string"
      [calculateCellValue]='calculateDescription' [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="claimStage" dataType="string" [calculateCellValue]='calculateClaimStage'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="invoiceDate" caption="Date" dataType="date" width="130" alignment="center">
    </dxi-column>

    <dxi-column dataField="totalExGST" dataType="number" width="130" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="true">
    </dxi-column>

    <dxi-column dataField="totalGST" dataType="number" width="130" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="true">
    </dxi-column>

    <dxi-column dataField="totalIncGST" dataType="number" width="130" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="true">
    </dxi-column>

    <dxi-column dataField="totalPaid" dataType="number" width="130" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="true">
    </dxi-column>

    <dxi-column dataField="fullyPaidOnDate" caption="Last Paid" dataType="date" width="130" [allowEditing]="true"
      alignment="center">
    </dxi-column>

    <dxi-column dataField="totalRemaining" dataType="number" width="130" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="false" [calculateCellValue]="calculateRemaining">
    </dxi-column>

    <dxi-column dataField="createDate" caption="Date Created" dataType="date" width="130" alignment="center"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="postingDate" caption="Posting Date" dataType="date" width="90" format="d/M/yy" alignment="center"
      [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="siteStartDate" dataType="date" width="90" format="d/M/yy" alignment="center"
      [allowEditing]="false" [visible]="false" [calculateCellValue]="calcSiteStartDate">
    </dxi-column>

    <dxi-column type="buttons">
      <dxi-button *ngIf="isAdmin" name="edit"> </dxi-button>
      <dxi-button *ngIf="isAdmin" name="delete" [onClick]="deleteRecord"> </dxi-button>
      <dxi-button template="paidCommand">
        <div class="paidButtonClass" *dxTemplate="let data of 'paidCommand'">
          <i *ngIf="data.data.fullyPaidOnDate" class="material-icons green" matTooltip="Paid">check</i>
          <i *ngIf="!data.data.fullyPaidOnDate" class="material-icons" (click)="checkIfPaid(data)"
            matTooltip="Check if paid">question_mark</i>
        </div>
      </dxi-button>
    </dxi-column>

    <!-- summary totals -->
    <dxo-summary>
      <dxi-total-item column="jobId" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="totalExGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalIncGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalPaid" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalRemaining" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

  </dx-data-grid>

</div>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>


<!-- delete popup -->
<dx-popup class="popup" [width]="400" [height]="235" [showTitle]="true" [title]="claimDesc" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="deletePopupVisible" [shading]="true">
  <div *dxTemplate="let data of 'content'">
    <div>Delete this record?</div>
    <div>Beware that deleting will NOT delete the external accounting system record which must be deleted manually!
    </div>
    <br>
    <dx-button id="load-button" text="Delete" type="danger" (onClick)="deleteClicked()">
    </dx-button>
  </div>
</dx-popup>
