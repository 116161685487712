import { GridService } from './../../services/grid.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { InvoiceLog } from './../../dtos/invoice-log';
import { NotificationService } from './../../services/notification.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Vendor } from '../../dtos/vendor';
import { UserService } from '../../services/felixApi/user.service';
import { User } from '../../dtos/user';

@Component({
  selector: 'js-invoice-logs',
  templateUrl: './invoice-logs.component.html',
  styleUrls: ['./invoice-logs.component.scss']
})
export class InvoiceLogsComponent implements OnInit, OnDestroy {
  @Input() invoiceId: number;

  subscriptions: Subscription[] = [];
  loading = true;
  vendors: Vendor[];
  invoiceLogs: InvoiceLog[];
  popupHeight: number;
  users: User[];

  constructor(
    private activeModal: NgbActiveModal,
    private maintenanceService: MaintenanceService,
    private invoiceService: InvoiceService,
    private notiService: NotificationService,
    public gridService: GridService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.popupHeight = window.innerHeight < 700 ? window.innerHeight - 100 : 600;
    this.vendors = this.maintenanceService.allVendors;
    this.users = this.userService.users;
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData() {
    this.subscriptions.push(
      this.invoiceService.getInvoiceLogs(this.invoiceId)
        .subscribe({
          next: (invoiceLogs) => {
            this.invoiceLogs = invoiceLogs;
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  close() {
    this.activeModal.dismiss();
  }
}
