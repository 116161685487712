<div class="page">
  <dx-data-grid *ngIf="!loadingData" #backChargesGrid id="backChargesGrid" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    [focusedRowEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'back-charges')" (onInitNewRow)="onInitNewRow($event)"
    columnResizingMode="widget" (onEditingStart)="onEditingStart($event)">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true" [search]="{enabled: true}"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="back-charges" [savingTimeout]="500">
    </dxo-state-storing>

    <dxo-editing mode="popup" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="isAdmin" [useIcons]="true"
      refreshMode="reshape">
      <dxo-popup title="" [showTitle]="false" [width]="editPopupWidth" [height]="editPopupHeight" [fullScreen]="false">
        <dxi-toolbar-item toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Save & Send Notice', onClick: sendNotice }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Generate PO', onClick: generatePO }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Generate Credit', onClick: generateCredit }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type: 'default', text: 'Save', onClick: saveClickHandler }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type:'danger', text: 'Cancel', onClick: cancelClickHandler }">
        </dxi-toolbar-item>
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="jobId" [colSpan]="1"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="siteAddress" [colSpan]="4"></dxi-item>
          <dxi-item dataField="vendorToBackChargeId" [colSpan]="4"></dxi-item>
          <dxi-item dataField="emailAddress" [colSpan]="4"></dxi-item>
          <dxi-item dataField="reason" editorType="dxTextArea" [colSpan]="4" [editorOptions]="{ height: 100 }">
          </dxi-item>
          <dxi-item dataField="amount" [colSpan]="2"></dxi-item>
          <dxi-item dataField="vendorAgreementTypeId" [colSpan]="2"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="sentDate" [colSpan]="1"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="creditNoteId" [colSpan]="1"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="vendorToRectifyId" [colSpan]="2"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="purchaseOrderId" [colSpan]="1"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="comment" editorType="dxTextArea" [colSpan]="4" [editorOptions]="{ height: 100 }">
          </dxi-item>
          <dxi-item dataField="backChargeStatusId" [colSpan]="2"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="3" [colSpan]="2">
          <dxi-item dataField="createUserId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="createDate" [colSpan]="1"></dxi-item>
          <dxi-item dataField="modifiedUserId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="modifiedDate" [colSpan]="1"></dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxi-column dataField="number" dataType="string" width="90" [calculateCellValue]='calculateBackChargeNumber'
      [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="jobId" dataType="number" caption="Job" [width]="100" editCellTemplate="jobDropDown"
      [setCellValue]="setJobIdCellValue" [calculateSortValue]="calculateJobSortValue" [allowEditing]="canEdit">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="siteAddress" dataType="string" [calculateCellValue]='calculateSiteAddress'
      [allowEditing]="false" [width]="240">
    </dxi-column>

    <dxi-column dataField="reason" dataType="string" minWidth="50" cellTemplate="commentCellTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="vendorToBackChargeId" caption="Vendor to Back-Charge" dataType="number"
      [calculateSortValue]="calculateVendorSortValue" [width]="200" [setCellValue]="setVendorToBackCharge">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="amount" caption="Amount Ex-GST" dataType="number" [width]="90" format="#,##0.00"
      [editorOptions]="{ min: 0, step: 1 }">
    </dxi-column>

    <dxi-column dataField="vendorAgreementTypeId" caption="Vendor Agreement" [width]="110">
      <dxo-lookup [dataSource]="vendorAgreementTypes" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="creditNoteNumber" caption="Credit Note" dataType="string" [width]="110">
    </dxi-column>

    <dxi-column dataField="creditNoteId" caption="Credit Note" dataType="string" [width]="110" [visible]="false"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="creditNotesForJobAndVendor" displayExpr="invoiceNumber" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="vendorToRectifyId" caption="Vendor to Rectify" dataType="number"
      [calculateSortValue]="calculateVendorSortValue" [width]="200">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="poNumber" caption="PO Number" dataType="string" [width]="110" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="purchaseOrderId" caption="Purchase Order" dataType="number" [width]="110" [visible]="false"
      editCellTemplate="poEditCellTemplate" [setCellValue]="setPurchaseOrderIdCellValue">
      <dxo-lookup [dataSource]="purchaseOrders" displayExpr="poNumber" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="comment" dataType="string" [width]="200" cellTemplate="commentCellTemplate"> </dxi-column>

    <dxi-column dataField="emailAddress" dataType="string" [width]="100" [visible]="false"> </dxi-column>

    <dxi-column dataField="backChargeStatusId" caption="Status" dataType="number" [width]="100">
      <dxo-lookup [dataSource]="backChargeStatuses" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="sentDate" caption="Date Sent" dataType="date" width="95" format="d/M/yy" alignment="center"
      [visible]="false" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="createUserId" caption="Entered by" dataType="number" [width]="120" [allowEditing]="false"
      [visible]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="createDate" caption="Entered" dataType="date" width="95" format="d/M/yy" alignment="center"
      [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified by" dataType="number" [width]="120" [allowEditing]="false"
      [visible]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="95" format="d/M/yy"
      alignment="center" [allowEditing]="false" [visible]="false">
    </dxi-column>


    <dxi-column dataField="sendNoticeToVendor" dataType="boolean" [width]="100" [visible]="false"> </dxi-column>
    <dxi-column dataField="ccToSelf" dataType="boolean" [width]="100" [visible]="false"> </dxi-column>


    <!-- Templates -->

    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div id="comment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'jobDropDown'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="jobs" [(value)]="cellInfo.value"
        displayExpr="jobNumber" valueExpr="id" contentTemplate="contentTemplate">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="jobs" keyExpr="id" [remoteOperations]="false" height="400px" [showBorders]="true"
            [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [wordWrapEnabled]="true"
            [autoNavigateToFocusedRow]="true" [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            (onSelectionChanged)="onJobSelectionChanged(cellInfo, e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>

            <dxi-column dataField="jobNumber" [width]="110"></dxi-column>
            <dxi-column dataField="jobAddressString" caption="Site Address"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let cellInfo of 'poEditCellTemplate'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="purchaseOrders" [(value)]="cellInfo.value"
        displayExpr="poNumber" valueExpr="id" contentTemplate="contentTemplate" [showClearButton]="true"
        (onValueChanged)="onPODropDownChanged(cellInfo, $event)">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="purchaseOrders" keyExpr="id" [remoteOperations]="false" height="99%"
            [showBorders]="true" [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [wordWrapEnabled]="true"
            [autoNavigateToFocusedRow]="true" [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            (onSelectionChanged)="onPOSelectionChanged(cellInfo, e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>

            <dxi-column dataField="poNumber" caption="PO#" [width]="90" sortOrder="asc"></dxi-column>

            <dxi-column dataField="costCentreId" dataType="number" caption="Cost Code"
              [calculateSortValue]="calculateCostCentreSortValue">
              <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id">
              </dxo-lookup>
            </dxi-column>

            <dxi-column dataField="vendorId" dataType="number" caption="Vendor"
              [calculateSortValue]="calculateVendorSortValue">
              <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
              </dxo-lookup>
            </dxi-column>

            <dxi-column dataField="orderTotal" dataType="number" [width]="90" format="#,##0"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>


    <dxi-column type="buttons" [fixed]="true">
      <dxi-button name="edit"></dxi-button>
      <dxi-button *ngIf="isAdmin" name="delete"></dxi-button>
    </dxi-column>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loadingData || loading" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>

<!-- save and send -->
<dx-popup class="popup" [width]="250" [height]="170" title="Send" [showTitle]="true" [shading]="false"
  [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="saveAndSendPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-check-box [(value)]="ccToSelf" text="CC the email to yourself"></dx-check-box>
    <br>
    <br>
    <dx-button text="Save & Send" type="default" (onClick)="saveAndSend()"> </dx-button>
  </div>
</dx-popup>

<js-manual-order *ngIf="showGeneratePOModal" [vendor]="vendorToRectify" [reason]="reason" [forBackCharge]="true"
  [jobId]="jobId" [forceChange]="forceChange" (purchaseOrderId)="setPurchaseOrderId($event)">
</js-manual-order>

<!-- create credit -->
<dx-popup class="popup" [width]="creditFormWidth" [height]="creditFormHeight" title="Create Credit Note"
  [showTitle]="true" [shading]="false" [dragEnabled]="true" [hideOnOutsideClick]="true"
  [(visible)]="showCreditNoteForm">
  <div *dxTemplate="let data of 'content'">
    <form (submit)="generateCreditGo()">
      <dx-form *ngIf="!loading" id="form" [(formData)]="backChargeForm" [readOnly]="false" [showColonAfterLabel]="true"
        labelLocation="left" width="100%" [showValidationSummary]="false" validationGroup="customerData" [colCount]="2">

        <dxi-item dataField="invoicedVendor" caption="Vendor" [colSpan]="2"
          [editorOptions]="{ disabled: true }"></dxi-item>

        <dxi-item dataField="jobNumber" [colSpan]="2" [editorOptions]="{ disabled: true }"></dxi-item>

        <dxi-item dataField="jobAddressString" [colSpan]="2" [editorOptions]="{ disabled: true }"></dxi-item>

        <dxi-item dataField="invoiceNumber" caption="Credit Number" [colSpan]="2" editorType="dxTextBox">
          <dxo-label location="left" text="Credit Number"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="invoiceDate" [colSpan]="1" editorType="dxDateBox" [editorOptions]="invoiceDateOptions">
        </dxi-item>

        <dxi-item dataField="totalExGST" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="invoiceTotalExOptions">
        </dxi-item>

        <dxi-item dataField="sendToEmail" [colSpan]="2">
          <dxo-label location="left" text="Email"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="emailSubject" [colSpan]="2">
          <dxo-label location="left" text="Subject"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="ccToSelf" [colSpan]="2">
          <dxo-label location="left" text="CC to yourself"></dxo-label>
        </dxi-item>

        <dxi-item dataField="comment" editorType="dxTextArea" [colSpan]="2" [editorOptions]="{ height: 90 }">
          <dxo-label location="left" text="Reason"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item itemType="button" [colSpan]="2" horizontalAlignment="right" [buttonOptions]="updateButtonOptions">
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>
