import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-variance-reason',
  templateUrl: './variance-reason.component.html',
  styleUrls: ['./variance-reason.component.scss']
})
export class VarianceReasonComponent implements OnInit, OnDestroy {

  dataSource: CustomStore;
  subscriptions: Subscription[] = [];

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService
  ) { }

  ngOnInit(): void {
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.getExtraReasons(false, true).subscribe(res => {
              return resolve(res);
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      },
      insert: async (values) => {
        if (values.isActive === undefined || values.isActive === null) {
          values.isActive = true;
        }
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.addExtraReason(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.updateExtraReason(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.deleteExtraReason(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }
}
