<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-popup [visible]="showAccountMappingPopup" [title]="'Account Mapping'" [width]="800" [height]="600"
    [showCloseButton]="false">
    <dx-data-grid #accountMappingGrid id="accountMappingGrid" [dataSource]="accountMappingData" [showBorders]="true" 
      [rowAlternationEnabled]="true" [height]="470" (onSaved)="saveAccountMapping()" (onExporting)="gridService.onExporting($event, 'accountmapping')">
      <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-editing mode="batch" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"
        refreshMode="repaint"></dxo-editing>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>

      <dxi-column dataField="accountId" caption="Account" [allowEditing]="false">
        <dxo-lookup [dataSource]="accounts" valueExpr="accountID" displayExpr="name"></dxo-lookup>
      </dxi-column>
      <dxi-column dataField="costCentreId" caption="Cost Centre" [allowEditing]="true" [editorOptions]="{ showClearButton: true }">
        <dxo-lookup [dataSource]="costCentres" valueExpr="id" displayExpr="description"></dxo-lookup>
      </dxi-column>
    </dx-data-grid>

    <div class="popup-footer">
      <!-- <dx-button text="Save" (onClick)="saveAccountMapping()" class="popup-footer-save"></dx-button> -->
      <dx-button text="Cancel" (onClick)="cancelAccountMapping()"></dx-button>
    </div>
  </dx-popup>

  <dx-data-grid #purchaseOrdersGrid *ngIf="!loading" id="purchaseOrdersGrid" class="m-auto" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')" (onEditorPreparing)="onEditorPreparing($event)" 
    (onSelectionChanged)="onSelectionChanged($event)" (onExporting)="gridService.onExporting($event, 'purchaseordersimport')">

    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-editing mode="batch" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"
      refreshMode="repaint"></dxo-editing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-selection selectAllMode='allPages' showCheckBoxesMode='always' mode="multiple"></dxo-selection>

    <dxi-column dataField="orderID" width="100" [allowEditing]="false" [visible]="false"></dxi-column>

    <dxi-column dataField="orderNumber" width="120" [allowEditing]="false" sortOrder="asc"></dxi-column>

    <dxi-column dataField="date" caption="Order Date" dataType="date" width="80" format="d/M/yy" alignment="center"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="supplierInvoiceNumber" width="100" [allowEditing]="false" [visible]="false"></dxi-column>

    <dxi-column dataField="supplierName" caption="Vendor" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="shipToAddress" [allowEditing]="false" [visible]="false"></dxi-column>

    <dxi-column dataField="isTaxInclusive" width="80" [allowEditing]="false"></dxi-column>

    <dxi-column *ngIf="accountingSystemType === 3" caption="Job Number" dataField="jobNumber" width="100"
      [allowEditing]="false" [visible]="false"></dxi-column>

    <dxi-column *ngIf="accountingSystemType === 3" caption="Job Name" dataField="jobName" [allowEditing]="false"
      [visible]="false"></dxi-column>

    <dxi-column dataField="subtotal" width="100" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="totalTax" width="100" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="comment" [allowEditing]="false" [visible]="false"></dxi-column>

    <dxi-column dataField="status" caption="Order Status" width="100" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="lineCount" caption="Order Line Count" width="100" [allowEditing]="false"
      [visible]="false"></dxi-column>

    <dxi-column dataField="lineDetails" caption="Order Line Details" width="100" [allowEditing]="false"
      [visible]="false"></dxi-column>

    <dxi-column dataField="invoiceID" width="100" [allowEditing]="false" [visible]="false"></dxi-column>

    <dxi-column dataField="invoiceNumber" width="100" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="invoiceSupplierName" caption="Invoice Vendor" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="invoiceDate" width="80" dataType="date" format="d/M/yy" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="invoiceIsTaxInclusive" width="80" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="invoiceTotal" width="100" [allowEditing]="false"></dxi-column>
    
    <dxi-column dataField="invoiceTax" width="100" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="invoiceStatus" width="100" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="paidDate" width="80" dataType="date" format="d/M/yy" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="invoiceLineCount" width="100" [allowEditing]="false" [visible]="false"></dxi-column>

    <dxi-column dataField="invoiceLineDetails" width="100" [allowEditing]="false" [visible]="false"></dxi-column>

    <dxi-column dataField="accountID" width="100" [allowEditing]="false" [visible]="false"></dxi-column>

    <dxi-column dataField="accountName" caption="Account" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="importToCostCentreId" caption="Import To Cost Centre" dataType="number"
      [allowEditing]="true">
      <dxo-lookup [dataSource]="costCentres" valueExpr="id" displayExpr="description"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="importToVendorId" caption="Import To Vendor" dataType="number" [allowEditing]="true">
      <dxo-lookup [dataSource]="vendors" valueExpr="id" displayExpr="vendorName"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="importToInvoiceVendorId" caption="Import To Invoice Vendor" dataType="number"
      [allowEditing]="true">
      <dxo-lookup [dataSource]="vendors" valueExpr="id" displayExpr="vendorName"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="importMessage" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="truthEnginePurchaseOrderId" width="100" [allowEditing]="false"
      [calculateCellValue]='calculatePOExistsInTruthEngine' caption="PO In Truth Engine" [allowEditing]="false"
      dataType="boolean"></dxi-column>

    <dxi-column dataField="truthEngineInvoiceId" width="100" [allowEditing]="false"
      [calculateCellValue]='calculateInvoiceExistsInTruthEngine' caption="Invoice In Truth Engine"
      [allowEditing]="false" dataType="boolean"></dxi-column>

    <!-- summary totals -->
    <dxo-summary>
      <dxi-total-item column="orderNumber" summaryType="count" displayFormat="{0} rows"></dxi-total-item>
      <dxi-total-item column="subtotal" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"></dxi-total-item>
      <dxi-total-item column="totalTax" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"></dxi-total-item>
      <dxi-total-item column="invoiceTotal" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"></dxi-total-item>
      <dxi-total-item column="invoiceTax" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"></dxi-total-item>
    </dxo-summary>

    <!-- templates -->
    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        Company: <dx-select-box id="selectAccountingCompany" [dataSource]="accountingCompanies" displayExpr="tenantName"
          valueExpr="tenantId" placeholder="Select Accounting Company" [width]="200"
          [(value)]="accountingSystemTenantId" (onValueChanged)="onCompanySelectionChanged()">
        </dx-select-box>
        <span *ngIf="accountingSystemType === 1 || accountingSystemType === 3">
            <span *ngIf="accountingSystemType === 1"> Xero</span><span *ngIf="accountingSystemType === 3"> MYOB</span> Job: 
            <dx-drop-down-box id="selectAccountingSystemJob"
            [deferRendering]="false" [dataSource]="accountingSystemJobs" displayExpr="number" valueExpr="id"
            [showClearButton]="true" placeholder="Select job" contentTemplate="accountingSystemJobs" [width]="150"
            [value]="selectedAccountingSystemJobId" [dropDownOptions]="{ minWidth: 500 }"
            (onValueChanged)="onAccountingSystemJobValueChanged($event)">
            <div *dxTemplate="let e of 'accountingSystemJobs'">
              <dx-data-grid [dataSource]="accountingSystemJobs" [hoverStateEnabled]="true" showBorders="true"
                height="100%" keyExpr="id" [focusedRowKey]="selectedAccountingSystemJobId"
                (onSelectionChanged)="onAccountingSystemJobSelectionChanged(e.component, $event)">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxi-column dataField="number"></dxi-column>
                <dxi-column dataField="name" *ngIf="accountingSystemType === 3"></dxi-column>
              </dx-data-grid>
            </div>
          </dx-drop-down-box></span>
        Date From: <dx-date-box id="fromDateBox" [width]="150" [(value)]='dateFrom' ></dx-date-box>
        <span> Truth Engine Job: <dx-drop-down-box id="selectJob" [deferRendering]="false" [dataSource]="jobs"
            displayExpr="jobNumber" valueExpr="id" [showClearButton]="true" placeholder="Select job"
            contentTemplate="jobs" [width]="150" [(value)]="selectedJobId" [dropDownOptions]="{ minWidth: 500 }"
            (onValueChanged)="onJobValueChanged($event)">
            <div *dxTemplate="let e of 'jobs'">
              <dx-data-grid [dataSource]="jobs" [hoverStateEnabled]="true" showBorders="true" height="100%" keyExpr="id"
                [focusedRowKey]="selectedJobId" (onSelectionChanged)="onJobSelectionChanged(e.component, $event)">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxi-column dataField="jobNumber" [width]="150"></dxi-column>
                <dxi-column dataField="contractName"></dxi-column>
              </dx-data-grid>
            </div>
          </dx-drop-down-box></span>
      </div>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingAccounts" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
