import { GridService } from './../../services/grid.service';
import { HouseType } from './../../dtos/house-type';
import { UtilsService } from './../../services/utils.service';
import { JobService } from './../../services/felixApi/job.service';
import { DivisionService } from './../../services/felixApi/division.service';
import { NotificationService } from './../../services/notification.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { GlobalService } from './../../services/global.service';
import { ClaimsService } from './../../services/felixApi/claims.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpModalComponent } from '../../shared/help.component';
import { WIPReport } from '../../dtos/wip-report';

@Component({
  selector: 'js-wip-report',
  templateUrl: './wip-report.component.html',
  styleUrls: ['./wip-report.component.scss']
})
export class WipReportComponent implements OnInit, OnDestroy {
  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  loadingData = true;
  invoiceCutOffDate: Date;
  invoiceBreakDown = false;
  showOrderedTotal = false;
  hideJobsCompleted = false;
  includeInactiveJobs = false;
  houseTypes: HouseType[];
  invoiceCaption: string;
  summariseToMasterJob = true;

  constructor(private claimsService: ClaimsService,
    private invoiceService: InvoiceService,
    private jobService: JobService,
    private divisionService: DivisionService,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private utilsService: UtilsService,
    public gridService: GridService,
    private globalService: GlobalService) {
    this.setMarginCellValue = this.setMarginCellValue.bind(this);
    this.setPercentCellValue = this.setPercentCellValue.bind(this);
    this.calculateDivision = this.calculateDivision.bind(this);
    this.calcContractExGst = this.calcContractExGst.bind(this);
    this.saveState = this.saveState.bind(this);
    this.loadState = this.loadState.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateMasterJob = this.calculateMasterJob.bind(this);
    this.calculateContractSignedDate = this.calculateContractSignedDate.bind(this);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadState(); // to set check boxes
    this.invoiceCutOffDate = new Date();
    this.loadData(false, false);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  loadData(useCache: boolean, runSetup: boolean) {
    this.subscriptions.push(
      this.invoiceService.getWIPData(useCache)
        .subscribe(
          () => {
            this.loadingData = false;
            this.houseTypes = this.jobService.houseTypes;
            this.invoiceCaption = this.invoiceService.taskControl.isWipUsingTaskCompleteToAccruePo
              ? 'Cost Invoices Accrued Ex.GST' : 'Cost Invoices Received Ex.GST';

            if (runSetup) {
              this.setUpDataSource();
            }
          },
          err => {
            this.notiService.notify(err);
            this.loadingData = false;
          })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'jobNumber',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.getWIP(this.invoiceCutOffDate, this.includeInactiveJobs).subscribe(res => {
              let summarisedJobs: WIPReport[] = [];
              if (this.summariseToMasterJob) {
                res.forEach(resJob => {
                  // adjust for commissions and marketing costs
                  if (resJob.commissionCost) {
                    resJob.jobCostExcGST -= resJob.commissionCost;
                  }
                  if (resJob.marketingCost) {
                    resJob.jobCostExcGST -= resJob.marketingCost;
                  }

                  const job = this.jobService.jobs.find(i => i.jobNumber === resJob.jobNumber);
                  if (!job.masterJobId) {
                    // check for any child jobs
                    const childJobs = this.jobService.jobs.filter(i => i.masterJobId === job.id);

                    childJobs.forEach(childJob => {
                      // get the WIP record
                      const childWIPRecord = res.find(i => i.jobNumber === childJob.jobNumber);

                      if (childWIPRecord) {
                        resJob.basePrice += childWIPRecord.basePrice ?? 0;
                        resJob.contractPrice += childWIPRecord.contractPrice ?? 0;
                        resJob.postContractVariations += childWIPRecord.postContractVariations ?? 0;
                        resJob.contractPriceIncGST += childWIPRecord.contractPriceIncGST ?? 0;
                        resJob.jobCostExcGST += childWIPRecord.jobCostExcGST ?? 0;
                        resJob.costInvoices += childWIPRecord.costInvoices ?? 0;
                        resJob.claimInvoices += childWIPRecord.claimInvoices ?? 0;
                        resJob.variationInvoices += childWIPRecord.variationInvoices ?? 0;
                        resJob.incomeInvoices += childWIPRecord.incomeInvoices ?? 0;
                        resJob.orderExtraTotal += childWIPRecord.orderExtraTotal ?? 0;
                        resJob.variationsInScheduledAmount += childWIPRecord.variationsInScheduledAmount ?? 0;
                        resJob.extraIncomeIncGST += childWIPRecord.extraIncomeIncGST ?? 0;
                        resJob.allOrdersTotal += childWIPRecord.allOrdersTotal ?? 0;
                      }
                    });
                    summarisedJobs.push(resJob);
                  }
                });
              } else {
                res.forEach(resJob => {
                  // adjust for commissions and marketing costs
                  if (resJob.commissionCost) {
                    resJob.jobCostExcGST -= resJob.commissionCost;
                  }
                  if (resJob.marketingCost) {
                    resJob.jobCostExcGST -= resJob.marketingCost;
                  }
                });
                summarisedJobs = res;
              }
              return resolve(summarisedJobs.filter(i => !this.hideJobsCompleted
                || (!i.completionDate || this.invoiceService.dateDiffInDays(new Date(i.completionDate), this.invoiceCutOffDate) >= 0)));
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) => {
          this.subscriptions.push(
            this.claimsService.updateJobCashFlow(encodeURIComponent(key), values).subscribe(res => {
              return resolve(res);
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          );
        });
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxTextBox',
        options: {
          width: 90,
          value: 'Cut-Off Date:',
          readOnly: true,
          stylingMode: 'underlined'
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxDateBox',
        options: {
          value: this.invoiceCutOffDate,
          onValueChanged: this.invoiceCutOffDateChanged.bind(this),
          calendarOptions: { showTodayButton: true }
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Go',
          type: 'default',
          onClick: this.setUpDataSource.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Summarise to Master Job',
          value: this.summariseToMasterJob,
          rtlEnabled: true,
          onValueChanged: this.summariseToMasterJobChanged.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Ordered Total',
          value: this.showOrderedTotal,
          rtlEnabled: true,
          onValueChanged: this.showOrderedTotalChanged.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Invoiced Breakdown',
          value: this.invoiceBreakDown,
          rtlEnabled: true,
          onValueChanged: this.invoiceBreakDownChanged.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Hide Jobs Completed at Cut-Off Date',
          value: this.hideJobsCompleted,
          rtlEnabled: true,
          onValueChanged: this.hideJobsCompletedChanged.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Include Inactive Jobs',
          value: this.includeInactiveJobs,
          rtlEnabled: true,
          onValueChanged: this.includeInactiveJobsChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  calcContractIncGst(rowData): number {
    return ((rowData.contractPriceIncGST ?? 0) + (rowData.postContractVariations ?? 0));
  }

  calcContractExGst(rowData): number {
    const job = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber);
    if (job?.isGSTFree) {
      return this.calcContractIncGst(rowData);
    } else {
      return Math.round(this.calcContractIncGst(rowData)
        / (1 + (job?.gstRate ? job.gstRate : this.invoiceService.globalGSTRate) / 100) * 100) / 100;
    }
  }

  calcProfitAmount(rowData): number {
    return this.calcContractExGst(rowData) - (!rowData.jobCostExcGST ? 0 : rowData.jobCostExcGST);
  }

  calcTotalContract = (rowData) => {
    return this.calcContractIncGst(rowData);
  }

  calcProfit = (rowData) => {
    return this.calcProfitAmount(rowData);
  }

  calcMarkUpPercent = (rowData) => {
    if (rowData && rowData.jobCostExcGST && rowData.contractPriceIncGST) {
      return this.returnInfinityAsNull(Math.round(this.calcProfitAmount(rowData) / rowData.jobCostExcGST * 10000) / 100);
    } else {
      return null;
    }
  }

  calcMarginPercent = (rowData) => {
    if (rowData && rowData.jobCostExcGST && rowData.contractPriceIncGST) {
      return this.returnInfinityAsNull(Math.round(this.calcProfitAmount(rowData) / this.calcContractExGst(rowData) * 10000) / 100);
    } else {
      return null;
    }
  }

  calcAdjustment = (rowData) => {
    if (rowData && rowData.jobCostExcGST) {
      return this.returnInfinityAsNull((((rowData.costInvoices ?? 0) / rowData.jobCostExcGST) * this.calcContractExGst(rowData))
        - (rowData.incomeInvoices ?? 0));
    } else {
      return null;
    }
  }

  calcCostAdjustment = (rowData) => {
    if (rowData && rowData.jobCostExcGST) {
      return (rowData.costInvoices ?? 0) - ((rowData.incomeInvoices ?? 0) / this.calcContractExGst(rowData)) * rowData.jobCostExcGST;
    } else {
      return null;
    }
  }

  calculateCommMarketingAdjustment = (rowData) => {
    if (rowData && rowData.jobCostExcGST) {
      return this.returnInfinityAsNull((((rowData.costInvoices ?? 0) / rowData.jobCostExcGST) * ((rowData.commissionCost ?? 0) + (rowData.marketingCost ?? 0))));
    } else {
      return null;
    }
  }

  calcIsOrdersLocked(rowData): boolean {
    return rowData.lockedDate ? true : false;
  }

  returnInfinityAsNull(value: number): number {
    return value === Infinity || value === -Infinity ? null : value;
  }

  setPercentCellValue(rowData, value, originalData) {
    if (value) {
      let jobCostExcGST = 0;
      const job = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber);
      if (job?.isGSTFree) {
        const jobCost
          = Math.round((originalData.contractPriceIncGST
            + (originalData.postContractVariations ? originalData.postContractVariations : 0)) /
            (1 + (value / 100)) * 100) / 100;
        jobCostExcGST = Math.round(jobCost);
      } else {
        const jobCost
          = Math.round(((originalData.contractPriceIncGST
            + (originalData.postContractVariations ? originalData.postContractVariations : 0)) /
            (1 + (job?.gstRate ? job.gstRate : this.invoiceService.globalGSTRate) / 100) / (1 + (value / 100))) * 100) / 100;
        jobCostExcGST = Math.round(jobCost);
      }

      // if (rowData.jobCostExcGST !== jobCostExcGST) {
      rowData.jobCostExcGST = jobCostExcGST;
      // }
    }
  }

  setMarginCellValue(rowData, value, originalData) {
    if (value) {
      let jobCostExcGST = 0;
      const job = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber);
      if (job?.isGSTFree) {
        const jobCost
          = Math.round((originalData.contractPriceIncGST
            + (originalData.postContractVariations ? originalData.postContractVariations : 0)) *
            (1 - (value / 100)) * 100) / 100;
        jobCostExcGST = Math.round(jobCost);
      } else {
        const jobCost
          = Math.round((originalData.contractPriceIncGST
            + (originalData.postContractVariations ? originalData.postContractVariations : 0)) /
            (1 + (job?.gstRate ? job.gstRate : this.invoiceService.globalGSTRate) / 100) * (1 - (value / 100)) * 100) / 100;
        jobCostExcGST = Math.round(jobCost);
      }

      // if (rowData.jobCostExcGST !== jobCostExcGST) {
      rowData.jobCostExcGST = jobCostExcGST;
      // }
    }
  }

  clearStatePersistance() {
    this.loadingData = true;
    this.invoiceBreakDown = false;
    this.showOrderedTotal = false;
    this.hideJobsCompleted = false;
    this.summariseToMasterJob = true;
    localStorage.removeItem('wip-report');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }

  calculateDivision(rowData): string {
    const job = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber);
    return this.divisionService.divisions.find(i => i.id === job?.divisionId)?.description;
  }

  invoiceCutOffDateChanged(e) {
    this.invoiceCutOffDate = e.value;
    this.setUpDataSource();
  }

  invoiceBreakDownChanged(e) {
    const state = this.loadState();
    this.invoiceBreakDown = e.value;
    this.saveState(state);
  }

  showOrderedTotalChanged(e) {
    const state = this.loadState();
    this.showOrderedTotal = e.value;
    this.saveState(state);
  }

  hideJobsCompletedChanged(e) {
    const state = this.loadState();
    this.hideJobsCompleted = e.value;
    this.saveState(state);
    this.setUpDataSource();
  }

  includeInactiveJobsChanged(e) {
    this.includeInactiveJobs = e.value;
    this.setUpDataSource();
  }

  summariseToMasterJobChanged(e) {
    const state = this.loadState();
    this.summariseToMasterJob = e.value;
    this.saveState(state);
    this.setUpDataSource();
  }

  onEditorPreparing(e: any) {
    // if locked no changes are allowed to the job cost figure
    if (e.parentType === 'dataRow' && e.row.data.schedulingLocked) {
      if (e.dataField === 'jobCostExcGST') {
        e.editorOptions.disabled = true;
      }
    }
  }

  saveState(currentState) {
    if (currentState) {
      currentState.invoiceBreakDown = this.invoiceBreakDown ? true : false;
      currentState.showOrderedTotal = this.showOrderedTotal ? true : false;
      currentState.hideJobsCompleted = this.hideJobsCompleted ? true : false;
      currentState.summariseToMasterJob = this.summariseToMasterJob ? true : false;

      const stateString = JSON.stringify(currentState);
      localStorage.setItem('wip-report', stateString);
    }
  }

  loadState(): any {
    const currentState = JSON.parse(localStorage.getItem('wip-report'));

    if (currentState) {
      this.invoiceBreakDown = currentState.invoiceBreakDown ? true : false;
      this.showOrderedTotal = currentState.showOrderedTotal ? true : false;
      this.hideJobsCompleted = currentState.hideJobsCompleted ? true : false;
      this.summariseToMasterJob = currentState.summariseToMasterJob ?? true;
    }
    return currentState;
  }

  help(dataField: string) {
    const modalRef = this.modalService.open(HelpModalComponent,
      { windowClass: 'modal-lg', scrollable: true });
    modalRef.componentInstance.dataField = dataField;
    modalRef.componentInstance.wipReport = true;

    modalRef.result.then(() => { });
  }

  calculateSiteAddress(data) {
    return this.jobService.jobs.find(i => i.jobNumber === data.jobNumber)?.jobAddressString;
  }

  calculateMasterJob(rowData): string {
    const masterJobId = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber)?.masterJobId;

    if (masterJobId) {
      return this.jobService.jobs.find(i => i.id === masterJobId)?.jobNumber;
    } else {
      return '';
    }
  }

  calculateContractSignedDate(data): Date {
    return this.utilsService.convertDateStringToDate(this.jobService.jobs.find(i => i.jobNumber === data.jobNumber)?.contractSignedDate);
  }

  refresh() {
    this.loadData(false, true);
  }
}
