<div class="page">

  <js-job-number *ngIf="!loadingData" (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>

  <dx-data-grid *ngIf="!loadingData && !loading" #poGrid id="poGrid" [height]="gridHeight"
    [dataSource]="purchaseOrderData" [allowColumnReordering]="false" [allowColumnResizing]="true"
    [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    [(selectedRowKeys)]="selectedOrders" [hoverStateEnabled]="true" (onRowPrepared)="onRowPrepared($event)"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')" (onRowClick)="onMultiRowClick($event)"
    (onExporting)="gridService.onExporting($event, 'order-send')">

    <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-editing mode="row" [allowUpdating]="true" [useIcons]="true" startEditAction="click">
    </dxo-editing>

    <dxo-selection [allowSelectAll]="true" showCheckBoxesMode='always' mode="multiple">
    </dxo-selection>

    <dxi-column dataField="printedDate" caption="Date Sent" dataType="date" width="100" format="d/M/yy"
      alignment="center" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="sentFromQueueDate" dataType="date" [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="emailQueueStatus" width="100" alignment="center" [allowEditing]="false"
      [calculateCellValue]="calculateSentFromQueueStatus">
    </dxi-column>

    <dxi-column dataField="createDate" caption="Raised" dataType="date" width="100" format="d/M/yy" alignment="center"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="createUserId" caption="Raised by" dataType="number" width="130" [allowEditing]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreId" caption="Cost Code" dataType="number" width="100" alignment="center"
      sortOrder="asc" [allowEditing]="false" [calculateSortValue]="calculateCostCentreSortValue">
      <dxo-lookup [dataSource]="costCentres" displayExpr="priceFileCode" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreDescription" caption="Cost Centre" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="poNumber" caption="PO Number" width="120" sortOrder="asc" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="orderTotal" caption="Total" width="90" format="#,###" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [allowEditing]="false"
      [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="vendorEmail" caption="Email Address">
    </dxi-column>

    <dxi-column dataField="hasAttachments" caption="" dataType="boolean" [width]="45"
      [calculateCellValue]='calculateHasAttachments' [allowEditing]="false" cellTemplate="attachmentCellTemplate2">
    </dxi-column>

    <dxi-column type="buttons" [width]="95">
      <dxi-button name="edit"></dxi-button>
      <dxi-button icon="docfile" [onClick]="attachDocs"></dxi-button>
    </dxi-column>


    <!-- Templates -->
    <div *dxTemplate="let cellInfo of 'attachmentCellTemplate2'">
      <div *ngIf="cellInfo.value && cellInfo.value != ''">
        <span *ngIf="cellInfo.value[0] === 'A'" id="addendaAttached">{{addendumLetter}}</span>
        <i *ngIf="cellInfo.value[0] !== 'A' && cellInfo.value[0] !== 'D'" class="material-icons red"
          (click)="missingAttachmentsPopup(cellInfo.value)">error_outline</i>
        <i *ngIf="(cellInfo.value[0] === 'A' && cellInfo.value[1] === 'D') || cellInfo.value[0] === 'D'"
          class="material-icons green">attach_file</i>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <span class="me-1">Un-Sent Only</span>
        <dx-check-box [(value)]="unSentOnly" [width]="30" (onValueChanged)="showUnSentOnlyChanged($event)">
        </dx-check-box>
      </div>
    </div>
  </dx-data-grid>


  <div *ngIf="!loadingData && !loading" class="subjectMessageClass mt-2">
    <div class="subjectTextClass">Email Subject:</div>
    <dx-text-box class="subjectClass" [(value)]="emailSubject"></dx-text-box>
  </div>

  <div *ngIf="!loadingData && !loading" class="subjectMessageClass mt-2">
    <div class="subjectTextClass">Email Message:</div>
    <dx-text-area class="subjectClass" [height]="90" [(value)]="emailMessage"> </dx-text-area>
    <div class="subjectTextHelpClass">
      <div>Can use:</div>
      <div>[{{siteManagerRoleName}}]</div>
      <div>[{{siteManagerRoleName}} Phone]</div>[{{siteManagerRoleName}} Email]
    </div>
  </div>

  <div *ngIf="!loadingData && !loading" class="subjectMessageClass mt-2">
    <div class="subjectTextClass">Attach Variation</div>
    <dx-select-box class="selectClass" [dataSource]="variations" displayExpr="title" valueExpr="id"
      placeholder="Select variation if required" [showClearButton]="true" (onValueChanged)="getVariationParams($event)">
      <div *dxTemplate="let data of 'item'">
        <b class="vo-number">{{data.displayedVariationNumber}}</b>
        <span>{{data.title}}</span>
      </div>
    </dx-select-box>
  </div>

  <dx-button *ngIf="!loadingData && !loading && !anyMissingAttachments" text="Send" class="mt-1" type="default"
    (onClick)="sendOrders()" [disabled]="!selectedOrders || !selectedOrders.length">
  </dx-button>

  <span *ngIf="!loadingData && !loading" class="ms-4 me-2 checkBoxFont">cc email to yourself?</span>
  <dx-check-box *ngIf="!loadingData && !loading" [(value)]="ccToSelf" [width]="30"> </dx-check-box>

  <dx-button *ngIf="!loadingData && !loading && anyMissingAttachments" text="Send Anyway" class="mt-1" type="default"
    (onClick)="sendOrdersGo(false, false, false)">
  </dx-button>

  <span *ngIf="!loadingData && !loading" class="ms-3 me-2 checkBoxFont">Send History of Changes?</span>
  <dx-check-box *ngIf="!loadingData && !loading" [(value)]="sendHistoryOfChanges" [width]="30"> </dx-check-box>

  <span *ngIf="!loadingData && !loading" class="ms-3 me-2 checkBoxFont">Make any adds/changes bold?</span>
  <dx-check-box *ngIf="!loadingData && !loading" [(value)]="boldAddedExtras" [width]="30"> </dx-check-box>

  <span *ngIf="!loadingData && !loading" class="ms-3 me-2 checkBoxFont">CC to {{siteManagerRoleName}}?</span>
  <dx-check-box *ngIf="!loadingData && !loading" [(value)]="ccToSiteManager" [width]="30"> </dx-check-box>

  <span *ngIf="!loadingData && !loading" class="ms-3 me-2 checkBoxFont">Show Prices?</span>
  <dx-check-box *ngIf="!loadingData && !loading" [(value)]="showPrices" [width]="30"> </dx-check-box>

  <dx-button *ngIf="!loadingData && !loading" id="downloadButton" text="Download" class="mt-1 ms-2" type="default"
    stylingMode="outlined" (onClick)="downloadOrders()">
  </dx-button>

  <dx-button *ngIf="!loadingData && !loading && !sendWithoutAttachments" id="downloadButton"
    text="Send Without Attachments" class="mt-1 ms-2" type="default" stylingMode="outlined"
    (onClick)="sendOrdersWithoutAttachments()">
  </dx-button>

  <dx-button *ngIf="!loadingData && !loading && sendWithoutAttachments" id="downloadButton"
    text="Confirm Send Without Attachments" class="mt-1 ms-2" type="danger" stylingMode="outlined"
    (onClick)="sendOrdersGo(false, true, false)">
  </dx-button>

  <dx-button *ngIf="!loadingData && !loading && !sendWithoutAttachments" id="downloadButton" text="Mark as Sent"
    class="mt-1 ms-2" type="default" stylingMode="outlined" (onClick)="markOrdersSent()">
  </dx-button>
</div>

<!-- missing attachments pop-up -->
<dx-popup class="popup" [width]="320" [height]="370" [showTitle]="true" title="Attachments Missing"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="missingAttachmentsPopupVisible" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid id="missingAttachmentGrid" class="m-auto" [dataSource]="missingAttachments" keyExpr="id"
      [height]="280" [showBorders]="true" [rowAlternationEnabled]="false" [wordWrapEnabled]="true"
      [allowColumnResizing]="false" (onExporting)="gridService.onExporting($event, 'missing-attachments')">

      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-export [enabled]="true"></dxo-export>
      <dxi-column dataField="description" caption="Info Documents NOT found" dataType="string"> </dxi-column>
    </dx-data-grid>
  </div>
</dx-popup>

<!-- Add attachments -->
<dx-popup class="popup" [width]="popupWidth" [height]="popupHeight" [showTitle]="true" title="Attachments"
  [shading]="false" [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="attachmentsPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="popupScroll">

      <dx-tree-list *ngIf="!loadingDocs" id="jobDocumentsTree" [dataSource]="jobDocuments" keyExpr="keyId"
        [height]="popupHeight - 133" parentIdExpr="parentKeyId" [showRowLines]="true" [showBorders]="true"
        [columnAutoWidth]="false" [(selectedRowKeys)]="selectedJobDocs"
        (onToolbarPreparing)="onDocTreePreparinng($event)" (onSelectionChanged)="attachmentSelectionChanged($event)">

        <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
        <dxo-selection mode="multiple" [recursive]="true" [allowSelectAll]="false"> </dxo-selection>

        <dxi-column dataField="description" [allowSorting]="false"></dxi-column>
        <dxi-column dataField="Auto-Attached" caption="Auto-Attached" [calculateCellValue]='calculateCallUpDocsType'
          [width]="110" cellTemplate="callUpDocsTypeCellTemplate" [allowSorting]="false"></dxi-column>

        <div *dxTemplate="let cellInfo of 'callUpDocsTypeCellTemplate'">
          <i *ngIf="cellInfo.value" class="material-icons">done</i>
        </div>
      </dx-tree-list>

      <dx-button *ngIf="!loadingDocs" text="Save" class="me-2 mt-1" type="default" (onClick)="saveAttachedDocs()">
      </dx-button>
      <dx-button *ngIf="!loadingDocs" text="Cancel" class="mt-1" type="danger" (onClick)="cancelAttachedDocs()">
      </dx-button>
    </dx-scroll-view>
  </div>
</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loading || loadingDocs" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>

<!-- Get variation parameters -->
<dx-popup class="popup" [width]="400" [height]="225" [showTitle]="true" title="Variation details" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="variationParamsPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="popupScroll">

      <div class="checkbox">
        <label for="printImages">
          Print Images &nbsp;
        </label>
        <input type="checkbox" id="printImages" [checked]="printImages" (change)="printImages = !printImages">
      </div>

      <div class="checkbox">
        <label for="printVariationPrices">
          Print Prices &nbsp;
        </label>
        <input type="checkbox" id="printVariationPrices" [checked]="printVariationPrices"
          (change)="printVariationPrices = !printVariationPrices">
      </div>

      <div class="checkbox">
        <label for="printNotApplicable">
          Print items marked 'Not Applicable' &nbsp;
        </label>
        <input type="checkbox" id="printNotApplicable" [checked]="printNotApplicable"
          (change)="printNotApplicable = !printNotApplicable">
      </div>

      <div class="checkbox">
        <label for="printConnectedTogether">
          Print connected items together &nbsp;
        </label>
        <input type="checkbox" id="printConnectedTogether" [checked]="printConnectedTogether"
          (change)="printConnectedTogether = !printConnectedTogether">
      </div>

      <div class="checkbox">
        <label for="printVONumber">
          Print variation number &nbsp;
        </label>
        <input type="checkbox" id="printVONumber" [checked]="printVONumber" (change)="printVONumber = !printVONumber">
      </div>

      <dx-button text="Ok" class="send-email-button" type="default"
        (onClick)="variationParamsPopupVisible = !variationParamsPopupVisible">
      </dx-button>
    </dx-scroll-view>
  </div>
</dx-popup>


<!-- ask to download separately -->
<dx-popup class="popup" [width]="250" [height]="170" [showTitle]="true" title="Download" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="downloadSeparatelyPopupVisible" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <dx-button *ngIf="!loadingData && !loading" id="downloadButton" text="Download as one file" class="mt-1"
      [width]="240" type="default" stylingMode="outlined" (onClick)="sendOrdersGo(true, false, false)">
    </dx-button>

    <dx-button *ngIf="!loadingData && !loading" id="downloadButton" text="Download as separate files" class="mt-2"
      [width]="240" type="default" stylingMode="outlined" (onClick)="downloadSeparateSepareteFiles()">
    </dx-button>
  </div>
</dx-popup>
