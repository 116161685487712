export class OrderControl {
  public id: number;
  public globalNote: string;
  public returnEmail: string;
  public modifiedDate: Date;
  public modifiedUserId: number;
  public ccEmailAddress: string;
  public invoiceThreshold: number;
  public jobTrackingCategoryId: string;
  public defaultEmailMessage: string;
  public minPostingDate: Date;
  public submittedInvoiceFormatId: number;
  public isSubcontractorsCutOffStrict: boolean;
  public purchaseOrderExtraNote: string;
  public canChangeDescriptionOrRateOnOrderLines: boolean;
}
