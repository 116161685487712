import { GlobalService } from './../../services/global.service';
import { Component, OnInit } from '@angular/core';
import { MigrationToolsHelperService } from '../migration-tools-helper.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-migration-tools-menu',
  templateUrl: './migration-tools-menu.component.html',
  styleUrls: ['./migration-tools-menu.component.scss']
})
export class MigrationToolsMenuComponent implements OnInit {

  sections: string[];
  purchaseOrderQueryCounter = 0;
  invoiceQueryCounter = 0;
  orderImportCounter = 0;
  incomeInvoiceQueryCounter = 0;

  selectedIndex = 0;
  accountingSystemType: number;

  constructor(public helper: MigrationToolsHelperService,
    private globalService: GlobalService) { }

  ngOnInit() {
    this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
    this.helper.selectedSection = 'Income Invoice Query';
    this.sections = this.helper.accountingSystemSections;
    this.sections = this.sections.concat(this.helper.fileUploadSections);
  }

  tabSelected(e) {
    if (e.itemData === 'Purchase Order Query') {
      this.purchaseOrderQueryCounter++;
    }
    if (e.itemData === 'Invoice Query') {
      this.invoiceQueryCounter++;
    }
    if (e.itemData === 'Order Import') {
      this.orderImportCounter++;
    }
    if (e.itemData === 'Income Invoice Query') {
      this.incomeInvoiceQueryCounter++;
    }
    this.helper.selectedSection = e.itemData;
  }
}