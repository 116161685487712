import { GridService } from './../../services/grid.service';
import { InvoiceStatusTypeEnum } from './../../dtos/invoice';
import { DivisionService } from './../../services/felixApi/division.service';
import { AuthService } from './../../services/auth.service';
import { UserService } from './../../services/felixApi/user.service';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { PoService } from '../../services/felixApi/po.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Vendor } from '../../dtos/vendor';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GlobalService } from '../../services/global.service';
import { InvoiceService } from '../../services/felixApi/invoice.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { PriceFileItem } from '../../dtos/price-file-item';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { VendorGroup } from '../../dtos/vendor-group';
import { User } from '../../dtos/user';
import { JobSearchModalComponent } from '../../shared/job-search-modal/job-search-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { GlAccountType } from '../../dtos/gl-account-type';
import { environment } from '../../../environments/environment';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'js-all-invoices',
  templateUrl: './all-invoices.component.html',
  styleUrls: ['./all-invoices.component.scss']
})
export class AllInvoicesComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: DataSource;
  vendors: Vendor[];
  loadingData: boolean;
  jobs: Job[];
  gridHeight: number;
  lastVendorId: number;
  attachmentsPopupVisible: boolean;
  currentInvoiceId: any;
  files: any[] = [];
  loadingFile: boolean;
  attachmentExists: any;
  attachmentsPopupHeight: number;
  costCentres: PriceFileItem[];
  vendorGroups: VendorGroup[];
  selectedVendorGroupId: number;
  users: User[];
  invoiceStatuses = [
    { id: 1, description: 'Entered' },
    { id: 2, description: 'OnHold' },
    { id: 7, description: 'Approved' },
    { id: 8, description: 'Posted' },
    { id: 9, description: 'Paid' },
    { id: 20, description: 'Rejected' },
    { id: 30, description: 'Deleted' },
    { id: 32, description: 'Split' }
  ];
  selectedVendorId: number;
  selectedJobNumber: string;
  jobNumber = '';
  isAdmin: boolean;
  deletePopupVisible: boolean;
  invoiceNumber: any;
  invoiceToDelete: any;
  buttonsWidth: number;
  accountingSystemType: number;
  glAccountTypes: GlAccountType[];

  constructor(
    private globalService: GlobalService,
    private modalService: NgbModal,
    private invoiceService: InvoiceService,
    private notiService: NotificationService,
    private jobService: JobService,
    private poService: PoService,
    private userService: UserService,
    private estimatingService: EstimatingService,
    private maintenanceService: MaintenanceService,
    private divisionService: DivisionService,
    public gridService: GridService,
    private authService: AuthService) {
    this.setVendorCellValue = this.setVendorCellValue.bind(this);
    this.setOrderNumberCellValue = this.setOrderNumberCellValue.bind(this);
    this.setTotalIncGSTCellValue = this.setTotalIncGSTCellValue.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.calculateJobNumber = this.calculateJobNumber.bind(this);
    this.calculateDivision = this.calculateDivision.bind(this);
    this.openPO = this.openPO.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.calcGlAccountType = this.calcGlAccountType.bind(this);
    this.calcVendorGroup = this.calcVendorGroup.bind(this);
    this.openCostCentre = this.openCostCentre.bind(this);
    this.checkIfPaid = this.checkIfPaid.bind(this);
    this.calcVendorEmail = this.calcVendorEmail.bind(this);
    this.calcVendorPhone = this.calcVendorPhone.bind(this);
    this.calcVendorContact = this.calcVendorContact.bind(this);
    this.calcSiteStartDate = this.calcSiteStartDate.bind(this);
    this.setInvoiceStatus = this.setInvoiceStatus.bind(this);

  }

  ngOnInit(): void {
    if (this.authService.isAdminOrSuper()
      || this.authService.areaPermissions.find(i => i.applicationArea === 'InvoiceSubmit')?.permissionType === 'Admin') {
      this.isAdmin = true;
    }

    if (this.isAdmin) {
      this.buttonsWidth = 175;
    } else {
      this.buttonsWidth = 120;
    }

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadData(false, false);
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData(useCache: boolean, isRefreshing: boolean) {
    this.subscriptions.push(
      this.invoiceService.getInvoicesDataForOnHold(useCache)
        .subscribe({
          next: () => {
            this.vendors = this.maintenanceService.allVendors;
            this.jobs = this.jobService.jobs;
            this.costCentres = this.estimatingService.costCentres;
            this.users = this.userService.users;
            this.vendorGroups = this.maintenanceService.vendorGroups;
            this.glAccountTypes = this.maintenanceService.glAccountTypes;
            this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
            this.loadingData = false;

            if (isRefreshing || (this.globalService.externalInvoiceId && this.globalService.externalInvoiceId !== '')) {
              // read the invoice
              this.setUpDataSource(useCache);
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource(useCache: boolean) {
    if (this.selectedVendorId || this.jobNumber !== ''
      || (this.globalService.externalInvoiceId !== null && this.globalService.externalInvoiceId !== '')) {
      this.dataSource = new DataSource({
        key: 'id',
        load: async () => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.invoiceService
                .getAllInvoices(this.selectedVendorId, this.jobNumber, this.globalService.externalInvoiceId, null, useCache, true)
                .subscribe({
                  next: (res) => {
                    if (this.globalService.externalInvoiceId && this.globalService.externalInvoiceId !== '') {
                      this.globalService.externalInvoiceId = null;
                      this.selectedVendorId = res[0].vendorId;
                      const job = this.jobs.find(i => i.id === res[0].jobId);
                      this.jobNumber = job?.jobNumber;
                    }
                    return resolve(res);
                  }, error: (err) => {
                    return reject(this.globalService.returnError(err));
                  }
                })
            ));
        },
        update: async (key, values) => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.invoiceService.updateInvoice(encodeURIComponent(key), values).subscribe({
                next: (res) => {
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            ));
        },
      });
    } else {
      this.dataSource = null;
    }
  }

  onToolbarPreparing(e, toolbarTemplate: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });

    toolbarItems.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: toolbarTemplate
    });
  }

  onJobNumberChanged() {
    this.jobNumber = this.jobNumber.trim();
    this.setUpDataSource(true);
  }

  setVendorCellValue(rowData, value) {
    rowData.vendorId = value;
    this.lastVendorId = value;
  }

  setTotalIncGSTCellValue(rowData, value) {
    rowData.totalIncGST = value;

    const job = this.jobService.jobs.find(i => i.id === rowData.jobId);
    if (job?.isGSTFree) {
      rowData.totalGST = 0;
      this.notiService.showInfo('GST Free Vendor');
    } else {
      rowData.totalGST = Math.round((value / (1 + this.invoiceService.globalGSTRate)) * 100) / 100;
    }

    rowData.totalExGST = value - rowData.totalGST;
  }

  setTotalGSTCellValue(rowData, value, originalData) {
    rowData.totalGST = value;
    rowData.totalExGST = (rowData.totalIncGST ?? originalData.totalIncGST) - value;
  }

  async setOrderNumberCellValue(rowData, value, originalData) {
    let orderNumberArray = value.split('.');

    if (orderNumberArray.length > 1) {
      const jobNumber = orderNumberArray[0];
      const job = this.jobs.find(i => i.jobNumber === jobNumber);
      if (job) {
        let orderNumber = orderNumberArray[1] + (orderNumberArray.length <= 2 ? '' : '.' + orderNumberArray[2]);

        if (orderNumberArray.length > 3) {
          orderNumber += '.' + orderNumberArray[3];
        }
        // look this up to see if we can find the matching PO
        const po = this.poService.allPurchaseOrdersForCompany.find(i => i.jobId === job.id && i.poNumber === orderNumber);
        if (po) {
          rowData.orderNumber = value;
          rowData.purchaseOrderId = po.id;
          rowData.jobId = job.id;
          rowData.poNumber = orderNumber;
          rowData.remainingBudget = po.orderTotal - (po.approvedInvoicesTotal ? po.approvedInvoicesTotal : 0);
          rowData.costCentreId = po.costCentreId;

          if (originalData.vendorId !== po.vendorId) {
            const vendor = this.maintenanceService.allVendors.find(i => i.id === po.vendorId);
            if (!vendor?.canAnyVendorInvoice) {
              if (!this.maintenanceService.vendorPayables
                .find(i => i.orderVendorId === po.vendorId && i.payVendorId === originalData.vendorId)) {
                this.notiService.showError('Cannot accept invoices from this vendor for this order');
              }
            }
          }
        } else {
          // error
          this.notiService.showError('Cannot find purchase order');
        }
      } else {
        // we may have a DataBuild PO - format job/po
        orderNumberArray = value.split('/');

        if (orderNumberArray.length === 2) {
          const jobNum = orderNumberArray[0];
          const jobDB = this.jobs.find(i => i.jobNumber === jobNum);
          if (jobDB) {
            const orderNumber = orderNumberArray[1];
            // look this up to see if we can find the matching PO
            await this.getPurchaseOrder(jobDB.id, orderNumber)
              .then(
                po => {
                  if (po) {
                    rowData.orderNumber = value;
                    rowData.purchaseOrderId = po.id;
                    rowData.jobId = jobDB.id;
                    rowData.poNumber = orderNumber;
                    rowData.remainingBudget = po.orderTotal - (po.approvedInvoicesTotal ? po.approvedInvoicesTotal : 0);
                    rowData.costCentreId = po.costCentreId;
                  }
                });
          }
        } else {
          // error
          this.notiService.showError('Cannot find purchase order');
        }
      }
    } else {
      // error
      this.notiService.showError('Cannot find purchase order');
    }
  }

  getPurchaseOrder(jobId: number, orderNumber: string): Promise<PurchaseOrder> {
    return new Promise((resolve, reject) =>
      this.subscriptions.push(
        this.poService.getPurchaseOrder(jobId, orderNumber).subscribe({
          next: (res) => {
            return resolve(res);
          }, error: (err) => {
            return reject(this.globalService.returnError(err));
          }
        })
      ));
  }

  // calculateHasAttachments(data) {
  //   return data.hasBlob ? data.id : null;
  // }

  calculateJobNumber(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobNumber;
  }

  calculateDivision(data) {
    const divisionId = this.jobs.find(i => i.id === data.jobId)?.divisionId;
    return this.divisionService.divisions.find(i => i.id === divisionId)?.description;
  }

  // calculateCostCentreId(data) {
  //   if (data.purchaseOrderId) {
  //     const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === data.purchaseOrderId);
  //     return po?.costCentreId;
  //   } else {
  //     return null;
  //   }
  // }

  calcVendorGroup(data) {
    const vendor = this.vendors.find(i => i.id === data.vendorId);
    return this.vendorGroups.find(i => i.id === vendor?.vendorGroupId)?.description;
  }

  calcVendorEmail(data) {
    const vendor = this.vendors.find(i => i.id === data.vendorId);
    return vendor?.email;
  }

  calcVendorPhone(data) {
    const vendor = this.vendors.find(i => i.id === data.vendorId);
    return vendor?.phoneNumber;
  }

  calcVendorContact(data) {
    const vendor = this.vendors.find(i => i.id === data.vendorId);
    return vendor?.vendorContact;
  }

  calcGlAccountType(data) {
    const vendor = this.vendors.find(i => i.id === data.vendorId);
    return this.glAccountTypes.find(i => i.id === vendor?.glAccountTypeId)?.description;
  }

  calcSiteStartDate(data) {
    const jobExtra = this.jobService.jobExtras.find(i => i.jobId === data.jobId);
    if (jobExtra?.siteStartDate) {
      return new Date(jobExtra?.siteStartDate);
    }
    return null;
  }

  downloadAttachment(e) {
    // download attachment
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.getInvoiceAttachment(e.row.data.id).subscribe({
        next: (res) => {
          if (res) {
            // saveAs(this.globalService.getBlobFromBase64(res.attachment, res.attachmentTypeId), res.attachmentName);
            this.poService.convertBlobAndOpen(res.attachment, res.attachmentTypeId, res.attachmentName, true, e.row.data.id);
          } else {
            this.notiService.showInfo('No invoice attached');
          }
          this.loadingFile = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  openPO(e) {
    if (e.row.data.jobId && e.row.data.purchaseOrderId) {
      const jobNumber = this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;
      const dataRecord = {
        purchaseOrderIds: [e.row.data.purchaseOrderId],
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        printPrices: true
      };

      this.notiService.showInfo('downloading order');

      this.subscriptions.push(
        this.poService.sendPurchaseOrders(e.row.data.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.poService.convertAndSave(orderResponse.pdfOrders, jobNumber, e.row.data.poNumber, false);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        this.jobNumber = jobNumber;
        this.setUpDataSource(true);
      }
    });
  }

  clearStatePersistance() {
    this.loadingData = true;
    localStorage.removeItem('all-invoices');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calculateCostCentreSortValue(data) {
    if (data.purchaseOrderId) {
      const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === data.purchaseOrderId);
      return this.costCentres.find(i => i.id === po?.costCentreId)?.orderNumber;
    } else {
      return null;
    }
  }

  deleteRecord(e) {
    this.invoiceToDelete = e.row.data.id;
    const jobNumber = this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;
    this.invoiceNumber = e.row.data.invoiceNumber;
    this.deletePopupVisible = true;
  }

  deleteClicked() {
    this.deletePopupVisible = false;
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.deleteInvoice(this.invoiceToDelete.toString())
        .subscribe({
          next: () => {
            this.loadingFile = false;
            this.setUpDataSource(false);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingFile = false;
          }
        })
    );
  }

  refresh() {
    this.loadData(false, true);
  }

  openCostCentre(e) {
    if (e.row.data.jobId && e.row.data.costCentreId) {
      this.jobService.setCurrentJob(this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber);
      localStorage.setItem('jobANNX-costcentreId', e.row.data.costCentreId);
      window.open(environment.applicationUrl + 'orders?coy=' + this.globalService.getCurrentCompanyId());
    }
  }

  checkIfPaid(e) {
    console.log(e);
    if (!e.row.data.paidDate && e.row.data.invoiceStatusId >= InvoiceStatusTypeEnum.Posted) {
      this.loadingFile = true;
      this.subscriptions.push(
        this.invoiceService.checkIfInvoicePaid(e.row.data.id)
          .subscribe({
            next: () => {
              this.loadingFile = false;
              this.setUpDataSource(true);
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingFile = false;
            }
          })
      );
    }
  }

  setInvoiceStatus(rowData, value, originalData) {
    if (originalData.invoiceStatusId === InvoiceStatusTypeEnum.Posted) {
      this.notiService.showWarning('Changing the status from Posted will NOT update the accounting system! You will need to do that manually if required.',
        null, { disableTimeOut: true, closeButton: true });
    }
    rowData.invoiceStatusId = value;
  }
}
