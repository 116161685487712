<dx-popup class="popup" [width]="600" [height]="popupHeight" [showTitle]="false" [shading]="false" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [visible]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-form *ngIf="!loading && !doNotAllowCosts" id="form" [(formData)]="orderLineExtraForm" [readOnly]="false"
      [showColonAfterLabel]="true" labelLocation="left" [minColWidth]="200" [colCount]="1" width="100%">

      <dxi-item *ngIf="isAddingAsExtra" dataField="varianceCodeId" editorType="dxSelectBox"
        [editorOptions]="{ items: varianceCodes, required: true, displayExpr: 'description', valueExpr: 'id', showClearButton: true, searchEnabled: true }">
        <dxo-label location="left" alignment="right" text="Extras Code"></dxo-label>
        <dxi-validation-rule type="required"> </dxi-validation-rule>
      </dxi-item>

      <dxi-item *ngIf="isAddingAsExtra" dataField="varianceReasonDescription">
        <dxo-label location="left" alignment="right" text="Extras Reason"></dxo-label>
        <dxi-validation-rule type="required"> </dxi-validation-rule>
        <div *dxTemplate>
          <dx-select-box [(ngModel)]="orderLineExtraForm.varianceReasonDescription" [dataSource]="varianceReasons"
            valueExpr="reason" [acceptCustomValue]="true" [showClearButton]="true" [searchEnabled]="true"
            (onValueChanged)="onLookupValueChanged($event)" (onCustomItemCreating)="addCustomItem($event)"
            displayExpr="reason" placeholder="Select reason">
          </dx-select-box>
        </div>
      </dxi-item>

      <dxi-item dataField="orderLineDescription" editorType="dxTextBox" [editorOptions]="{ required: true }">
        <dxo-label location="left" alignment="right" text="Order Line Description"></dxo-label>
        <dxi-validation-rule type="required"> </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="varianceAmount" editorType="dxNumberBox" [editorOptions]="{ required: true }">
        <dxo-label location="left" alignment="right" text="Amount"> </dxo-label>
        <dxi-validation-rule type="required"> </dxi-validation-rule>
      </dxi-item>

      <!-- move from another PO -->
      <dxi-item *ngIf="isAddingAsExtra" dataField="fromPurchaseOrderId">
        <dxo-label location="left" alignment="right" text="Move from another order"></dxo-label>
        <div *dxTemplate>
          <dx-drop-down-box [dropDownOptions]="dropDownOptionsPOs" [dataSource]="purchaseOrders"
            placeholder="Select ONLY if moving budget from another order"
            [value]="orderLineExtraForm.fromPurchaseOrderId" displayExpr="poNumber" valueExpr="id"
            contentTemplate="purchaseOrderContentTemplate" [showClearButton]="true">
            <div *dxTemplate="let e of 'purchaseOrderContentTemplate'">
              <dx-data-grid [dataSource]="purchaseOrders" keyExpr="id" [remoteOperations]="false" height="358px"
                [showBorders]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
                [selection]="{ mode: 'single' }" [scrolling]="{ mode: 'standard', preloadEnabled: true }"
                [autoNavigateToFocusedRow]="true" [selectedRowKeys]="[orderLineExtraForm.fromPurchaseOrderId]"
                (onSelectionChanged)="onPurchaseOrderSelectionChanged(e.component, $event)">

                <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
                <dxo-paging [enabled]="false"></dxo-paging>

                <dxi-column dataField="id" [visible]='false'></dxi-column>
                <dxi-column dataField="poNumber" caption="PO#" [width]="100" sortOrder="asc"></dxi-column>

                <dxi-column dataField="costCentreId" dataType="number" caption="Cost Code"
                  [calculateSortValue]="calculateCostCentreSortValue">
                  <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id">
                  </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="vendorId" dataType="number" caption="Vendor">
                  <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
                  </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="remainingAmount" caption="Remaining" [width]="90" format="#,##0"></dxi-column>
              </dx-data-grid>
            </div>
          </dx-drop-down-box>
        </div>
      </dxi-item>
    </dx-form>

    <b *ngIf="doNotAllowCosts">Cost Centre does not allow costs! Move this invoice to an appropriate Purchase Order</b>

    <dx-button *ngIf="!loading && !doNotAllowCosts" text="Go" class="me-2 mt-2" type="default" (onClick)="submit()">
    </dx-button>

    <dx-button *ngIf="!loading && !doNotAllowCosts && isAddingAsExtra" text="New Order" class="ms-2 mt-2"
      type="outlined" (onClick)="zeroOrderPopup()">
    </dx-button>

    <dx-button *ngIf="!loading && allowNonExtra && isAddingAsExtra && !doNotAllowCosts" text="Add as Original Budget"
      type="danger" (onClick)="isAddingAsExtra = false; popupHeight = 210" class="ms-3 mt-2">
    </dx-button>

    <dx-button *ngIf="!loading && allowNonExtra && !isAddingAsExtra && !doNotAllowCosts" text="Add as Extra"
      type="outlined" (onClick)="isAddingAsExtra = true; popupHeight = 350" class="ms-3 mt-2">
    </dx-button>
  </div>

  <dxi-toolbar-item [text]="title" location="before">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="{ icon: 'close', onClick: cancel }">
  </dxi-toolbar-item>

</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
