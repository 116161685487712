<div class="page form-height">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div class="page max-w-1000 m-auto">

    <dx-form id="form" *ngIf="!loading" [(formData)]="orderControlForm" [readOnly]="false" [showColonAfterLabel]="true"
      validationGroup="formData" labelLocation="left" [minColWidth]="500" [colCount]="1" [width]="800">

      <dxi-item itemType="group">
        <dxi-item dataField="globalNote" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="Global order note">
          </dxo-label>
        </dxi-item>

        <dxi-item dataField="returnEmail" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="Order queries note on order">
          </dxo-label>
        </dxi-item>

        <dxi-item dataField="purchaseOrderExtraNote" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="Extra note for order queries">
          </dxo-label>
        </dxi-item>

        <dxi-item cssClass="purchaseOrderExtraNoteButton" itemType="button" horizontalAlignment="right"
          [buttonOptions]="purchaseOrderExtraNoteOptions">
        </dxi-item>

        <dxi-item dataField="defaultEmailMessage" editorType="dxTextArea" [editorOptions]="{ height: 150 }">
          <dxo-label location="left" alignment="right" text="Default email message">
          </dxo-label>
        </dxi-item>

        <dxi-item dataField="ccEmailAddress" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="BCC all order emails to">
          </dxo-label>
        </dxi-item>

        <dxi-item dataField="invoiceThreshold" editorType="dxNumberBox">
          <dxo-label location="left" alignment="right" text="Invoices can overrun order by $">
          </dxo-label>
        </dxi-item>

        <dxi-item dataField="minPostingDate" editorType="dxDateBox">
          <dxo-label location="left" alignment="right" text="Minimum Invoice Date">
          </dxo-label>
        </dxi-item>

        <dxi-item dataField="submittedInvoiceFormatId" editorType="dxSelectBox"
          [editorOptions]="{ items: invoiceFormats, valueExpr: 'id', displayExpr: 'description' }">
          <dxo-label location="left" alignment="right" text="Submitted Invoice Format">
          </dxo-label>
        </dxi-item>

        <dxi-item dataField="isSubcontractorsCutOffStrict" editorType="dxCheckBox">
          <dxo-label location="left" alignment="right" text="Is subcontractors cut-off strict">
          </dxo-label>
        </dxi-item>

        <dxi-item dataField="canChangeDescriptionOrRateOnOrderLines" editorType="dxCheckBox">
          <dxo-label location="left" alignment="right" text="Users can change the description or rate on order lines">
          </dxo-label>
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group">
        <dxi-item dataField="modifiedUser" [editorOptions]="{ width: '250px', disabled: true }" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="Last modified by"></dxo-label>
        </dxi-item>

        <dxi-item dataField="modifiedDate" [editorOptions]="{ width: '120px', disabled: true }" editorType="dxDateBox">
          <dxo-label location="left" alignment="right" text="Last modified"></dxo-label>
        </dxi-item>
      </dxi-item>

    </dx-form>


    <dx-button *ngIf="!loading" text="Update" class="mt-1" type="default" (onClick)="update()">
    </dx-button>
    <dx-button *ngIf="!loading" text="Order Logo" class="mt-1 ms-2" type="default" stylingMode="outlined"
      (onClick)="openLoadFilePopup()">
    </dx-button>
    <dx-button *ngIf="invoiceAdmin && !loading && accountingSystemType === 1" text="Connect to Xero" class="mt-1 ms-2"
      type="success" (onClick)="connectToXero()">
    </dx-button>
    <dx-button *ngIf="invoiceAdmin && !loading && accountingSystemType === 1" text="Disconnect from Xero"
      class="mt-1 ms-2" type="success" (onClick)="disconnectPopup()">
    </dx-button>
    <dx-button *ngIf="invoiceAdmin && !loading && accountingSystemType === 2" text="Connect to QuickBooks"
      class="mt-1 ms-2" type="success" (onClick)="connectToQuickBooks()">
    </dx-button>
    <dx-button *ngIf="invoiceAdmin && !loading && accountingSystemType === 2" text="Disconnect from QuickBooks"
      class="mt-1 ms-2" type="success" (onClick)="disconnectPopup()">
    </dx-button>
    <dx-button *ngIf="invoiceAdmin && !loading && accountingSystemType === 3" text="Connect to MYOB" class="mt-1 ms-2"
      type="success" (onClick)="connectToMyob()">
    </dx-button>
    <dx-button *ngIf="invoiceAdmin && !loading && accountingSystemType === 3" text="Disconnect from MYOB"
      class="mt-1 ms-2" type="success" (onClick)="disconnectPopup()">
    </dx-button>
  </div>
</div>


<!-- Disconnect Accounting System popup -->
<dx-popup class="popup" [width]="450" [height]="330" [showTitle]="true" title="Disconnect" [dragEnabled]="false"
  [(visible)]="disconnectPopupVisible">

  <dx-data-grid id="disconnectGrid" class="m-auto" [height]="210" [dataSource]="accountingSystemTenants"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true">

    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="reshape">
    </dxo-editing>

    <dxi-column dataField="tenantName" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="hidden" [width]="100" [allowEditing]="true" dataType="boolean"> </dxi-column>

  </dx-data-grid>
</dx-popup>
