import { ReportMenuComponent } from './Reports/report-menu/report-menu.component';
import { ClaimsComponent } from './claims/claims.component';
import { OrderSendComponent } from './order-send/order-send.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';

// import { AuthGuard } from './services/auth-guard.service';
import { MsalGuard } from '@azure/msal-angular';
import { RefreshGuard } from './services/refresh-guard.service';
import { OrdersComponent } from './orders/orders.component';
import { MaintenanceMenuComponent } from './Maintenance/maintenance-menu/maintenance-menu.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { VendorsComponent } from './Maintenance/vendors/vendors.component';
import { MigrationToolsMenuComponent } from './migration-tools/migration-tools-menu/migration-tools-menu.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login/:comp', component: LoginComponent },
  { path: 'welcome', component: WelcomeComponent, canActivate: [RefreshGuard] }, // msal stopped working when this guarded
  { path: 'welcome/:comp', component: WelcomeComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'orders', component: OrdersComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'maintenance', component: MaintenanceMenuComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'order-send', component: OrderSendComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'vendors', component: VendorsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'invoice', component: InvoiceComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'claims', component: ClaimsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'reports', component: ReportMenuComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'migration-tools', component: MigrationToolsMenuComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'login', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
