import { AccountingSystemTenant } from '../../dtos/accounting-system-tenant';
import { UserService } from '../../services/felixApi/user.service';
import { AccountingSystemService } from '../../services/felixApi/accounting-system.service';
import { JobService } from '../../services/felixApi/job.service';
import { ClaimsService } from '../../services/felixApi/claims.service';
import { VariationService } from '../../services/felixApi/variation.service';
import { Component, OnInit, ViewChild, OnDestroy, OnChanges, Input } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../dtos/user';
import { ClientJob } from '../../dtos/client-job';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { IncomeInvoiceTypeEnum } from '../../dtos/income-invoice';
import CustomStore from 'devextreme/data/custom_store';
import { AccountingSystemIncomeInvoiceCompare } from '../../dtos/accounting-system-income-invoice-compare';
import { JobClaimDropdown } from '../../dtos/job-claim-dropdown';
import { JobVariationDropdown } from '../../dtos/job-variation-dropdown';

@Component({
  selector: 'js-income-invoice-query',
  templateUrl: './income-invoice-query.component.html',
  styleUrls: ['./income-invoice-query.component.scss']
})
export class IncomeInvoiceQueryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() incomeInvoiceQueryCounter: number;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  incomeInvoices: AccountingSystemIncomeInvoiceCompare[];
  dataSource: CustomStore;
  loading = true;
  gridHeight: number;
  invoiceAdmin: boolean;
  loadingAccounts: boolean;
  users: User[];
  accountingSystemType: number;
  contacts: ClientJob[];
  selectedContactId: number;
  externalContactId: string;
  accountingSystemTenantId: string;
  accountingCompanies: AccountingSystemTenant[];
  lastModifiedDate: Date;
  maxInvoices: number = 1000;
  splitByLines: boolean = false;
  invoiceCount: number;
  dropDownOptions: { width: number; minHeight: number; };
  autoConnectPopupVisible: boolean;
  allClaims: JobClaimDropdown[] = [];
  allJobVariations: JobVariationDropdown[] = [];

  incomeInvoiceTypes = Object.entries(IncomeInvoiceTypeEnum)
    .filter(e => !isNaN(e[0] as any))
    .map(e => ({
      value: IncomeInvoiceTypeEnum[e[0]],
      text: e[1]
    }));

  constructor(
    private globalService: GlobalService,
    private notiService: NotificationService,
    public gridService: GridService,
    private authService: AuthService,
    private accountingSystemService: AccountingSystemService,
    private userService: UserService,
    private jobService: JobService,
    private claimsService: ClaimsService,
    private variationService: VariationService
  ) {
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.dropDownOptions = { width: 300, minHeight: 400 };
    this.calculateExistsInTruthEngine = this.calculateExistsInTruthEngine.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.gridHeight = window.innerHeight - 130;
    this.getData();
  }

  getData() {
    this.subscriptions.push(
      this.accountingSystemService.getDataForInvoiceQuery().subscribe({
        next: () => {
          this.users = this.userService.users;
          this.accountingCompanies = this.accountingSystemService.accountingSystemTenantsList();
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);

          if (this.authService.isAdminOrSuper()
            || this.authService.areaPermissions.find(i => i.applicationArea === 'Invoices')?.permissionType === 'Admin') {
            this.invoiceAdmin = true;
          }

          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'invoiceID',
      loadMode: 'raw',
      load: () => this.incomeInvoices,
      update: async (key, values) => {
        const changedRec = this.incomeInvoices.find(i => i.invoiceID === key);
        if (values.importMessage) {
          changedRec.importMessage = values.importMessage;
        }
        if (values.importType) {
          changedRec.importType = values.importType;
        }
        if (values.importToJobNumber) {
          changedRec.importToJobNumber = values.importToJobNumber;
        }
        if (values.truthEngineIncomeInvoiceId) {
          changedRec.truthEngineIncomeInvoiceId = values.truthEngineIncomeInvoiceId;
        }
        if (values.importToClaimId) {
          changedRec.importToClaimId = values.importToClaimId;
        }
        if (values.importToVariationId) {
          changedRec.importToVariationId = values.importToVariationId;
        }
        if (values.createVariationNumber) {
          changedRec.createVariationNumber = values.createVariationNumber;
        }
        return changedRec;
      }
    });
  }

  importSelected() {
    return async () => {
      this.loading = true;
      this.grid.instance.saveEditData();
      const selectedRows = this.grid.instance.getSelectedRowsData();
      if (selectedRows.length > 0) {
        this.subscriptions.push(
          this.accountingSystemService.importSelectedIncomeInvoices(selectedRows).subscribe({
            next: (res: AccountingSystemIncomeInvoiceCompare[]) => {
              var importedCount = 0;
              var notImportedCount = 0;
              var errorCount = 0;
              for (const row of res) {
                this.dataSource.update(row.invoiceID, row);
                if (row.importMessage.startsWith('Imported')) {
                  importedCount++;
                }
                if (row.importMessage.startsWith('Not Imported')) {
                  notImportedCount++;
                }
                if (row.importMessage.startsWith('Error')) {
                  errorCount++;
                }
              }
              this.loading = false;
              if (importedCount > 0 && notImportedCount === 0 && errorCount === 0) {
                this.notiService.showSuccess(`All selected income invoices Imported successfully (${importedCount})`);
              }
              else {
                this.notiService.showWarning(`Imported - ${importedCount}, Not Imported - ${notImportedCount}, Errors - ${errorCount}`);
              }
            }, error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      }
      else {
        this.loading = false;
        this.notiService.showInfo('No income invoices selected');
      }
    };
  }

  getCount() {
    this.subscriptions.push(
      this.accountingSystemService.getAccountingSystemIncomeInvoicesCount(this.accountingSystemTenantId, this.externalContactId, this.lastModifiedDate).subscribe({
        next: (res: number) => {
          this.invoiceCount = res;
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Import Selected',
          onClick: this.importSelected()
        }
      });
  }

  refresh() {
    if (this.accountingSystemTenantId) {
      this.loading = true;
      this.grid.instance.deselectAll();
      this.subscriptions.push(
        this.accountingSystemService.getAccountingSystemIncomeInvoices(this.accountingSystemTenantId, this.externalContactId, this.lastModifiedDate, this.maxInvoices, this.splitByLines)
          .subscribe({
            next: (res: AccountingSystemIncomeInvoiceCompare[]) => {
              this.incomeInvoices = res;
              this.setUpDataSource();
              this.loading = false;
            }, error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          }));
      this.subscriptions.push(
        this.claimsService.getAllClaimLines()
          .subscribe({
            next: (res) => {
              this.allClaims = res.map(claim => ({
                id: claim.id,
                description: `${claim.description} ($${claim.amount.toFixed(2)})`,
                jobId: claim.jobId,
                jobNumber: claim.jobNumber
              }));
            }, error: (err) => {
              this.notiService.notify(err);
            }
          }));
      this.subscriptions.push(
        this.variationService.getAllVariations()
          .subscribe({
          next: (res) => {
            this.allJobVariations = res.map(variation => ({
              id: variation.id,
              description: `${variation.variationNumber} - ${variation.title} ($${variation.variationTotal})`,
              jobId: variation.jobId,
              jobNumber: variation.jobNumber
            }));
          }, error: (err) => {
            this.notiService.notify(err);
          }
        }));
    }
    else {
      this.notiService.showInfo('No Company selected');
    }
  }

  async onCompanySelectionChanged() {
    this.invoiceCount = null;
    this.contacts = null;
    if (this.accountingSystemTenantId) {
      this.subscriptions.push(
        this.jobService.getClientJobsByTenant(this.accountingSystemTenantId).subscribe({
          next: (res) => {
            const uniqueContacts = res
              .filter((contact, index, self) =>
                index === self.findIndex((c) => c.accountsId === contact.accountsId))
              .sort((a, b) => a.contractName.localeCompare(b.contractName));
            this.contacts = uniqueContacts;
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })

      );
    }
  }

  async onContactSelectionChanged(e) {
    this.invoiceCount = null;
    this.externalContactId = null;
    if (e.value) {
      const selectedObject = this.contacts.find(item => item.id === e.value);
      if (selectedObject) {
        this.externalContactId = selectedObject.accountsId;
      }
    }
  }

  calculateExistsInTruthEngine(data) {
    if (data.truthEngineIncomeInvoiceId && data.truthEngineIncomeInvoiceId !== '') {
      return true;
    }
    else {
      return false;
    }
  }

  async onLastModifiedChanged() {
    this.invoiceCount = null;
  }

  onLookupValueChanged(ea, e) {
    e.setValue(ea.value);
  }

  onDropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }

  async onEditorPreparing(e: any) {

    if (e.dataField === 'importToClaimId' || e.dataField === 'importToVariationId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'importType') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
    if (e.dataType === 'boolean') {
      e.editorOptions.dropDownOptions = { minWidth: 70 };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'importToVariationId') {
      const filteredVariations = this.allJobVariations.filter(variation => variation.jobNumber === e.row.data.importToJobNumber);
      e.editorOptions.dataSource = filteredVariations;
    }

    if (e.parentType === 'dataRow' && e.dataField === 'importToClaimId') {
      const filteredClaims = this.allClaims.filter(claim => claim.jobNumber === e.row.data.importToJobNumber);
      e.editorOptions.dataSource = filteredClaims;
    }
  }

  async getJobIdFromJobNumber(jobNumber: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.subscriptions.push(
        this.jobService.getJob(jobNumber).subscribe({
          next: (res) => {
            resolve(res.id);
          },
          error: (err) => {
            reject(err);
          }
        })
      );
    });
  }
}