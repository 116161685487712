import { CompanyService } from './company.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { MaintenanceService } from './maintenance.service';
import { HttpService } from '../http.service';
import { AuthApiService } from './auth-api.service';
import { UserService } from './user.service';
import { EstimatingService } from './estimating.service';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { AccountingSystemTenant } from '../../dtos/accounting-system-tenant';
import { AccountingSystemContact } from '../../dtos/accounting-system-contact';
import { AccountingSystemVendor } from '../../dtos/accounting-system-vendor';
import { AccountingSystemJob } from '../../dtos/accounting-system-job';
import { AccountingSystemAccountToCostCentre } from '../../dtos/accounting-system-account-to-cost-centre';
import { AccountingSystemTerm } from '../../dtos/accounting-system-term';
import { AccountingSystemProductService } from '../../dtos/accounting-system-product-service';
import { AccountingSystemAccount } from '../../dtos/accounting-system-account';
import { AccountingSystemCategory } from '../../dtos/accounting-system-category';

@Injectable({
  providedIn: 'root'
})
export class AccountingSystemService {

  constructor(
    private _http: HttpClient,
    private maintenanceService: MaintenanceService,
    private companyService: CompanyService,
    private globalService: GlobalService,
    private authApi: AuthApiService,
    private userService: UserService,
    private estimatingService: EstimatingService,
    private httpService: HttpService) { }

    private accountingSystemTenants: AccountingSystemTenant[];
    accountingSystemTenantsCompany: number;
    jobsForTenant: AccountingSystemJob[];
    jobsForTenantCompany: string;
    jobTenantId: string;
    accountingSystemTerms: AccountingSystemTerm[];
    accountingSystemTermsCompany: number;
    accountingSystemTermsTenantId: string;
    accountingSystemProductsAndServices: AccountingSystemProductService[];
    accountingSystemProductsAndServicesCompany: number;
    accountingSystemProductsAndServicesTenantId: string;
    accountingSystemProductsAndServicesType: string;
    accountingSystemCategories: AccountingSystemCategory[];
    accountingSystemCategoriesCompany: number;
    accountingSystemCategoriesTenantId: string;
    accountingSystemAccounts: AccountingSystemAccount[];
    accountingSystemAccountsCompany: number;
    accountingSystemAccountsTenantId: string;

  // Tenants
  getAccountingSystemTenants(): Observable<AccountingSystemTenant[]> {
    if (this.accountingSystemTenants && this.accountingSystemTenants.length
      && this.accountingSystemTenantsCompany === this.globalService.getCurrentCompany().id) {
      return of(this.accountingSystemTenants);
    }
    else {
      return this._http.get<AccountingSystemTenant[]>(this.globalService.getApiUrl() +
        '/accounting-system/tenants', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemTenants = res;
            this.accountingSystemTenantsCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  accountingSystemTenantsList(includeHidden: Boolean = false): AccountingSystemTenant[] {
    if (includeHidden) {
      return this.accountingSystemTenants;
    }
    else {
      return this.accountingSystemTenants.filter(i => !i.hidden);
    }
  }

  updateAccountingSystemTenant(tenantId: string, itm: any): Observable<AccountingSystemTenant> {
    const url = this.globalService.getApiUrl() + '/accounting-system/tenant/' + tenantId;
    return this._http.patch<AccountingSystemTenant>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteAccountingSystemTenant(tenantId: string): Observable<boolean> {
    return this._http.delete<boolean>(this.globalService.getApiUrl() +
      '/accounting-system/tenant/' + tenantId, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.accountingSystemTenants = null;
        }),
        catchError(this.handleError));
  }

  // Vendors
  getAccountingSystemVendors(tenantId: string): Observable<AccountingSystemVendor[]> {
    return this._http.get<AccountingSystemVendor[]>(this.globalService.getApiUrl() +
      '/accounting-system/vendors?tenantId=' + tenantId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  updateAccountingSystemVendors(tenantId: string): Observable<AccountingSystemVendor[]> {
    const url = this.globalService.getApiUrl() + '/accounting-system/vendors?tenantId=' + tenantId;
    return this._http.post<AccountingSystemVendor[]>(url, JSON.stringify({}), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  // Contacts
  getAccountingSystemContacts(tenantId: string): Observable<AccountingSystemContact[]> {
    return this._http.get<AccountingSystemContact[]>(this.globalService.getApiUrl() +
      '/accounting-system/contacts?tenantId=' + tenantId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  updateAccountingSystemContacts(tenantId: string): Observable<AccountingSystemContact[]> {
    const url = this.globalService.getApiUrl() + '/accounting-system/contacts?tenantId=' + tenantId;
    return this._http.post<AccountingSystemContact[]>(url, JSON.stringify({}), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  // Invoices  
  getDataForInvoiceQuery(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [
        this.companyService.getCompanyConfigs(),
        this.authApi.getAreaPermissions(),
        this.getAccountingSystemTenants(),
        this.userService.getCurrCompUsers(true)
      ]
    )
      .pipe(
        map(([configs]) => { return configs; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  getAccountingSystemInvoices(tenantId: string, jobId: string, vendorId: string, lastModified: Date, maxInvoices: number) {
    let url = this.globalService.getApiUrl() + '/accounting-system/invoices?tenantId=' + tenantId;
    if (jobId) {
      url += '&jobIds=' + jobId;
    }
    if (vendorId) {
      url += '&vendorIds=' + vendorId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    if (maxInvoices) {
      url += '&maxInvoices=' + maxInvoices;
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  getAccountingSystemInvoicesCount(tenantId: string, vendorId: string, lastModified: Date) {
    let url = this.globalService.getApiUrl() + '/accounting-system/invoices-count?tenantId=' + tenantId;
    if (vendorId) {
      url += '&vendorIds=' + vendorId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  // Income Invoices
  getAccountingSystemIncomeInvoices(tenantId: string, contactId: string, lastModified: Date, maxInvoices: number, splitByLines: boolean) {
    let url = this.globalService.getApiUrl() + '/accounting-system/income-invoices-compare?tenantId=' + tenantId;
    if (contactId) {
      url += '&contactIds=' + contactId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    if (maxInvoices) {
      url += '&maxInvoices=' + maxInvoices;
    }
    if (splitByLines) {
      url += '&splitByLines=true';
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  getAccountingSystemIncomeInvoicesCount(tenantId: string, contactId: string, lastModified: Date) {
    let url = this.globalService.getApiUrl() + '/accounting-system/income-invoices-count?tenantId=' + tenantId;
    if (contactId) {
      url += '&contactIds=' + contactId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  importSelectedIncomeInvoices(selectedRows: any[]) {
    const url = this.globalService.getApiUrl() + '/accounting-system/income-invoices-import';
    return this._http.post(url, JSON.stringify(selectedRows), this.httpService.getHttpOptions());
  }

  submitInvoices(vendorGroupId: number, includeExpiredInsurances: boolean, selectedIds: any) {
    let url = this.globalService.getApiUrl() + '/accounting-system/submit-invoices?includeExpiredInsurances=' + includeExpiredInsurances;
    if (vendorGroupId) {
      url += '&vendorGroupId=' + vendorGroupId;
    }
    return this._http.post(url, JSON.stringify(selectedIds), this.httpService.getHttpOptions());
  }

  getJobsForTenant(useCache: boolean = true, tenantId: string): Observable<AccountingSystemJob[]> {
    if (useCache && this.jobsForTenant && this.jobsForTenant.length
      && this.jobsForTenantCompany === this.globalService.getCurrentCompanyId()
      && this.jobTenantId === tenantId) {
      return of(this.jobsForTenant);
    } else {
      let url = this.globalService.getApiUrl() + '/accounting-system/jobs';

      if (tenantId && tenantId !== '') {
        url += '?tenantId=' + tenantId;
      }
      return this._http.post<AccountingSystemJob[]>(url, null, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobsForTenant = res;
          this.jobsForTenantCompany = this.globalService.getCurrentCompanyId();
          this.jobTenantId = tenantId;
        }),
        catchError(this.handleError));
    }
  }

  getTrackingCategoryJobsForTenant(useCache: boolean = true, tenantId: string): Observable<AccountingSystemJob[]> {
    if (useCache && this.jobsForTenant && this.jobsForTenant.length
      && this.jobsForTenantCompany === this.globalService.getCurrentCompanyId()
      && this.jobTenantId === tenantId) {
      return of(this.jobsForTenant);
    } else {
      let url = this.globalService.getApiUrl() + '/accounting-system/tracking-category-jobs';

      if (tenantId && tenantId !== '') {
        url += '?tenantId=' + tenantId;
      }
      return this._http.get<AccountingSystemJob[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobsForTenant = res;
          this.jobsForTenantCompany = this.globalService.getCurrentCompanyId();
          this.jobTenantId = tenantId;
        }),
        catchError(this.handleError));
    }
  }

  getDataForPurchaseOrderQuery(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [
        this.companyService.getCompanyConfigs(),
        this.authApi.getAreaPermissions(),
        this.getAccountingSystemTenants(),
        this.userService.getCurrCompUsers(true),
        this.estimatingService.getPriceFileItemGroups(true)
      ]
    )
      .pipe(
        map(([configs]) => { return configs; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  getAccountingSystemPurchaseOrders(tenantId: string, jobId: string, vendorId: string, lastModified: Date, maxPurchaseOrders: number) {
    let url = this.globalService.getApiUrl() + '/accounting-system/purchase-orders?tenantId=' + tenantId;
    if (jobId) {
      url += '&jobIds=' + jobId;
    }
    if (vendorId) {
      url += '&vendorIds=' + vendorId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    if (maxPurchaseOrders) {
      url += '&maxPurchaseOrders=' + maxPurchaseOrders;
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  getAccountingSystemPurchaseOrdersCompare(tenantId: string, jobId: number, dateFrom: Date, accountingSystemjobId: string) {
    let url = this.globalService.getApiUrl() + '/accounting-system/purchase-orders-compare'
    url += '?tenantId=' + tenantId;
    url += '&jobId=' + jobId;
    url += '&dateFrom=' + dateFrom.toISOString();
    url += '&accountingSystemjobId=' + accountingSystemjobId;
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  importSelectedPurchaseOrders(jobId: number, selectedRows: any[]) {
    let url = this.globalService.getApiUrl() + '/accounting-system/purchase-orders-import';
    url += '?jobId=' + jobId;
    return this._http.post(url, JSON.stringify(selectedRows), this.httpService.getHttpOptions());
  }

  // Accounting System Account to Cost Centre
  getAccountingSystemAccountToCostCentres(tenantId: string): Observable<AccountingSystemAccountToCostCentre[]> {
    const url = this.globalService.getApiUrl() + '/accounting-system/account-to-cost-centre?tenantId=' + tenantId;
    return this._http.get<AccountingSystemAccountToCostCentre[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addAccountingSystemAccountToCostCentre(accountId: string, costCentreId: string, accountingSystemTenantId: string) {
    const url = this.globalService.getApiUrl() + '/accounting-system/account-to-cost-centre';
    const accountToCostCentre = {
      accountId: accountId,
      costCentreId: costCentreId,
      tenantId: accountingSystemTenantId
    };
    return this._http.post<AccountingSystemAccountToCostCentre>(url, JSON.stringify(accountToCostCentre), this.httpService.getHttpOptions());
  }

  updateAccountingSystemAccountToCostCentre(id: number, costCentreId: string) {
    const url = this.globalService.getApiUrl() + '/accounting-system/account-to-cost-centre/' + id;
    const updatedRecord = {
      costCentreId: costCentreId,
      hasCostCentreId: true
    };
    return this._http.patch<AccountingSystemAccountToCostCentre>(url, JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
  }

  deleteAccountingSystemAccountToCostCentre(id: number) {
    const url = this.globalService.getApiUrl() + '/accounting-system/account-to-cost-centre/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  // Terms
  getAccountingSystemTerms(tenantId: string): Observable<AccountingSystemTerm[]> {
    if (this.accountingSystemTerms
      && this.accountingSystemTermsTenantId === tenantId
      && this.accountingSystemTermsCompany === this.globalService.getCurrentCompany().id) {
      return of(this.accountingSystemTerms);
    } else {
      return this._http.get<AccountingSystemTerm[]>(this.globalService.getApiUrl() +
        '/accounting-system/terms?tenantId=' + tenantId, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemTerms = res;
            this.accountingSystemTermsCompany = this.globalService.getCurrentCompany().id;
            this.accountingSystemTermsTenantId = tenantId;
          }),
          catchError(this.handleError));
    }
  }

  getAccountingSystemDataServices(tenantId: string, getTerms: boolean): Observable<AccountingSystemProductService[]> {
    return forkJoin(
      [
        this.getAccountingSystemProductsAndServices(tenantId, "Service"),
        getTerms ? this.getAccountingSystemTerms(tenantId) : of([])
      ]
    )
      .pipe(
        map(([result]) => { return result; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  // Accounts
  getAccountingSystemAccounts(tenantId: string): Observable<AccountingSystemAccount[]> {
    if (this.accountingSystemAccounts
      && this.accountingSystemAccountsTenantId === tenantId
      && this.accountingSystemAccountsCompany === this.globalService.getCurrentCompany().id) {
      return of(this.accountingSystemAccounts);
    } else {
      return this._http.post<AccountingSystemAccount[]>(this.globalService.getApiUrl() +
        '/accounting-system/accounts?tenantId=' + tenantId, null, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemAccounts = res;
            this.accountingSystemAccountsCompany = this.globalService.getCurrentCompany().id;
            this.accountingSystemAccountsTenantId = tenantId;
          }),
          catchError(this.handleError));
    }
  }

  // Tracking categories
  getAccountingSystemCategories(tenantId: string): Observable<AccountingSystemCategory[]> {
    if (this.accountingSystemCategories
      && this.accountingSystemCategoriesTenantId === tenantId
      && this.accountingSystemCategoriesCompany === this.globalService.getCurrentCompany().id) {
      return of(this.accountingSystemCategories);
    } else {
      return this._http.get<AccountingSystemCategory[]>(this.globalService.getApiUrl() +
        '/accounting-system/tracking-categories?tenantId=' + tenantId, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemCategories = res;
            this.accountingSystemCategoriesCompany = this.globalService.getCurrentCompany().id;
            this.accountingSystemCategoriesTenantId = tenantId;
          }),
          catchError(this.handleError));
    }
  }

  getAccountingSystemData(tenantId: string): Observable<AccountingSystemAccount[]> {
    return forkJoin(
      [
        this.getAccountingSystemAccounts(tenantId),
        this.getAccountingSystemCategories(tenantId)
      ]
    )
      .pipe(
        map(([result]) => { return result; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  getAccountingSystemProductsAndServices(tenantId: string, type: string): Observable<AccountingSystemProductService[]> {
    if (this.accountingSystemProductsAndServices
      && this.accountingSystemProductsAndServicesTenantId === tenantId
      && this.accountingSystemProductsAndServicesCompany === this.globalService.getCurrentCompany().id
      && this.accountingSystemProductsAndServicesType === type) {
      return of(this.accountingSystemProductsAndServices);
    } else {
      var typeSearch = "";
      if (type.length > 0) {
        typeSearch = '&type=' + type;
      }
      return this._http.get<AccountingSystemProductService[]>(this.globalService.getApiUrl() +
        '/accounting-system/product-and-services?tenantId=' + tenantId + typeSearch, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemProductsAndServices = res;
            this.accountingSystemProductsAndServicesCompany = this.globalService.getCurrentCompany().id;
            this.accountingSystemProductsAndServicesTenantId = tenantId;
            this.accountingSystemProductsAndServicesType = type;
          }),
          catchError(this.handleError));
    }
  }

  jsonReplacer(key, value) {
    if (this[key] instanceof Date) {
      return this[key].toDateString();
    }
    return value;
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
