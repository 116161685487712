<div class="page">

  <dx-data-grid *ngIf="!loadingData && !loading" #approvedGrid id="approvedGrid" columnResizingMode="widget" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true" columnMinWidth="10"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'toolbarTemplate2')"
    [(selectedRowKeys)]="selectedRecords"  (onExporting)="gridService.onExporting($event, 'invoices-approved')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <!-- <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping> -->
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoices-approved" [savingTimeout]="500">
    </dxo-state-storing>

    <dxo-editing mode="row" [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="invoiceAdmin"
      [useIcons]="true">
    </dxo-editing>

    <dxo-selection selectAllMode='allPages' showCheckBoxesMode='always' mode="multiple">
    </dxo-selection>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [setCellValue]="setVendorCellValue"
      [minWidth]="125" [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="orderNumber" width="130" [setCellValue]="setOrderNumberCellValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="invoiceNumber" width="120">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="invoiceDate" dataType="date" width="85" format="d/M/yy" alignment="center">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" width="85" format="d/M/yy" alignment="center">
    </dxi-column>

    <dxi-column dataField="totalIncGST" dataType="number" width="106" [format]="{ type: 'fixedPoint', precision: 2 }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="totalGST" dataType="number" width="90" [format]="{ type: 'fixedPoint', precision: 2 }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="jobId" caption="Job Address" dataType="number" [allowEditing]="false">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobAddressString" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreId" caption="Cost Centre" dataType="string" [allowEditing]="false">
      <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="purchaseOrderId" dataType="number" width="120" [visible]="false">
    </dxi-column>

    <dxi-column dataField="vendorGroup" caption="Vendor Group" [visible]="false" dataType="string" width="120"
      [calculateCellValue]="calcVendorGroup" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="glAccountType" caption="Vendor Type" [visible]="false" dataType="string" width="120"
      [calculateCellValue]="calcGlAccountType" [allowEditing]="false">
    </dxi-column>


    <dxi-column dataField="authorisedByUserId" caption="Approved by" dataType="number" width="120"
      [allowEditing]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="description" caption="Comment">
    </dxi-column>

    <dxi-column dataField="connectionStatus" caption="Status" dataType="string" [allowEditing]="false"
      [calculateCellValue]='calculateConnectionStatus'>
    </dxi-column>

    <dxi-column type="buttons" fixed="true" [width]="160">
      <dxi-button *ngIf="invoiceAdmin" name="delete"></dxi-button>
      <dxi-button icon="doc" [onClick]="downloadAttachment" hint='Download Invoice'></dxi-button>
      <dxi-button icon="undo" [onClick]="markOnHold" hint='Mark On Hold'></dxi-button>
      <dxi-button icon="download" [onClick]="openPO" hint='Download PO'></dxi-button>
      <dxi-button icon="import" [onClick]="openCostCentre" hint='Go to Construction Orders'></dxi-button>
    </dxi-column>


    <!-- summary totals -->
    <dxo-summary>
      <dxi-total-item column="vendorId" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="totalExGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalIncGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

    <!-- Templates -->
    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-select-box id="selectVendorGroup" [dataSource]="vendorFilterGroups" displayExpr="description" valueExpr="id"
          placeholder="All Groups" [width]="286" [(value)]='selectedVendorGroupId'
          (onValueChanged)='setUpDataSource(true)'>
        </dx-select-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <dx-check-box text="Include Vendors with Expired Insurances" [(value)]='includeExpiredInsurances'>
        </dx-check-box>
      </div>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }" [(visible)]="loadingFile"
  [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
