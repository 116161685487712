<div class="modal-header">
  <h3 class="modal-title">ALL Jobs: Change orders to a new vendor</h3>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <b>Update orders for ALL ACTIVE JOBS where no invoices have been received and the order still has accrual.</b>
  <br>

  <div class="dx-field">
    <div class="dx-field-label">Change vendor:</div>
    <div class="dx-field-value">
      <dx-select-box [(ngModel)]="oldVendorId" [dataSource]="vendors" valueExpr="id" displayExpr="vendorName"
        [showClearButton]="true" [searchEnabled]="true">
      </dx-select-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">To new vendor:</div>
    <div class="dx-field-value">
      <dx-select-box [(ngModel)]="newVendorId" [dataSource]="vendors" valueExpr="id" displayExpr="vendorName"
        [searchEnabled]="true">
      </dx-select-box>
    </div>
  </div>

  <js-extra-variance-select [varianceCodeId]=varianceCodeId [varianceReason]="varianceReason"
    (varianceCodeIdSelected)="varianceCodeId = $event" (varianceReasonSelected)="varianceReason = $event">
  </js-extra-variance-select>

  <div class="dx-field">
    <div class="dx-field-label">Date to price items:</div>
    <div class="inlineFlex">
      <dx-date-box [(ngModel)]="costDate" [width]="150"></dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <dx-check-box [(ngModel)]="cancelAndRecreateCallUp"
      text="Cancel Call-Up if it exists and create new one for new purchase order">
    </dx-check-box>
  </div>

  <br>

  <dx-data-grid [dataSource]="purchaseOrders" keyExpr="id" [(selectedRowKeys)]="selectedRows" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [allowColumnReordering]="false" [remoteOperations]="false"
    [allowColumnResizing]="false" [columnAutoWidth]="false" [height]="gridHeight"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'global-change-vendor')">

    <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple"></dxo-selection>
    <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-export [enabled]="true"></dxo-export>

    <dxi-column dataField="jobId" caption="Job" dataType="number" [allowEditing]="false" [width]="120" sortOrder="asc">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="siteAddress" dataType="string" [calculateCellValue]='calculateSiteAddress'>
    </dxi-column>

    <dxi-column dataField="poNumber" caption="PO Number" sortOrder="asc" [width]="120"> </dxi-column>

    <dxi-column dataField="currentActivityDesc" caption="Activity" [calculateCellValue]='calculateActivityDesc'
      [width]="180">
    </dxi-column>

    <dxi-column dataField="siteManager" [caption]="calculateSiteManagerTitle()" dataType="string" width="180"
      [calculateCellValue]='calculateSiteManager'>
    </dxi-column>

  </dx-data-grid>

  <div *ngIf="isUpdating">Purchase orders to change: {{purchaseOrderCount}}</div>
  <div *ngIf="isUpdating">Please wait...</div>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="isUpdating" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
