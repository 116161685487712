import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Invoice, RejectInvoiceForm } from '../../dtos/invoice';
import { InvoiceService } from '../../services/felixApi/invoice.service';
import { JobService } from '../../services/felixApi/job.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-reject-invoice',
  templateUrl: './reject-invoice.component.html',
  styleUrls: ['./reject-invoice.component.scss']
})
export class RejectInvoiceComponent implements OnInit, OnDestroy {
  @Input() invoice: Invoice;

  subscriptions: Subscription[] = [];
  loading: boolean;
  rejectInvoiceForm: RejectInvoiceForm = new RejectInvoiceForm();
  invoiceDateOptions: object;
  updateButtonOptions = {
    text: 'Reject',
    type: 'default',
    stylingMode: 'contained',
    useSubmitBehavior: true
  };

  constructor(
    private _activeModal: NgbActiveModal,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private notiService: NotificationService,
    private invoiceService: InvoiceService,
  ) {
    this.invoiceDateOptions = {
      type: 'date',
      useMaskBehavior: true,
      disabled: true
    };
  }

  ngOnInit() {
    const vendor = this.maintenanceService.allVendors.find(i => i.id === this.invoice.vendorId);
    const job = this.jobService.jobs.find(i => i.id === this.invoice.jobId);

    this.rejectInvoiceForm.jobNumber = job?.jobNumber;
    this.rejectInvoiceForm.jobAddressString = job?.jobAddressString;
    this.rejectInvoiceForm.vendorName = vendor?.vendorName;
    this.rejectInvoiceForm.invoiceNumber = this.invoice.invoiceNumber;
    this.rejectInvoiceForm.invoiceDate = this.invoice.invoiceDate;
    this.rejectInvoiceForm.sendRejectToEmail = vendor.email;
    this.rejectInvoiceForm.emailSubject = `Invoice Rejected - Invoice #${this.invoice.invoiceNumber} - Job #${job?.jobNumber} - ${job?.jobAddressString}`;
    this.rejectInvoiceForm.comment = '';
    this.rejectInvoiceForm.ccToSelf = true;

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cancel() {
    this._activeModal.dismiss();
  }

  reject() {
    this.loading = true;
    this.subscriptions.push(
      this.invoiceService.rejectInvoice(this.invoice.id.toString(),
        {
          sendRejectToEmail: this.rejectInvoiceForm.sendRejectToEmail,
          comment: this.rejectInvoiceForm.comment,
          ccToSelf: this.rejectInvoiceForm.ccToSelf,
          emailSubject: this.rejectInvoiceForm.emailSubject
        })
        .subscribe({
          next: () => {
            this.notiService.showSuccess('Invoice Rejected');
            this._activeModal.close();
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
    );
  }
}
