import { UserTypeEnum } from './../../dtos/user-type.enum';
import { UserService } from './../../services/felixApi/user.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { formatDate } from 'devextreme/localization';
import { DivisionService } from './../../services/felixApi/division.service';
import { AccountingSystemVendor } from '../../dtos/accounting-system-vendor';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotificationService } from '../../services/notification.service';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { Vendor } from '../../dtos/vendor';
import { AccountingSystemService } from '../../services/felixApi/accounting-system.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GlAccountType } from '../../dtos/gl-account-type';
import { VendorGroup } from '../../dtos/vendor-group';
import { Division } from '../../dtos/division';
import CustomStore from 'devextreme/data/custom_store';
import { PriceFileItem } from '../../dtos/price-file-item';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { User } from '../../dtos/user';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'js-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = false;
  value: any[] = [];
  vendors: Vendor[] = [];
  vendorsDataSource: DataSource;
  gridHeight: number;
  accountingSystemVendors: AccountingSystemVendor[];
  glAccountTypes: GlAccountType[];
  vendorGroups: VendorGroup[];
  accountingSystemType: number;
  accountingSystemTenantName: string;
  accountingSystemTenantNames: Division[] = [];
  accountingSystemTenantId: string;
  vendorPlaceHolder: string;
  autoConnectPopupVisible = false;
  loadingAccounts = false;
  costCentres: PriceFileItem[];
  users: User[];
  usersDataSource: DataSource;

  constructor(
    private accountingSystemService: AccountingSystemService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private divisionService: DivisionService,
    private invoiceService: InvoiceService,
    private estimatingService: EstimatingService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.gridHeight = window.innerHeight - 70;
    this.getData();
  }

  onEditorPreparing(e) {
    if (e.dataField === 'vendorPayableIds' || e.dataField === 'accountId' || e.dataField === 'invoiceDefaultCostCentreId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'glAccountTypeId' || e.dataField === 'vendorGroupId' || e.dataField === 'vendorInvoiceApproverUserIds') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
    if (e.dataType === 'boolean') {
      e.editorOptions.dropDownOptions = { minWidth: 70 };
    }
  }

  getData() {
    this.subscriptions.push(
      this.invoiceService.getInvoicesData(true).subscribe({
        next: () => {
          this.costCentres = this.estimatingService.costCentres;
          this.users = this.userService.users.filter(u => u.userTypeId === UserTypeEnum.Admin || u.userTypeId === UserTypeEnum.Office);
          this.updateUsersDatasource();
          this.divisionService.divisions.forEach(division => {
            if (division.accountingSystemTenantId
              && !this.accountingSystemTenantNames.find(i => i.accountingSystemTenantId === division.accountingSystemTenantId)) {
              this.accountingSystemTenantNames.push(division);
            }
          });
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
          if (this.accountingSystemTenantNames?.length === 1) {
            this.accountingSystemTenantName = this.accountingSystemTenantNames[0].accountingSystemTenantName;
            this.selectOptionChanged();
          } else if (this.accountingSystemTenantNames?.length) {
            this.vendorPlaceHolder = 'Select Accounting Company...';
          } else {
            this.vendorPlaceHolder = 'Please connect Divisions to Accounts via Maintenance';
          }
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  refresh() {
    // this.informationTypeService.clearCaches();
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.getVendorsWithPayables(false, this.accountingSystemTenantId).subscribe({
              next: (res) => {
                this.vendors = res;
                this.updateVendorsDatasource()
                this.glAccountTypes = this.maintenanceService.glAccountTypes;
                this.vendorGroups = this.maintenanceService.vendorGroups;
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.addVendor(values).subscribe({
              next: (res) => {
                this.vendors.push(res);
                this.updateVendorsDatasource()
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.updateVendorForTenant(encodeURIComponent(key), values, this.accountingSystemTenantId).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.deleteVendor(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      }
    });
  }

  updateVendorsDatasource() {
    this.vendorsDataSource = new DataSource({
      store: this.vendors,
      paginate: true,
      pageSize: 50
    });
  }

  updateUsersDatasource() {
    this.usersDataSource = new DataSource({
      store: this.users,
      paginate: true,
      pageSize: 50
    });
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;

    var updateButtonText = '';
    if (this.accountingSystemType === 1) {
      updateButtonText = 'Update Xero Vendors';
    }
    if (this.accountingSystemType === 2) {
      updateButtonText = 'Update QuickBooks Vendors';
    }
    if (this.accountingSystemType === 3) {
      updateButtonText = 'Update MYOB Vendors';
    }

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          text: 'Auto-Connect',
          onClick: this.autoConnect.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });

      if (updateButtonText !== '') {
        toolbarItems.unshift(
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              type: 'default',
              text: updateButtonText,
              onClick: this.updateVendorList.bind(this)
            }
          });
      }
  }

  ngOnDestroy() {
    this.maintenanceService.vendorsForTenant = null;
    this.maintenanceService.allVendors = [];
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  calculateFilterExpression(filterValue, selectedFilterOperation, target) {
    if (target === 'search' && typeof (filterValue) === 'string') {
      return [(this as any).dataField, 'contains', filterValue];
    }
    return function (data) {
      return (data.AssignedEmployee || []).indexOf(filterValue) !== -1;
    };
  }

  cellTemplate(container, options) {
    const noBreakSpace = '\u00A0',
      text = (options.value || []).map(element => {
        return options.column.lookup.calculateCellValue(element);
      }).join(', ');
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  connectToAccountingSystem(tenantId: string) {
    // get the vendors from AccountingSystem to do the connection
    this.loading = true;

    this.subscriptions.push(
      this.accountingSystemService.getAccountingSystemVendors(tenantId)
        .subscribe({
          next: (accountingSystemVendors) => {
            this.accountingSystemVendors = accountingSystemVendors;
            this.loading = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onLookupValueChanged(ea, e) {
    e.setValue(ea.value);
  }

  selectOptionChanged() {
    this.dataSource = null;
    this.accountingSystemTenantId = this.accountingSystemTenantNames
      .find(i => i.accountingSystemTenantName === this.accountingSystemTenantName)?.accountingSystemTenantId;

    this.connectToAccountingSystem(this.accountingSystemTenantId);
  }

  autoConnect() {
    // auto connect any that it can match the names
    if (this.accountingSystemTenantId && this.accountingSystemTenantId !== '') {
      this.autoConnectPopupVisible = true;
    } else {
      this.notiService.showInfo('Select a company');
    }
  }

  autoConnectGo() {
    // auto connect any that it can match the names
    this.autoConnectPopupVisible = false;
    this.loadingAccounts = true;

    this.subscriptions.push(
      this.maintenanceService.autoConnectVendors(this.accountingSystemTenantId).subscribe({
        next: () => {
          this.loadingAccounts = false;
          this.refresh();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loadingAccounts = false;
        }
      })
    );
  }

  clearStatePersistance() {
    this.loading = true;
    localStorage.removeItem('orderVendorsGrid');
    setTimeout(() => {
      this.loading = false;
    }, 300); // wait
  }

  setIncomeProtectionExpiry(newData, value) {
    // must use formatDate as we get ISO format and lose hours so date can be yesterday
    if (value) {
      newData.incomeProtectionExpiry = formatDate(value, 'yyyy-MM-dd');
    } else {
      newData.incomeProtectionExpiry = null;
    }
  }

  setPublicLiabilityExpiry(newData, value) {
    // must use formatDate as we get ISO format and lose hours so date can be yesterday
    if (value) {
      newData.publicLiabilityExpiry = formatDate(value, 'yyyy-MM-dd');
    } else {
      newData.publicLiabilityExpiry = null;
    }
  }

  setWorkCoverExpiry(newData, value) {
    // must use formatDate as we get ISO format and lose hours so date can be yesterday
    if (value) {
      newData.workCoverExpiry = formatDate(value, 'yyyy-MM-dd');
    } else {
      newData.workCoverExpiry = null;
    }
  }

  onInitNewRow = (e) => {
    e.data.isActive = true;
    e.data.canAcceptInvoices = true;
    e.data.isCallUpShowSupervisor = true;
    e.data.canAnyVendorInvoice = false;
    e.data.markOrdersSentWhenRaised = false;
    e.data.isActive = true;
    e.data.canAttachOrdersToCallUps = true;
  }

  updateVendorList() {
    if (this.accountingSystemTenantId && this.accountingSystemTenantId !== '') {
      this.loadingAccounts = true;
      this.subscriptions.push(
        this.accountingSystemService.updateAccountingSystemVendors(this.accountingSystemTenantId).subscribe({
          next: (res) => {
            this.accountingSystemVendors = res
            this.loadingAccounts = false;
          }, error: (err) => {
            this.notiService.notify(err);
            this.loadingAccounts = false;
          }
        })
      );
    } else {
      this.notiService.showInfo('Select a company');
    }
  }
}
