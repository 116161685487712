import { AccountingSystemService } from './../../services/felixApi/accounting-system.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { GridService } from '../../services/grid.service';
import { DivisionService } from '../../services/felixApi/division.service';
import { NotificationService } from '../../services/notification.service';
import { CompanyService } from '../../services/felixApi/company.service';
import { AuthService } from '../../services/auth.service';
import { AccountingSystemCategory } from '../../dtos/accounting-system-category';
import { AccountingSystemTerm } from '../../dtos/accounting-system-term';
import { AccountingSystemAccount } from '../../dtos/accounting-system-account';
import { AccountingSystemTenant } from '../../dtos/accounting-system-tenant';
import CustomStore from 'devextreme/data/custom_store';
import { AccountingSystemProductService } from '../../dtos/accounting-system-product-service';

@Component({
  selector: 'js-divisions',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.scss']
})
export class DivisionsComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = true;
  gridHeight: number;
  invoiceAdmin: boolean;
  accountingSystemCategories: AccountingSystemCategory[];
  accountingSystemTerms: AccountingSystemTerm[];
  accountsLoaded: boolean;
  accountingSystemAccounts: AccountingSystemAccount[];
  accountingSystemServices: AccountingSystemProductService[];
  accountingSystemTenants: AccountingSystemTenant[];
  loadingAccounts: boolean;
  accountCategoriesLoaded: boolean;
  accountServicesLoaded: boolean;
  accountingSystemType: number;
  tenantId: string;

  constructor(
    private divisionService: DivisionService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    public gridService: GridService,
    private compService: CompanyService,
    private authService: AuthService,
    private accountingSystemService: AccountingSystemService,
    private maintenanceService: MaintenanceService
  ) {
    this.setCompanyCellValue = this.setCompanyCellValue.bind(this);
  }

  ngOnInit(): void {
    this.gridHeight = window.innerHeight - 130;
    this.getCompanyConfigurations();
    this.connectToAccountingSystemTenants();
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.compService.getCompanyConfigurations().subscribe({
        next: () => {
          if (this.authService.isAdminOrSuper()
            || this.authService.areaPermissions.find(i => i.applicationArea === 'Invoices')?.permissionType === 'Admin') {
            this.invoiceAdmin = true;
          }
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
          this.setUpDataSource();
          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  refresh() {
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.divisionService.getDivisions(false).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.divisionService.updateDivision(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  onEditingStart(e) {
    this.getAccountingSystemLookups(e.data.accountingSystemTenantId);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  connectToAccountingSystemTenants() {
    // Get the accounts from Accounting System to do the connection
    this.loading = true;

    this.subscriptions.push(
      this.accountingSystemService.getAccountingSystemTenants()
        .subscribe({
          next: () => {
            this.accountingSystemTenants = this.accountingSystemService.accountingSystemTenantsList();
            this.accountsLoaded = true;
            setTimeout(() => {
              this.loading = false;
            }, 250); // wait so grid loading will work in case we have cached data
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  connectToAccountingSystemCategories(tenantId: string) {
    // Get the accounts from Accounting System (Xero)
    this.accountingSystemAccounts = [];
    this.accountingSystemCategories = [{ trackingCategoryID: '', name: 'Not Selected' }];
    this.accountCategoriesLoaded = false;

    if (this.accountsLoaded && tenantId && tenantId.trim() !== '') {
      this.loadingAccounts = true;
      this.subscriptions.push(
        this.accountingSystemService.getAccountingSystemData(tenantId)
          .subscribe({
            next: (accountingSystemAccounts) => {
              this.accountingSystemAccounts = accountingSystemAccounts;
              this.accountingSystemCategories = this.accountingSystemCategories.concat(this.accountingSystemService.accountingSystemCategories);
              this.accountCategoriesLoaded = true;
              this.loadingAccounts = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingAccounts = false;
            }
          })
      );
    }
  }

  connectToAccountingSystemServices(tenantId: string, getTerms: boolean) {
    // Get the service codes from Accounting System (Quickbooks/Business Central)
    this.accountingSystemServices = [];
    this.accountServicesLoaded = false;

    if (this.accountsLoaded && tenantId && tenantId.trim() !== '') {
      this.loadingAccounts = true;
      this.subscriptions.push(
        this.accountingSystemService.getAccountingSystemDataServices(tenantId, getTerms)
          .subscribe({
            next: (accountingSystemServices) => {
              this.accountingSystemServices = accountingSystemServices;
              this.accountingSystemTerms = this.accountingSystemService.accountingSystemTerms;
              this.accountServicesLoaded = true;
              this.loadingAccounts = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingAccounts = false;
            }
          })
      );
    }
  }

  connectToAccountingSystemAccounts(tenantId: string) {
    // Get the accounts from Accounting System (MYOB)
    this.accountingSystemAccounts = [];

    if (this.accountsLoaded && tenantId && tenantId.trim() !== '') {
      this.loadingAccounts = true;
      this.subscriptions.push(
        this.accountingSystemService.getAccountingSystemAccounts(tenantId)
          .subscribe({
            next: (accountingSystemAccounts) => {
              this.accountingSystemAccounts = accountingSystemAccounts;
              this.accountingSystemTerms = this.accountingSystemService.accountingSystemTerms;
              this.loadingAccounts = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingAccounts = false;
            }
          })
      );
    }
  }

  setCompanyCellValue(rowData, value) {
    rowData.accountingSystemTenantName = value;
    rowData.accountingSystemTenantId = this.accountingSystemTenants.find(i => i.tenantName === value)?.tenantId;
    rowData.jobTrackingCategoryId = '';
    rowData.trackingCategoryId = '';
    this.getAccountingSystemLookups(rowData.accountingSystemTenantId);
  }

  getAccountingSystemLookups(tenantId: string) {
    this.tenantId = tenantId;
    if (this.accountingSystemType === 1) { // Xero
      this.connectToAccountingSystemCategories(tenantId);
    }
    else if (this.accountingSystemType === 2) { // Quickbooks
      this.connectToAccountingSystemServices(tenantId, true);
    }
    else if (this.accountingSystemType === 3) { // MYOB
      this.connectToAccountingSystemAccounts(tenantId);
    }
    else if (this.accountingSystemType === 4) { // Business Central
      this.connectToAccountingSystemServices(tenantId, false);
    }
  }
}
