import { GlobalService } from './../../services/global.service';
import { Component, OnInit } from '@angular/core';
import { MaintenanceHelperService } from '../maintenance-helper.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-maintenance-menu',
  templateUrl: './maintenance-menu.component.html',
  styleUrls: ['./maintenance-menu.component.scss']
})
export class MaintenanceMenuComponent implements OnInit {

  sections: string[];
  divisionAccountCounter = 0;
  divisionProductCounter = 0;
  jobAccountingCustomerCounter = 0;

  selectedIndex = 0;
  accountingSystemType: number;

  constructor(public helper: MaintenanceHelperService,
    private globalService: GlobalService) { }

  ngOnInit() {
    this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
    this.helper.selectedSection = 'Extras Codes';
    this.sections = this.helper.orderSections;

    if (this.globalService.invoicePermission === 'Admin') {
      if (this.accountingSystemType === 2 || this.accountingSystemType === 4) {
        this.sections = this.sections.concat(this.helper.itemInvoiceSections);
      }
      else {
        this.sections = this.sections.concat(this.helper.accountInvoiceSections);
      }
    }

    // Are we coming back from accounting system authorisation
    const session = this.globalService.getSessionObject();
    if (session && session.xeroState && session.xeroState !== '') {
      this.globalService.setSessionAtt('xeroCode', ''); // clear
      this.globalService.setSessionAtt('xeroState', ''); // clear
      this.helper.selectedSection = 'Control';
      this.selectedIndex = 2;
    }
    if (session && session.quickBooksState && session.quickBooksState !== '' && session.quickBooksRealmId !== '') {
      this.globalService.setSessionAtt('quickBooksCode', ''); // clear
      this.globalService.setSessionAtt('quickBooksState', ''); // clear
      this.globalService.setSessionAtt('quickBooksRealmId', ''); // clear
      this.helper.selectedSection = 'Control';
      this.selectedIndex = 2;
    }
    if (session && session.myobState && session.myobState !== '') {
      this.globalService.setSessionAtt('myobCode', ''); // clear
      this.globalService.setSessionAtt('myobState', ''); // clear
      this.helper.selectedSection = 'Control';
      this.selectedIndex = 2;
    }
  }

  tabSelected(e) {
    if (e.itemData === 'Division Accounts') {
      this.divisionAccountCounter++;
    }
    if (e.itemData === 'Division Products') {
      this.divisionProductCounter++;
    }
    if (e.itemData === 'Job-Accounting Customers') {
      this.jobAccountingCustomerCounter++;
    }
    this.helper.selectedSection = e.itemData;
  }
}
