import { GlobalService } from './../../services/global.service';
import { AuthService } from './../../services/auth.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountingSystemService } from '../../services/felixApi/accounting-system.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Subscription } from 'rxjs';
import { OrderControl } from '../../dtos/order-control';
import { User } from '../../dtos/user';
import { UserService } from '../../services/felixApi/user.service';
import { NotificationService } from '../../services/notification.service';
import { AccountingSystemCategory } from '../../dtos/accounting-system-category';
import { environment } from '../../../environments/environment';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import CustomStore from 'devextreme/data/custom_store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderLogoComponent } from './order-logo/order-logo.component';

@Component({
  selector: 'js-order-control',
  templateUrl: './order-control.component.html',
  styleUrls: ['./order-control.component.scss']
})
export class OrderControlComponent implements OnInit, OnDestroy {

  loading = true;
  subscriptions: Subscription[] = [];
  orderControl: OrderControl;
  newOrderControl: OrderControl;
  orderControlForm: OrderControl;
  cutOffTimeToPopulateNextStart: Date;
  updatedData: any;
  user: User;
  disconnectPopupVisible = false;
  accountingSystemTenants: CustomStore;
  selectedTenantsToDisconnect: string[] = [];

  buttonOptions = {
    text: 'Update',
    type: 'default',
    stylingMode: 'contained',
    onClick: () => {
      this.update();
    },
    useSubmitBehavior: true
  };
  loadbuttonOptions = {
    text: 'Load Logo',
    type: 'default',
    stylingMode: 'outlined',
    onClick: () => {
      this.openLoadFilePopup();
    },
    useSubmitBehavior: true
  };
  purchaseOrderExtraNoteOptions = {
    text: 'Fields: [RoleName], [Email], [Phone]',
    type: 'outlined',
    stylingMode: 'text'
  };
  // fileTypeId = 1;
  // fileValue: any[] = [];
  // isFileValid: boolean;
  invoiceAdmin: boolean;
  accountingSystemType: number;
  accountsLoaded: boolean;
  accountingSystemCategories: AccountingSystemCategory[];
  invoiceFormats: { id: number; description: string; }[];

  constructor(
    private accountingSystemService: AccountingSystemService,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    private compService: CompanyService,
    private globalService: GlobalService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.invoiceFormats = [
      { id: null, description: 'Job number + /Inv. + Invoice number' },
      { id: 1, description: 'Invoice number only' }
    ];

    this.getData();
    this.getCompanyConfigurations();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.compService.getCompanyConfigurations().subscribe({
        next: () => {
          if (this.authService.isAdminOrSuper()
            || this.authService.areaPermissions.find(i => i.applicationArea === 'Invoices')?.permissionType === 'Admin') {
            this.invoiceAdmin = true;
          }
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  getData() {
    this.subscriptions.push(
      this.maintenanceService.getOrderControlData(false)
        .subscribe({
          next: (orderControl) => {
            this.orderControl = orderControl;

            if (orderControl) {
              this.user = this.userService.users.find(i => i.id === orderControl.modifiedUserId);
              this.orderControlForm = JSON.parse(JSON.stringify(this.orderControl));
              this.orderControlForm.minPostingDate = this.orderControl.minPostingDate;
            } else {
              this.orderControlForm = new OrderControl;
            }

            if (!this.orderControlForm?.defaultEmailMessage || this.orderControlForm.defaultEmailMessage.trim() === '') {
              this.orderControlForm.defaultEmailMessage = 'Hi [EmailSalutation]\n\n'
                + 'Please see attached Purchase order/s and relevant documentation\n'
                + 'If you have any queries, please contact me via email at [MyEmailAddress]\n\n'
                + '**Site supervisor to call up booking date**';
            }

            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  update() {
    if (this.orderControl) {
      // edit
      this.updatedData = {};
      let isItemsToUpdate = false;

      if (this.orderControlForm.globalNote !== this.orderControl.globalNote) {
        this.updatedData.globalNote = this.orderControlForm.globalNote;
        isItemsToUpdate = true;
      }

      if (this.orderControlForm.returnEmail !== this.orderControl.returnEmail) {
        this.updatedData.returnEmail = this.orderControlForm.returnEmail;
        isItemsToUpdate = true;
      }

      if (this.orderControlForm.purchaseOrderExtraNote !== this.orderControl.purchaseOrderExtraNote) {
        this.updatedData.purchaseOrderExtraNote = this.orderControlForm.purchaseOrderExtraNote;
        isItemsToUpdate = true;
      }

      if (this.orderControlForm.defaultEmailMessage !== this.orderControl.defaultEmailMessage) {
        this.updatedData.defaultEmailMessage = this.orderControlForm.defaultEmailMessage;
        isItemsToUpdate = true;
      }

      if (this.orderControlForm.ccEmailAddress !== this.orderControl.ccEmailAddress) {
        this.updatedData.ccEmailAddress = this.orderControlForm.ccEmailAddress;
        isItemsToUpdate = true;
      }

      if (this.orderControlForm.invoiceThreshold !== this.orderControl.invoiceThreshold) {
        this.updatedData.invoiceThreshold = this.orderControlForm.invoiceThreshold;
        isItemsToUpdate = true;
      }

      if (this.orderControlForm.jobTrackingCategoryId !== this.orderControl.jobTrackingCategoryId) {
        this.updatedData.jobTrackingCategoryId = this.orderControlForm.jobTrackingCategoryId;
        isItemsToUpdate = true;
      }

      if (this.orderControlForm.minPostingDate !== this.orderControl.minPostingDate) {
        this.updatedData.minPostingDate = this.orderControlForm.minPostingDate;
        isItemsToUpdate = true;
      }

      if (this.orderControlForm.submittedInvoiceFormatId !== this.orderControl.submittedInvoiceFormatId) {
        this.updatedData.submittedInvoiceFormatId = this.orderControlForm.submittedInvoiceFormatId;
        isItemsToUpdate = true;
      }

      if (this.orderControlForm.isSubcontractorsCutOffStrict !== this.orderControl.isSubcontractorsCutOffStrict) {
        this.updatedData.isSubcontractorsCutOffStrict = this.orderControlForm.isSubcontractorsCutOffStrict;
        isItemsToUpdate = true;
      }

      if (isItemsToUpdate) {
        this.loading = true;

        this.subscriptions.push(
          this.maintenanceService.updateOrderControl(this.updatedData)
            .subscribe({
              next: () => {
                this.notiService.showSuccess('Updated');
                this.getData();
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }
    } else {
      // add
      this.loading = true;

      this.updatedData = {};
      this.updatedData.globalNote = this.orderControlForm.globalNote;
      this.updatedData.returnEmail = this.orderControlForm.returnEmail;
      this.updatedData.purchaseOrderExtraNote = this.orderControlForm.purchaseOrderExtraNote;
      this.updatedData.defaultEmailMessage = this.orderControlForm.defaultEmailMessage;
      this.updatedData.ccEmailAddress = this.orderControlForm.ccEmailAddress;
      this.updatedData.invoiceThreshold = this.orderControlForm.invoiceThreshold;
      this.updatedData.jobTrackingCategoryId = this.orderControlForm.jobTrackingCategoryId;
      this.updatedData.minPostingDate = this.orderControlForm.minPostingDate;
      this.updatedData.submittedInvoiceFormatId = this.orderControlForm.submittedInvoiceFormatId;
      this.updatedData.isSubcontractorsCutOffStrict = this.orderControlForm.isSubcontractorsCutOffStrict;

      this.subscriptions.push(
        this.maintenanceService.addOrderControl(this.updatedData)
          .subscribe({
            next: () => {
              this.getData();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }

  connectToAccountingSystemTenants() {
    // Get the accounts from Accounting System to do the connection
    this.accountingSystemTenants = new CustomStore({
      key: 'tenantId',
      load: async () => {
        this.loading = true;
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.accountingSystemService.getAccountingSystemTenants().subscribe({
              next: (res) => {
                this.loading = false;
                return resolve(res);
              }, error: (err) => {
                this.loading = false;
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.accountingSystemService.updateAccountingSystemTenant(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.accountingSystemService.deleteAccountingSystemTenant(encodeURIComponent(key)).subscribe({
              next: (result) => {
                this.notiService.showSuccess(result);
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  openLoadFilePopup() {
    const modalRef = this.modalService.open(OrderLogoComponent);
    modalRef.result.then(() => { }, () => { });
  }

  connectToXero() {
    this.loading = true;
    let url = 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=' + environment.xeroClientID;
    url += '&redirect_uri=' + environment.applicationUrl;
    url += '&scope=' + environment.xeroScopes;
    url += '&state=x' + this.globalService.getCurrentCompanyId();
    window.location.assign(url);
  }

  connectToQuickBooks() {
    this.loading = true;
    let url = 'https://appcenter.intuit.com/connect/oauth2?client_id=' + environment.quickBooksClientID;
    url += '&redirect_uri=' + environment.applicationUrl;
    url += '&response_type=code';
    url += '&scope=' + environment.quickBooksScopes;
    url += '&state=q' + this.globalService.getCurrentCompanyId();
    window.location.assign(url);
  }

  connectToMyob() {
    this.loading = true;
    let url = 'https://secure.myob.com/oauth2/account/authorize?client_id=' + environment.myobClientID;
    url += '&redirect_uri=' + environment.applicationUrl;
    url += '&response_type=code';
    url += '&scope=' + environment.myobScopes;
    url += '&state=m' + this.globalService.getCurrentCompanyId();
    window.location.assign(url);
  }

  disconnectPopup() {
    this.connectToAccountingSystemTenants();
    this.disconnectPopupVisible = true;
  }
}
